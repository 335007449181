import React, { Component } from "react";
import "./ListTreinamento.scss";

import { ReactComponent as IfaIcon } from "../../.././../assets/images/instrutor/icone-ifa.svg";

import Header from "../../../Header/Header";

import TrainingService from "../../../../services/training.service";
import InstructorService from "../../../../services/instructor.service";
import ClassService from "../../../../services/class.service";
import LocalStorage from "../../../../services/localStorage.service";
import QuizService from "../../../../services/quiz.service";

import Loading from "../.../../../../Loading/Loading";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ContextAuth } from "../../../../context/authContext";
import { navigate } from "../../../../services/navigation.service";
import TrainingItem from "./components/TrainingItem";
import goToTraining from "./utils/goToTraining";
import FinishButton from "./components/FinishButton";
const MySwal = withReactContent(Swal);

class ListTreinamento extends Component {
  static contextType = ContextAuth;
  component = this;

  constructor(props) {
    super(props);
    this.state = {
      trainings: [
        {
          _id: "",
          state: "",
        },
      ],
      instructor: "",
      trainingCompleted: [],
      Loading: true,
      completedClassesByInstructor: [],
    };
  }

  componentWillMount = async () => {
    const lang = LocalStorage.retrieve("language");

    let localInstructor = this.context.state.userData;
    let instructor = await InstructorService.getById(localInstructor._id);

    let trainings = await TrainingService.getAll();

    this.setState({
      trainingCompleted: instructor.trainingCompleted,
      instructor: instructor,
    }, async () => {

      trainings = trainings.map((training) => {
        
        if (lang === "pt" || lang === "en" || lang === "es") {
          let isThisTrainingCompleted = this.state.trainingCompleted.includes(training._id)

          return {
            status: isThisTrainingCompleted,
            order: training.order,
            _id: training._id,
            ...training[lang],
          };
        }
      }).sort(function (a, b) {
        return a.order - b.order;
      });
    });

    await this.setState({
      trainings: trainings,
      Loading: false,
    });

    let completedClasses = await ClassService.classCompletedByInstructor(
      localInstructor._id
    );

    await this.setState({
      completedClassesByInstructor: completedClasses,
    });
  };

  isTrainingCompleted() {
    return (
      this.state.instructor.trainingCompletedAt &&
      this.state.instructor.trainingCompletedAt.length
    );
  }

  saveTrainingCompletedDate = () => {
    const setContext = this.context.setContext;
    let id = this.state.instructor._id;
    InstructorService.update(id, {
      trainingCompletedAt: new Date(),
    })
      .then((res) => {
        setContext({ userData: res });

        this.alert(
          `${this.props.t("i_training.btnHome")}`,
          "success",
          `${this.props.t("i_training.certifiedText")}`,
          "/home-instrutor"
        );
      })
      .catch(() => {});
  };

  alert(text, icon, title, url) {
    MySwal.fire({
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${text}`,
      icon: icon,
      title: `<p>${title}</p>`,
    }).then(function () {
      window.location = `${url}`;
    });
  }

  async concludeTrainingWithNoQuizz(trainingId) {
    const intructorId = { instructorId: this.state.instructor._id };
    if (trainingId === "62f65732faae58370c12c2bf") {
      await QuizService.concludeTrainingWithNoQuizz(trainingId, intructorId);
    }
  }

  render() {
    return (
      <Loading fade={!!this.state.Loading}>
        <div>
          <Header />

          <div className="page-title-header">
            <h1 className="page-title">{this.props.t("i_training.title")}</h1>
            <span className="page-title-border"></span>
          </div>

          <main className="list-treinamento-main">
            <p className="list-treinamento-text-intro">
              {this.isTrainingCompleted()
                ? ""
                : this.props.t("i_training.intro")}
            </p>

            <ul>
              {
                this.state.trainings &&
                this.state.trainings.map(training => 
                  <TrainingItem
                    {...this.props}
                    training={training}
                    onClick={() => goToTraining.bind(this)(training)}
                  />
              )}
              <a
                href="https://youtube.com/playlist?list=PLcZ2p1IUPmQ5SxT6t7Ci1t-cCnpfuJJmr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li className="list-treinamento-trilha depoimento">
                  <div className="list-treinamento-trilha-image">
                    <IfaIcon />
                  </div>

                  <span className="list-treinamento-trilha-divider"></span>

                  <div className="list-treinamento-trilha-content">
                    <h4 className="list-treinamento-trilha-content-title">
                      {this.props.t("i_training.btnTestimonial")}
                    </h4>
                  </div>
                  <span className="list-treinamento-trilha-arrow"></span>
                </li>
              </a>
            </ul>

            <p className="list-treinamento-text-alert">
              {this.isTrainingCompleted()
                ? ""
                : this.props.t("i_training.alert")}
            </p>

            <div className="list-treinamento-buttons">
              <button
                className="list-treinamento-back"
                onClick={(e) => window.history.back()}
              >
                {this.props.t("i_training.btnBack")}
              </button>

              {this.state.completedClassesByInstructor.length >= 1 && (
                <button
                  className="list-treinamento-view-certified"
                  onClick={() => {
                    const navigator = navigate.bind(this.component);

                    navigator({ link: `/certificado-instrutor` });
                  }}
                >
                  {this.props.t("i_training.viewCertified")}
                </button>
              )}

              <FinishButton
                {...this.props}
                state={this.state}
                component={this}
                saveTrainingCompletedDate={this.saveTrainingCompletedDate}
              />
            </div>
          </main>
        </div>
      </Loading>
    );
  }
}

export default ListTreinamento;
