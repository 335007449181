export class InstructorDTO {
    active;
    address;
    born;
    cellphone;
    contactWithIfa;
    courses;
    createdAt;
    email;
    firstAcess;
    institution;
    isTeacher;
    name;
    nationality;
    receiveMessagesIfa;
    trainingCompleted;
    trainingCompletedAt;
    username;
    _id;

    constructor(instructor) {
        this.active = instructor.active;
        this.address = instructor.address;
        this.born = instructor.born;
        this.cellphone = instructor.cellphone;
        this.contactWithIfa = instructor.contactWithIfa;
        this.courses = instructor.courses;
        this.createdAt = instructor.createdAt;
        this.email = instructor.email;
        this.firstAcess = instructor.firstAcess;
        this.institution = instructor.institution;
        this.isTeacher = instructor.isTeacher;
        this.name = instructor.name;
        this.nationality = instructor.nationality;
        this.receiveMessagesIfa = instructor.receiveMessagesIfa;
        this.trainingCompleted = instructor.trainingCompleted;
        this.trainingCompletedAt = instructor.trainingCompletedAt;
        this.username = instructor.username;
        this._id = instructor._id;

    }

}
