import React, { Component } from 'react';

import QuizService from '../../../../services/quiz.service';
import LocalStorage from '../../../../services/localStorage.service';
import ParticipantService from '../../../../services/participant.service'

import Loading from '../../../Loading/Loading'

import './Game.scss';

import Header from '../../../Header/Header';
import { showAlert } from '../../../../utils/showAlert';
import { ContextAuth } from '../../../../context/authContext';

class Game extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
    this.state = {
      quizzes: [],
      participant: {},  
      statement: '',
      Loading: true,
      saved: []
    }
  }

  async componentWillMount(){
    const lang = LocalStorage.retrieve('language');
    let localParticipante = this.context.state.userData;
    let participante = await ParticipantService.getById(localParticipante._id)
    let { id } = this.props.match.params;
    let quizContent = await QuizService.getByTrainingId(id)
    
    quizContent = quizContent.map(quiz => {
      return {
        _id: quiz._id,
        training: quiz.training,
        type: quiz.type,
        typeGame: quiz.typeGame,
        currenty: false,
        ...quiz[lang]
      }
    });

    if(quizContent.length > 0)
      quizContent[0].currenty = true;

    this.setState({
      quizzes: quizContent,
      participant: participante,
      Loading: false
    })    
  }

  nextQuestion = async (e) => {
    try {
      let user = this.state.participant._id;
      let current = this.state.quizzes.filter((q) => q.currenty);
      let quiz = current[0]._id;
      let correct = this.state.answerChosen.correct;
      let answer = this.state.answerChosen.answer;

      this.setState(
        {
          saved: [...this.state.saved, { user, quiz, correct, answer }],
        },
        async () => {
          let allQuizzes = this.state.quizzes;
          allQuizzes.shift();

          if (allQuizzes && allQuizzes.length > 0) {
            allQuizzes[0].currenty = true;
            this.setState({ quizzes: allQuizzes });
          } else {
            let res = await QuizService.createSaved(this.state.saved);
            this.alert(
              `${this.props.t("game.btnBack")}`,
              "success",
              `${this.props.t("game.successText")} ${res.result}%`,
              "/game-types"
            );
          }
        }
      );
    } catch (err) {
      this.alert(
        `${this.props.t('game.btnBack')}`,
        'error',
        `${this.props.t('game.errorText')}`,
        `javascript:void(0)`
      )
    }
  }

  alert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  render() {
    return (
      <Loading fade = { this.state.Loading }>
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('game.title')}</h1>
          <span className="page-title-border"></span>
        </div>
        <div>
          {this.state.quizzes && this.state.quizzes.map(quiz => {
            if (quiz.currenty) {
              return (
                <div>
                  <main className="game-main">
                    <div className="game-collapse">
                      <input id="teste" type="checkbox" />
                      <label htmlFor="teste">
                        <span className="game-collapse-title">
                          <h3>{this.props.t('game.curiosity')}</h3>
                          <span></span>
                        </span>
                      </label>
                      <p className="game-collapse-text">
                        {quiz.curiosity}
                      </p>
                    </div>
                    {quiz.statement.split('\n').map(function(item, key) {
                      return (
                        <div className="game-content-text">
                          <p key={key}>{item}</p>
                        </div>
                      )
                    })}
                    { quiz.answers && quiz.answers.map((answer, i) =>{
                        return(
                            <div className="form-group game-question-alternatives" key={`alt-${i}`}>
                              <input
                                className="game-input"
                                id={`alt-${i}`}
                                name="answer"
                                required
                                type="radio"
                                value={`alt-${i}`}
                                onClick={() => this.setState({answerChosen: answer})}
                              />
                              <label className="card game-label" for={`alt-${i}`}>
                                {answer.answer}
                              </label>
                            </div>
                          )
                        })
                    }
                {
                  this.state.quizzes && this.state.quizzes.length > 1 ?
                  <button className="quiz-instrutor-button button-full" onClick={this.nextQuestion.bind(this)}>{this.props.t('game.btnNext')}</button>
                  :
                  <button className="quiz-instrutor-button button-full" onClick={this.nextQuestion.bind(this)}>{this.props.t('game.btnFinish')}</button>
                }
            </main>
                </div>
              )
            }
          })
        }
        </div>
      </div>
      </Loading>
    );
  }
}

export default Game;