import { useContext, useEffect, useState } from "react"
import ModalMissoes from "../components/Participante/Missoes/Modal/Modal"
import getTranslation from "../utils/getTranslation"
import { navigate } from "../services/navigation.service"
import { ContextAuth } from "../context/authContext"
import { useHistory } from "react-router"

const useMissionInformationAndTipForModal = () => {
    const [missionInformationAndTip, setMissionInformationAndTip] = useState({
        name: '',
        contenxt: '',
        toDo: '',
        tip: '',
        result: '',
        participantId: '',
        participantMissionId: ''
    })
    const context = useContext(ContextAuth)
    const history = useHistory()

    const props = { history }

    useEffect(() => {
        if(!missionInformationAndTip.name) return 
    
        const $modal = document.querySelector('.missoes-participant-modal');
    
        if(!$modal) return 
    
        $modal.style.display = $modal.style.display === 'block' ? 'none' : 'block'
    
    },[missionInformationAndTip])

    const ModalComponent = () => {
        return (
            <ModalMissoes
            name={missionInformationAndTip.name}
            context={missionInformationAndTip.contenxt}
            todo={missionInformationAndTip.toDo}
            result={missionInformationAndTip.result}
            tip={missionInformationAndTip.tip}
            t={getTranslation}
            nextButton={getTranslation('p_missions.modal.btnNext')}
            onClickNextButton={() =>                     
              navigate.call(
                { context, props },
                {
                  link: `participant/${missionInformationAndTip.participantId}/mission/${missionInformationAndTip.participantMissionId}`
                }
              )
            }
          />
        )
    }

    return {
        setMissionInformationAndTip,
        ModalComponent
    }
}

export default useMissionInformationAndTipForModal