import React, { Component } from "react";
import "./Header.scss";

import LocalStorage from "../../services/localStorage.service";

import { ReactComponent as Logout } from "../../assets/images/header/icon-logout.svg";
import { ReactComponent as EditPassword } from "../../assets/images/header/edit-password.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/header/icone-arrow.svg";
import { ReactComponent as IfaIcon } from "../../assets/images/header/icone-ifa.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/header/dashboard.svg";
// import { ReactComponent as InstructorIcon } from '../../assets/images/header/user.svg';
import { ReactComponent as Avatar01 } from "../../assets/images/avatar/01.svg";
import { ReactComponent as Avatar02 } from "../../assets/images/avatar/02.svg";
import { ReactComponent as Avatar03 } from "../../assets/images/avatar/03.svg";
import { ReactComponent as Avatar04 } from "../../assets/images/avatar/04.svg";
import { ReactComponent as Avatar05 } from "../../assets/images/avatar/05.svg";
import { ReactComponent as Avatar06 } from "../../assets/images/avatar/06.svg";
import { ReactComponent as Avatar07 } from "../../assets/images/avatar/07.svg";
import { ReactComponent as Avatar08 } from "../../assets/images/avatar/08.svg";
import { ReactComponent as Avatar09 } from "../../assets/images/avatar/09.svg";

import { ReactComponent as Bald01 } from "../../assets/images/avatar/1-bald.svg";
import { ReactComponent as Bald02 } from "../../assets/images/avatar/2-bald.svg";
import { ReactComponent as Bald03 } from "../../assets/images/avatar/3-bald.svg";
import { ReactComponent as Bald04 } from "../../assets/images/avatar/4-bald.svg";
import { ReactComponent as Bald05 } from "../../assets/images/avatar/5-bald.svg";
import { ReactComponent as Bald06 } from "../../assets/images/avatar/6-bald.svg";
import { ReactComponent as Bald07 } from "../../assets/images/avatar/7-bald.svg";
import { ReactComponent as Bald08 } from "../../assets/images/avatar/8-bald.svg";
import { ReactComponent as Bald09 } from "../../assets/images/avatar/9-bald.svg";
import { Link, withRouter } from "react-router-dom";
import { ContextAuth } from "../../context/authContext";
import { navigate } from "../../services/navigation.service";

class Header extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
    this.state = {
      avatarImage: "",
      avatars: [
        <Avatar01 className="header-user" />,
        <Avatar02 className="header-user" />,
        <Avatar03 className="header-user" />,
        <Avatar04 className="header-user" />,
        <Avatar05 className="header-user" />,
        <Avatar06 className="header-user" />,
        <Avatar07 className="header-user" />,
        <Avatar08 className="header-user" />,
        <Avatar09 className="header-user" />,
      ],
      balds: [
        <Bald01 className="header-user" />,
        <Bald02 className="header-user" />,
        <Bald03 className="header-user" />,
        <Bald04 className="header-user" />,
        <Bald05 className="header-user" />,
        <Bald06 className="header-user" />,
        <Bald07 className="header-user" />,
        <Bald08 className="header-user" />,
        <Bald09 className="header-user" />,
      ],
      instructorIcon: <Avatar07 className="header-user" />,
      lang: LocalStorage.retrieve("language"),
      userType: "participant",
    };
  }

  componentDidMount = async () => {
    const state = this.context.state;
    const user = state.userData;
    const role = state.role;
    let avatarImage, userType;

    if (role == "participant" && user.avatar.type) {
      if (user.avatar.bald)
        avatarImage = this.state.balds[user.avatar.type - 1];
      else avatarImage = this.state.avatars[user.avatar.type - 1];
    } else if (role == "instructor") {
      avatarImage = this.state.instructorIcon;
      userType = "instructor";
    } else {
      avatarImage = this.state.instructorIcon;
    }

    this.setState({
      avatarImage,
      userType,
    });
  };

  componentDidUpdate = async () => {
    const state = this.context.state;
    const user = state.userData;
    const role = state.role;

    if (role == "participant" && user.avatar.type) {
      // Change Hair Color
      let hair = document.querySelectorAll(".cls-2");
      hair.forEach((item) => {
        item.classList = `cls-2 ${user.avatar.hairColor}`;
      });

      // Change Skin Color
      if (user.avatar.bald) {
        let skin = document.querySelectorAll(".noHair-cls-1");

        skin.forEach((item) => {
          item.classList = `noHair-cls-1 ${user.avatar.skinColor}`;
        });
      } else {
        let skin = document.querySelectorAll(".cls-1");

        skin.forEach((item) => {
          item.classList = `cls-1 ${user.avatar.skinColor}`;
        });
      }
    }
  };

  logout = () => {
    this.context.clearContext();

    const navigator = navigate.bind(this);

    navigator({ link: "/", verifyToken: false });
  };

  previousPage() {
    const component = this;
    let path = window.location.pathname;
    let map = new Map();
    map.set("/oficina-instrutor", "/turmas-list");
    map.set("/oficina-participante", "/home-participante");
    map.set("/turmas-list", "/home-instrutor");
    map.set("/game-types", "/home-participante");

    for (var [key, value] of map) {
      if (path.includes(key)) {
        const navigator = navigate.bind(component);

        navigator({ link: value });
        return;
      }
    }

    if (path.includes("/turmas-consult")) {
      const previousURL = document.referrer;
      if (
        previousURL &&
        (previousURL.includes("/atribuicao-poderes") ||
          previousURL.includes("/atribuicao-missoes"))
      ) {
        const navigator = navigate.bind(component);

        navigator({ goBack: true });
        return;
      }
    }

    if (window.location.pathname.includes("/62f65732faae58370c12c2bf")) {
      const navigator = navigate.bind(component);

      navigator({ link: "/treinamento-instrutor" });
    } else {
      // voltar para página anterior
      this.props.history.goBack();
    }
  }

  canReturn() {
    if (window.location.pathname.includes("/welcome")) return false;

    const user = this.context.state.userData
    if (!user) return true;

    return !(
      window.location.pathname.includes("/treinamento-instrutor") &&
      !user.trainingCompletedAt
    );
  }

  render() {
    return (
      <header className="main-header">
        {!this.canReturn() ? (
          <></>
        ) : (
          <ArrowIcon
            className="header-arrow"
            onClick={() => this.previousPage()}
          />
        )}

        <Link
          className="header-ifa"
          to={
            !this.canReturn()
              ? "#"
              : this.state.userType === "instructor"
              ? "/home-instrutor"
              : "/home-participante"
          }
        >
          <IfaIcon />
        </Link>

        <div className="header-dropdown">
          {this.state.avatarImage}
          <input
            className="informacoes-adicionais-radio"
            id="icon"
            name="avatar"
            type="checkbox"
            value="true"
          />
          <label htmlFor="icon">
            <ul className="dropdown-list">
              {this.state.userType === "instructor" ? (
                <>
                  {this.canReturn() ? (
                    <li key="dashboard">
                      <Link to="/dashboard">
                        <DashboardIcon className="header-item-icon" />
                        dashboard
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li key="password">
                    <a href="/recover-password">
                      <EditPassword className="header-item-icon" />
                      {this.state.lang === "pt"
                        ? "editar senha"
                        : this.state.lang === "en"
                        ? "edit password"
                        : "editar contraseña"}
                    </a>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li key="logout" onClick={() => this.logout()}>
                <Logout className="header-item-icon" />
                logout
              </li>
            </ul>
          </label>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
