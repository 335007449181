import { useEffect, useState } from "react"
import Checkbox from "../../../../Form/Checkbox/Checkbox"
import LocalStorage from "../../../../../services/localStorage.service"

const InstitutionNameAndType = (props) => {

    const [othersTextInput, setOthersTextInput] = useState('')

    const getTranslatedText = (name) => props.t(`i_registration.institution.${name}`)
    const isChecked = (type) => props.state.type == type

    const setType = (type) => {
        const institutionStaticTypes = institutionTypes.map(item => item.label)

        props.setState('type', type)

        if(institutionStaticTypes.includes(type)) setOthersTextInput('')
    }

    const handleOthersInputChange = (e) => {
        setType(e.target.value)
        setOthersTextInput(e.target.value)
    }

    const retrieveInstitutionTypeInLocalStorage = () => {
        const institutionStoraged = LocalStorage.retrieve('instituicao')
        const institutionStaticTypes = institutionTypes.map(item => item.label)

        if (!institutionStoraged || !institutionStoraged.institution?.type) return

        const { type } = institutionStoraged.institution

        if(institutionStaticTypes.includes(type)) {
            setType(type)
        } else {
            setType(type)
            setOthersTextInput(type)
        }

    }

    const institutionTypes = [
        {
            id: 'public-school',
            label: getTranslatedText('allTypes.public-school')
        },
        {
            id: 'private-school',
            label: getTranslatedText('allTypes.private-school')
        },
        {
            id: 'cca-cj',
            label: getTranslatedText('allTypes.CCA')
        },
        {
            id: 'osc',
            label: getTranslatedText('allTypes.OSC')
        },
    ]

    useEffect(() => {
        retrieveInstitutionTypeInLocalStorage()
    },[])

    return (
        <div className="card cadastro-instrutor-card">
            <div className="form-group">
                <label className="cadastro-instrutor-label">
                    {getTranslatedText('name')}
                    <span>*</span>
                </label>
                <input
                    className="cadastro-instrutor-input"
                    placeholder={getTranslatedText('namePlaceholder')}
                    name="name"
                    value={props.state.name}
                    onChange={(e) => props.setState('name', e.target.value)}
                />
            </div>
            <div className="form-group">
                <p className="cadastro-instrutor-label">
                    {getTranslatedText('type')}
                </p>
                {institutionTypes.map(item => 
                    <Checkbox
                        id={item.id}
                        label={item.label}
                        onClick={() => setType(item.label)}
                        isChecked={isChecked(item.label)}
                    />
                )}
                <Checkbox
                    id="outros-type"
                    label={getTranslatedText('allTypes.others')}
                    onClick={() => setType(othersTextInput)}
                    onChangeText={handleOthersInputChange}
                    textValue={othersTextInput}
                    isChecked={isChecked(othersTextInput)}
                    isInputDisabled={!isChecked(othersTextInput)}
                    hasTextInput
                />
            </div>
        </div>
    )
}

export default InstitutionNameAndType;