import requests from '../agent';
import LocalStorage from './localStorage.service';
import SecurityService from './security.service';

const endpoint = '/missions';

const MissionService = {
    getAll: async(authContext) => {
        if (SecurityService.isInstructor(authContext)) {
            const lang = LocalStorage.retrieve('language');
            return await requests.get(`${endpoint}/${lang}`);
        }
        return await requests.get(`${endpoint}/active`);
    },
    assignMission: async (participants) => {
        return await requests.post(`${endpoint}/assign`, participants);
    }
};

export default MissionService;

