import React, { Component } from 'react';

import './About.scss';
import Header from '../../../Header/Header';

import { ReactComponent as Avatar01 } from '../../../../assets/images/avatar/01.svg';
import { ReactComponent as Avatar02 } from '../../../../assets/images/avatar/02.svg';
import { ReactComponent as Avatar03 } from '../../../../assets/images/avatar/03.svg';
import { ReactComponent as Avatar04 } from '../../../../assets/images/avatar/04.svg';
import { ReactComponent as Avatar05 } from '../../../../assets/images/avatar/05.svg';
import { ReactComponent as Avatar06 } from '../../../../assets/images/avatar/06.svg';
import { ReactComponent as Avatar07 } from '../../../../assets/images/avatar/07.svg';
import { ReactComponent as Avatar08 } from '../../../../assets/images/avatar/08.svg';
import { ReactComponent as Avatar09 } from '../../../../assets/images/avatar/09.svg';

import { ReactComponent as Bald01 } from '../../../../assets/images/avatar/1-bald.svg';
import { ReactComponent as Bald02 } from '../../../../assets/images/avatar/2-bald.svg';
import { ReactComponent as Bald03 } from '../../../../assets/images/avatar/3-bald.svg';
import { ReactComponent as Bald04 } from '../../../../assets/images/avatar/4-bald.svg';
import { ReactComponent as Bald05 } from '../../../../assets/images/avatar/5-bald.svg';
import { ReactComponent as Bald06 } from '../../../../assets/images/avatar/6-bald.svg';
import { ReactComponent as Bald07 } from '../../../../assets/images/avatar/7-bald.svg';
import { ReactComponent as Bald08 } from '../../../../assets/images/avatar/8-bald.svg';
import { ReactComponent as Bald09 } from '../../../../assets/images/avatar/9-bald.svg';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { showAlert } from '../../../../utils/showAlert';
import { ContextAuth } from '../../../../context/authContext';
const MySwal = withReactContent(Swal)

export default class About extends Component {
  static contextType = ContextAuth;
  constructor(props){
    super(props)
    this.state = {
      avatar: '',
      bald: false,
      avatars: [
        <Avatar01 className="profile-highlight" />,
        <Avatar02 className="profile-highlight" />,
        <Avatar03 className="profile-highlight" />,
        <Avatar04 className="profile-highlight" />,
        <Avatar05 className="profile-highlight" />,
        <Avatar06 className="profile-highlight" />,
        <Avatar07 className="profile-highlight" />,
        <Avatar08 className="profile-highlight" />,
        <Avatar09 className="profile-highlight" />
      ],
      balds: [
        <Bald01 className="profile-highlight" />,
        <Bald02 className="profile-highlight" />,
        <Bald03 className="profile-highlight" />,
        <Bald04 className="profile-highlight" />,
        <Bald05 className="profile-highlight" />,
        <Bald06 className="profile-highlight" />,
        <Bald07 className="profile-highlight" />,
        <Bald08 className="profile-highlight" />,
        <Bald09 className="profile-highlight" />
      ],
      avatarImage: '',
      hairColor: '',
      skinColor: '',
      nickname: '',
      favoriteColor: '',
      favoriteAnimal: ''
    }
  }

  componentWillMount = () => {
    let query = this.props.location.search
    query = query.replace(/\?/g, '')
    let variables = query.split('&')

    const avatar = variables[0].split('=')[1]
    const bald = variables[1].split('=')[1]
    const color = variables[2].split('=')[1]
    const skinColor = variables[3].split('=')[1]

    let avatarImage;

    if(bald === 'true'){
      avatarImage = this.state.balds[avatar-1]
    } else {
      avatarImage = this.state.avatars[avatar-1]
    }

    this.setState({
      avatar: avatar,
      bald: bald,
      avatarImage: avatarImage,
      hairColor: color,
      skinColor: skinColor
    })
  }

  componentDidMount(){
    // Change Hair Color
    let hair = document.querySelectorAll('.cls-2')
    hair.forEach(item => {
      item.classList = `cls-2 ${this.state.hairColor}`
    })

    // Change Skin Color
    if(this.state.bald !== 'true'){
      let skin = document.querySelectorAll('.cls-1')

      skin.forEach(item => {
        item.classList = `cls-1 ${this.state.skinColor}`
      })
    } else {
      let skin = document.querySelectorAll('.noHair-cls-1')

      skin.forEach(item => {
        item.classList = `noHair-cls-1 ${this.state.skinColor}`
      })
    }
  }

  changeNickname = async (e) => { await this.setState({nickname: e.target.value  }) };
  changeFavoriteColor = async (e) =>{ await this.setState({favoriteColor: e.target.value})}
  changeFavoriteAnimal = async (e) => {await this.setState({favoriteAnimal: e.target.value})}

  validNickname(e){
     if(this.state.nickname == ""){
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
          'error',
        `${this.props.t('p_about.nickNameAlert')}`,
          '#'
      )
      this.setState({nickname: ""})
     }
  }

  validFavoriteColor(e){
     if(this.state.favoriteColor == ""){
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
          'error',
        `${this.props.t('p_about.favoriteColorAlert')}`,
          '#'
      )
      this.setState({favoriteColor: ""})
     }
  }

  validFavoriteAnimal(e){
     if(this.state.favoriteAnimal == ""){
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
          'error',
        `${this.props.t('p_about.favoriteAnimalAlert')}`,
          '#'
      )
      this.setState({favoriteAnimal: ""})
     }
  }

  validForm(e){
    if(this.state.nickname == "" || this.state.favoriteAnimal == "" || this.state.favoriteColor == ""){
      e.preventDefault()
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
          'error',
        `${this.props.t('p_about.validFormAlert')}`,
          '#'
      )
    }
  }

  showAlert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  render() {
    return (
      <div className="profile">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('p_about.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="profile-main">
          { this.state.avatarImage }

          <div className="form-group">
            <label className="about-label">
              {this.props.t('p_about.nicknameLabel')}
            </label>
            <input
              className="about-input"
              placeholder={this.props.t('p_about.nicknamePlaceholder')}
              onChange={this.changeNickname.bind(this)}
              onBlur={this.validNickname.bind(this)}
              type="text"
            />
          </div>

          <div className="form-group">
            <label className="about-label">
              {this.props.t('p_about.colorLabel')}
            </label>
            <div className="about-select-arrow">
              <select
                className="about-select"
                onChange={this.changeFavoriteColor.bind(this)}
                onBlur={this.validFavoriteColor.bind(this)}
              >
                <option value="">{this.props.t('p_about.colorSelect')}</option>
                <option value="blue">{this.props.t('p_about.colorOption1')}</option>
                <option value="yellow">{this.props.t('p_about.colorOption2')}</option>
                <option value="red">{this.props.t('p_about.colorOption3')}</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="about-label">
              {this.props.t('p_about.animalLabel')}
            </label>
            <div className="about-select-arrow">
              <select
                className="about-select"
                onChange={this.changeFavoriteAnimal.bind(this)}
                onBlur={this.validFavoriteAnimal.bind(this)}
              >
                <option value="">{this.props.t('p_about.animalSelect')}</option>
                <option value="dog">{this.props.t('p_about.animalOption1')}</option>
                <option value="cat">{this.props.t('p_about.animalOption2')}</option>
                <option value="bird">{this.props.t('p_about.animalOption3')}</option>
              </select>
            </div>
          </div>

          <a
            className="button-full button-next"
            onClick={this.validForm.bind(this)}
            href={
              `/participant-confirm?
              avatar=${this.state.avatar}&
              bald=${this.state.bald}&
              color=${this.state.hairColor}&
              skinColor=${this.state.skinColor}&
              nickname=${this.state.nickname}&
              favoriteColor=${this.state.favoriteColor}&
              favoriteAnimal=${this.state.favoriteAnimal}`
            }
          >
            {this.props.t('p_about.btnContinue')}
          </a>
        </main>
      </div>
    );
  }
}