import React, { Component } from "react";
import "./InformacoesAcesso.scss";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import InstructorService from "../../../../services/instructor.service";
import { showAlert } from "../../../../utils/showAlert";
import { ContextAuth } from "../../../../context/authContext";
const MySwal = withReactContent(Swal);
const ERROR_STYLE = "is-invalid";

class InformacoesAcesso extends Component {
  static contextType = ContextAuth;
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      emailConfirm: ""
    };
  }

  componentWillMount = async () => {
    if (this.props.dataExist) {
      let dataAux = this.props.dataAux;
      await this.setState({
        username: dataAux.username,
        email: dataAux.email,
        password: dataAux.password,
        passwordConfirm: dataAux.passwordConfirm,
        emailConfirm: dataAux.emailConfirm
      })
    }
  }

  async componentDidMount() {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: (<a href="javascript:void(0)">OK</a>),
      customClass: {
        confirmButton: "confirm-button-class",
      },
      reverseButtons: true,
      title:  `${this.props.t("i_registration.partnerWarnTitle")}`,
      width: "32rem",
      text: `${this.props.t("i_registration.partnerWarnContent")}`
    })
  }

  changeValue(e) {
    let name = e.target.name;
    let value = e.target.value;
    this[name].classList.remove(ERROR_STYLE);
    this.setState({ [name]: value });
  }

  emailIsValid(email) {
    return email.match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
  }

  async validEmail(e) {
    const email = e.target.value;
    let message;

    if (!this.emailIsValid(email))
      message = `${this.props.t("i_registration.instructorEmailAlert")}`;

    if (message) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error", message, "#"
      );
      this.setState({ email: "" });
    }
  };

  validationError(title) {
    MySwal.fire({
      confirmButtonColor: "#87BF40",
      confirmButtonText: (
        <span>{this.props.t("i_registration.btnBack")}</span>
      ),
      icon: "error",
      title: <p>{title}</p>
    });
  }

  validationSuccess = () => {
    this.setState({
      email: this.state.email.trim(),
      username: this.state.username.trim()
    })
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: `${this.props.t("i_registration.btnBack")}`,
      cancelButtonColor: "#87BF40",
      confirmButtonColor: "#87BF40",
      confirmButtonText: (
        <a href="javascript:void(0)">
          {this.props.t("i_registration.btnNextPage")}
        </a>
      ),
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class"
      },
      icon: "warning",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: `${this.props.t("i_registration.finishAccess")}`
    }).then(result => {
      if (result.value)
        this.props.getInfo("dadosCadastrais", this.state);
    });
  }

  async validationForm() {
    for (const key of Object.keys(this.state)) {
      if (this[key]) {
        if (
          (!this.state[key] || this.state[key] === "") &&
          !this[key].getAttribute("data-exclude")
        ) {
          this[key].classList.add(ERROR_STYLE);
          throw { code: "i_registration.fieldsAlert" }
        }
      }
    }

    if (this.state.password !== this.state.passwordConfirm) {
      this.passwordConfirm.classList.add(ERROR_STYLE);
      throw { code: "i_registration.access.passwordMatch" }
    }

    if (await InstructorService.existsWithEmail(this.state.email)) {
      throw { code: "i_registration.instructorEmailAlreadyRegistered" }
    }

    if (this.state.email !== this.state.emailConfirm) {
      this.emailConfirm.classList.add(ERROR_STYLE);
      throw { code: "i_registration.access.emailMatch" }
    }
  }

  handleClick() {
    this.validationForm().then(
      this.validationSuccess
    )
    .catch (
      err => this.validationError(this.props.t(err.code))
    )
  }

  showAlert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  render() {
    return (
      <div>
        <h2 className="cadastro-instrutor-main-title">
          {this.props.t("i_registration.access.subtitle")}
        </h2>

        <div className="card cadastro-instrutor-progress">
          {this.props.iconPerson}
          <div className="cadastro-instrutor-progresso-list">
            <span className="progresso-list-item active">1</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">2</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">3</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">4</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">5</span>
          </div>
          {this.props.iconCheck}
        </div>

        <span className="cadastro-instrutor-legend">
          {this.props.t("i_registration.info")}
        </span>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.access.username")}
              <span>*</span>
            </label>
            <span className="cadastro-instrutor-alert">
              {this.props.t("i_registration.access.exampleUsername")}
            </span>
            <input
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.access.usernamePlaceholder"
              )}
              ref={username => {
                this.username = username;
              }}
              name="username"
              type="text"
              value={this.state.username}
              onChange={this.changeValue.bind(this)}
            />
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.access.password")}
              <span>*</span>
            </label>
            <input
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.access.passwordPlaceholder"
              )}
              ref={password => {
                this.password = password;
              }}
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.changeValue.bind(this)}
            />
          </div>

          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.access.confirmPassword")}
              <span>*</span>
            </label>
            <input
              ref={passwordConfirm => {
                this.passwordConfirm = passwordConfirm;
              }}
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.access.confirmPasswordPlaceholder"
              )}
              name="passwordConfirm"
              type="password"
              value={this.state.passwordConfirm}
              onChange={this.changeValue.bind(this)}
            />
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.access.email")}
              <span>*</span>
            </label>
            <span className="cadastro-instrutor-alert">
              {this.props.t("i_registration.access.emailInfo")}
            </span>
            <input
              className="cadastro-instrutor-input email"
              placeholder={this.props.t(
                "i_registration.access.emailPlaceholder"
              )}
              type="email"
              ref={email => {
                this.email = email;
              }}
              name="email"
              value={this.state.email}
              onBlur={this.validEmail.bind(this)}
              onChange={this.changeValue.bind(this)}
            />
          </div>

          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.access.confirmEmail")}
              <span>*</span>
            </label>
            <input
              className="cadastro-instrutor-input email"
              placeholder={this.props.t(
                "i_registration.access.confirmEmailPlaceholder"
              )}
              ref={emailConfirm => {
                this.emailConfirm = emailConfirm;
              }}
              name="emailConfirm"
              type="email"
              value={this.state.emailConfirm}
              onChange={this.changeValue.bind(this)}
            />
          </div>
        </div>

        <div className="cadastro-instrutor-buttons">
          <a href="/login-instrutor" className="cadastro-instrutor-back">
            {this.props.t("i_registration.btnCancel")}
          </a>
          <button
            className="cadastro-instrutor-next"
            onClick={this.handleClick.bind(this)}
          >
            {this.props.t("i_registration.btnNext")}
          </button>
        </div>
      </div>
    );
  }
}

export default InformacoesAcesso;