import React, { Component } from "react";
import "./Oficina.scss";

import ClassService from "../../../../services/class.service";
import WorkshopService from "../../../../services/workshop.service";
import LocalStorage from "../../../../services/localStorage.service";

import Header from "../../../Header/Header";
import DescriptionModal from "./DescriptionModal/DescriptionModal";
import { ReactComponent as IconPerson } from "../../../../assets/images/instrutor/person.svg";
import { ReactComponent as CheckMark } from "../../../../assets/images/instrutor/check-mark.svg";
import ProgressoCampanha from "../../../Participante/OficinaCampanha/Content/Campanha";

import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

import Loading from "../../../Loading/Loading";
import { showAlert } from "../../../../utils/showAlert";
import { navigate } from "../../../../services/navigation.service";
import { ContextAuth } from "../../../../context/authContext";
import WorkshopSlider from "./WorkshopSlider/WorkshopSlider";

const MySwal = withReactContent(Swal);

class Oficina extends Component {
  static contextType = ContextAuth;
  _step = 1;
  component = this;

  constructor(props) {
    super(props);
    this.state = {
      turma: { _id: "", name: "", code: "", type: "" },
      workshopId: "",
      conteudo: { oficinaName: "", description: "" },
      workshops: [],
      count: 1,
      stepCampaign: 0,
      stepOficina: 0,
      currentWorkshop: "",
      timeLineWorkshop: [
        {
          step: 1,
          description: `${this.props.t("i_Class.timeLineWorkshop1")}`,
          current: false,
        },
        {
          step: 2,
          description: `${this.props.t("i_Class.timeLineWorkshop2")}`,
          current: false,
        },
        {
          step: 3,
          description: `${this.props.t("i_Class.timeLineWorkshop3")}`,
          current: false,
        },
        {
          step: 4,
          description: `${this.props.t("i_Class.timeLineWorkshop4")}`,
          current: false,
        },
        {
          step: 5,
          description: `${this.props.t("i_Class.timeLineWorkshop5")}`,
          current: false,
        },
      ],
      timeLineCampaign: [
        {
          step: 1,
          description: `${this.props.t("i_Class.timeLineCampaign1")}`,
          current: false,
        },
        {
          step: 2,
          description: `${this.props.t("i_Class.timeLineCampaign2")}`,
          current: false,
        },
        {
          step: 3,
          description: `${this.props.t("i_Class.timeLineCampaign3")}`,
          current: false,
        },
        {
          step: 4,
          description: `${this.props.t("i_Class.timeLineCampaign4")}`,
          current: false,
        },
        {
          step: 5,
          description: `${this.props.t("i_Class.timeLineCampaign5")}`,
          current: false,
        },
        {
          step: 6,
          description: `${this.props.t("i_Class.timeLineCampaign6")}`,
          current: false,
        },
        {
          step: 7,
          description: `${this.props.t("i_Class.timeLineCampaign7")}`,
          current: false,
        },
      ],
      lang: LocalStorage.retrieve("language"),
      Loading: true,
      _step: 0,
    };
  }

  async componentWillMount() {
    const lang = LocalStorage.retrieve("language");
    let { code } = this.props.match.params;
    const classCode = await ClassService.classExist(code);
    const component = this;

    const turma = {
      _id: classCode._id,
      name: classCode.name,
      code: classCode.code,
      type: classCode.type,
    };

    let currentWorkshop;

    const allCompleted = classCode.workshops.filter((wk) => !wk.completed);

    if (allCompleted.length == 0) {
      currentWorkshop = await WorkshopService.getById(
        classCode.workshops[classCode.workshops.length - 1].workshop
      ); //ultimo workshop - todos completps
      await this.setState({
        stepCampaign: classCode.workshops.length - 1,
        workshops: classCode.workshops,
        workshopId: currentWorkshop._id,
        currentWorkshop: classCode.workshops[classCode.workshops.length - 1],
      });
    } else {
      for (let i = 0; i < classCode.workshops.length; i++) {
        if (!classCode.workshops[i].completed) {
          currentWorkshop = await WorkshopService.getById(
            classCode.workshops[i].workshop
          );
          await this.setState({
            stepCampaign: i,
            workshops: classCode.workshops,
            workshopId: currentWorkshop._id,
            currentWorkshop: classCode.workshops[i],
          });
          break;
        }
      }
      // }
    }

    currentWorkshop = {
      _id: currentWorkshop._id,
      last: currentWorkshop.last,
        ...currentWorkshop[lang],
    }

    const conteudo = {
      oficinaName: currentWorkshop.name,
      description: currentWorkshop.description,
    };

    // Steps da Linha do tempo

    let timeLineWorkshop = this.state.timeLineWorkshop;
    let timeLineCampaign = this.state.timeLineCampaign;

    if (
      turma.type == "campaign" ||
      turma.type == "campaignLevel2" ||
      (turma.type == "campaignLevel1" &&
        this.state.currentWorkshop ===
          this.state.workshops[this.state.workshops.length - 1])
    ) {
      if (this.state.currentWorkshop.starsCompleted)
        this.setState({ _step: 6 });
      else if (this.state.currentWorkshop.missionsCompleted)
        this.setState({ _step: 5 });
      else if (this.state.currentWorkshop.powersCompleted)
        this.setState({ _step: 4 });
      else if (this.state.currentWorkshop.presenceCompleted)
        this.setState({ _step: 2 });

      timeLineCampaign[this.state._step].current = true;
    } else {
      if (this.state.currentWorkshop.powersCompleted)
        this.setState({ _step: 4 });
      else if (this.state.currentWorkshop.presenceCompleted)
        this.setState({ _step: 2 });

      timeLineWorkshop[this.state._step].current = true;
    }

    await this.setState({
      turma: turma,
      conteudo: conteudo,
      timeLineWorkshop: timeLineWorkshop,
      timeLineCampaign: timeLineCampaign,
    });

    if (moment(this.state.currentWorkshop.dateTime).format("ll") > new Date()) {
      //caju
      MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: `${this.props.t("i_Class.btnBack")}`,
        icon: "error",
        html: (
          <p>
            {this.props.t("i_Class.scheduleAlert")}:{" "}
            {moment(this.state.currentWorkshop.dateTime).format("L")}
          </p>
        ),
      }).then(function () {
        const navigator = navigate.bind(component);

        navigator({ link: "/turmas-list" });
      });
    }

    this.showSlides(1);
  }

  // Modal Descrição da Oficina
  openDescriptionModal() {
    let modal = document.querySelector(".oficina-instrutor-modal");
    modal.style.display = "block";
  }

  showAlert(icon, title, url) {
    showAlert("", icon, title, url, this);
  }

  backStep = async () => {
    let timeLine = "";
    if (
      this.state.turma.type == "campaignLevel1" &&
      this.state.currentWorkshop !==
        this.state.workshops[this.state.workshops.length - 1]
    )
      timeLine = this.state.timeLineWorkshop;
    else timeLine = this.state.timeLineCampaign;
    const index = timeLine.findIndex((step) => step.current);

    if (index == 2 && this.state.currentWorkshop.presenceCompleted)
      this.showAlert(
        "error",
        `${this.props.t("i_Class.failingPresence")}`,
        "#"
      );

    if (index == 4 && this.state.currentWorkshop.missionsCompleted)
      this.showAlert("error", `${this.props.t("i_Class.failingPower")}`, "#");

    if (index == 5 && this.state.currentWorkshop.starsCompleted)
      this.showAlert("error", `${this.props.t("i_Class.failingMission")}`, "#");

    if (index == 6 && this.state.currentWorkshop.starsCompleted)
      this.showAlert("error", `${this.props.t("i_Class.failingStar")}`, "#");

    if (index > 0) {
      timeLine[index].current = false;
      timeLine[index - 1].current = true;
      await this.setState({
        timeLine: timeLine,
        stepOficina: index,
        _step: index - 1,
      });
    }
  };

  nextStep = async () => {
    let timeLine;

    if (
      this.state.turma.type == "campaignLevel1" &&
      this.state.currentWorkshop !==
        this.state.workshops[this.state.workshops.length - 1]
    ) {
      timeLine = this.state.timeLineWorkshop;
    } else {
      timeLine = this.state.timeLineCampaign;
    }
    const index = timeLine.findIndex((step) => step.current);

    if (index == timeLine.length - 1) {
      MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        cancelButtonText: `${this.props.t("i_Class.btnBack")}`,
        cancelButtonColor: "#87BF40",
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <a href="javascript:void(0)">{this.props.t("i_Class.btnFinish")}</a>
        ),
        customClass: {
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class",
        },
        icon: "warning",
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        title: `${this.props.t("i_Class.finishTitle")}`,
        html: (
          <p style={{ color: "#E81E47" }}>
            {this.props.t("i_Class.finishText")} <br />
            <br />
            <span style={{ color: "#F69522" }}>
              {this.props.t("i_Class.finishAlert")}
            </span>
          </p>
        ),
      }).then((result) => {
        if (result.value) this.finishTurma();
      });
    } else {
      timeLine[index].current = false;
      timeLine[index + 1].current = true;
      await this.setState({
        timeLine: timeLine,
        stepOficina: index,
        _step: index + 1,
      });
    }
  };

  // Concluir turma
  finishTurma = async () => {
    let { code } = this.props.match.params;
    const classCode = await ClassService.classExist(code);
    const getCompletedClassesByInstructor =
      await ClassService.classCompletedByInstructor(classCode.instructor);

    if (!this.canBeCompleted()) return this.finishErrorAlert();

    const res = await ClassService.completeClass({
      code: this.state.turma.code,
      workshop: this.state.workshopId,
      step: "completed",
    });

    const type = this.state.turma.type;

    if (res._id) {
      if (
        (type === "campaignLevel2" || type === "campaign") &&
        this.state.stepCampaign === 3
      ) {
        return this.finishFourthWorkshop();
      } else if (
        this.state.stepCampaign === 4 &&
        getCompletedClassesByInstructor.length === 0
      ) {
        return this.certificateAlert();
      }
      return this.finishSuccessAlert();
    }

    return this.finishErrorAlert();
  };

  canBeCompleted = () => {
    const type = this.state.turma.type;
    const currentWorkshop = this.state.currentWorkshop;

    if (
      type === "campaignLevel1" &&
      currentWorkshop !== this.state.workshops[this.state.workshops.length - 1]
    ) {
      return (
        currentWorkshop.presenceCompleted && currentWorkshop.powersCompleted
      );
    } else if (
      type === "campaign" ||
      type === "campaignLevel2" ||
      (type === "campaignLevel1" &&
        currentWorkshop ===
          this.state.workshops[this.state.workshops.length - 1])
    ) {
      return (
        currentWorkshop.presenceCompleted &&
        currentWorkshop.powersCompleted &&
        currentWorkshop.missionsCompleted &&
        currentWorkshop.starsCompleted
      );
    }
  };

  finishSuccessAlert = () => {
    const component = this;
    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${this.props.t("i_Class.btnBackHome")}`,
      icon: "success",
      title: <p>{this.props.t("i_Class.successFinished")}</p>,
    }).then(function () {
      const navigator = navigate.bind(component);

      navigator({ link: "/turmas-list" });
    });
  };

  certificateAlert = () => {
    const component = this;

    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${this.props.t(
        "i_Class.finishedFirstClassCertificateBtn"
      )}`,
      icon: "success",
      title: `${this.props.t("i_Class.finishedFirstClassCertificateTitle")}`,
      text: `${this.props.t("i_Class.finishedFirstClassCertificateSubtext")}`,
    }).then(function () {
      const navigator = navigate.bind(component);

      navigator({ link: "/turmas-list" });
    });
  };

  finishFourthWorkshop = () => {
    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${this.props.t("i_Class.btnCopy")}`,
      icon: "success",
      title: <h5>{this.props.t("i_Class.successFinished")}</h5>,
      html: (
        <small style={{ color: "#000000" }}>
          {this.props.t("i_Class.shareSurvey")}
        </small>
      ),
      input: "text",
      inputValue: `${this.props.t("i_Class.linkSurvey")}`,
      inputAttributes: {
        readonly: true,
      },
    }).then(() => {
      const copyText = document.querySelector(".swal2-input");
      copyText.select();
      document.execCommand("copy");
      this.copySuccess();
    });
  };

  copySuccess = () => {
    const component = this;

    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${this.props.t("i_Class.btnBackHome")}`,
      icon: "success",
      title: <p>{this.props.t("i_Class.copiedLink")}</p>,
    }).then(function () {
      const navigator = navigate.bind(component);

      navigator({ link: "/turmas-list" });
    });
  };

  finishErrorAlert = () => {
    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${this.props.t("i_Class.btnBack")}`,
      icon: "error",
      title: <p>{this.props.t("i_Class.failingFinish")}</p>,
      text: `${this.props.t("i_Class.failingMessage")}`,
    });
  };

  showSlides = async (n) => {
    let options = document.getElementsByClassName("menu-item");
    let slides = document.getElementsByClassName("item");

    if (slides.length && options.length) {
      for (let i = 0; i < slides.length; i++) slides[i].style.display = "none";
      for (let i = 0; i < options.length; i++)
        options[i].className = options[i].className.replace(" active", "");

      slides[n - 1].style.display = "block";
      options[n - 1].className += " active";
    } 
  };

  nextStepText = () => {
    const type = this.state.turma.type;
    const timeLine =
      type === "campaignLevel1" &&
      this.state.currentWorkshop !==
        this.state.workshops[this.state.workshops.length - 1]
        ? this.state.timeLineWorkshop
        : this.state.timeLineCampaign;

    if (!timeLine[timeLine.length - 1].current)
      return `${this.props.t("i_Class.btnNextStep")}`;

    return `${this.props.t("i_Class.btnFinishClass")}`;
  };

  changeLoadingToFalse = () => {
    this.setState({ Loading: false })
  };

  addCount = () => {
    this.setState({ count: this.state.count + 1 })
  }

  render() {
    return (
      <Loading fade={this.state.Loading}>
        <div>
          <Header />

          <div className="page-title-header">
            <h1 className="page-title">{this.props.t("i_Class.title")}</h1>
            <span className="page-title-border"></span>
          </div>

          <main className="oficina-instrutor-main">
            <h3 className="oficina-instrutor-class-name">
              {this.state.turma.name}
              <span>
                {this.props.t("i_Class.code")}: {this.state.turma.code}
              </span>
            </h3>

            {this.state.turma?.type ? (
              <ProgressoCampanha
                campaign={this.state.workshops}
                current={this.state.stepCampaign}
                t={this.props.t}
              />
            ) : (
              ""
            )}

            <p className="oficina-instrutor-instructions">
              {this.props.t("i_Class.timelineInfo")}
            </p>

            <div className="oficina-instrutor-timeline">
              <h4>{this.props.t("i_Class.timeline")}</h4>

              <div className="oficina-instrutor-timeline-progress">
                <IconPerson />
                <div>
                  {this.state.turma.type === "campaignLevel1" &&
                  this.state.currentWorkshop !==
                    this.state.workshops[this.state.workshops.length - 1]
                    ? this.state.timeLineWorkshop.map((step) => [
                        <span
                          className={
                            "timeline-list-item " +
                            (step.current ? "active" : "")
                          }
                        >
                          {step.step}
                        </span>,
                        <span className="timeline-list-item-divider"></span>,
                      ])
                    : this.state.timeLineCampaign.map((step) => [
                        <span
                          className={
                            "timeline-list-item " +
                            (step.current ? "active" : "")
                          }
                        >
                          {step.step}
                        </span>,
                        <span className="timeline-list-item-divider"></span>,
                      ])}
                </div>
                <CheckMark />
              </div>

              {this.state.turma.type === "campaignLevel1" &&
              this.state.currentWorkshop !==
                this.state.workshops[this.state.workshops.length - 1]
                ? this.state.timeLineWorkshop.map((step, index) => {
                    if (step.current) {
                      return (
                        <p className="timeline-instructions" key={index}>
                          {step.description}
                        </p>
                      );
                    }
                  })
                : this.state.timeLineCampaign.map((step, index) => {
                    if (step.current) {
                      return (
                        <p className="timeline-instructions" key={index}>
                          {step.description}
                        </p>
                      );
                    }
                  })}
            </div>

            <h3
              className="oficina-instrutor-content-title"
              onClick={() => this.openDescriptionModal()}
            >
              {this.state.conteudo.oficinaName}
            </h3>

            <DescriptionModal conteudo={this.state.conteudo} t={this.props.t} />

            {/* 
              Slides da oficina (apresentação, mão na massa e resultados) 
            */}
            <ul className="menu-list">
              <li className="menu-item" onClick={() => this.showSlides(1)}>
                {this.props.t("i_Class.presentation")}
              </li>
              <li className="menu-item" onClick={() => this.showSlides(2)}>
                {this.props.t("i_Class.handsOn")}
              </li>
              <li className="menu-item" onClick={() => this.showSlides(3)}>
                {this.props.t("i_Class.results")}
              </li>
            </ul>
            {this.state.workshopId && (
              <WorkshopSlider
                changeLoadingToFalse={this.changeLoadingToFalse}
                addCount={this.addCount}
                count={this.state.count}
                workshopId={this.state.workshopId}
                language={this.state.lang}
              />
            )}

            {/* 
              botões de ações da oficina (presença, power, mission e star) 
            */}
            <div className="card oficina-instrutor-actions">
              <h4>{this.props.t("i_Class.actions")}</h4>

              <div className="actions-buttons-line line1">
                <Link
                  to={`/turmas-presenca/${this.state.turma.code}/${this.state.workshopId}`}
                  className={`actions-button ${
                    this.state._step !== 1 ||
                    this.state.currentWorkshop.presenceCompleted
                      ? "disabled"
                      : ""
                  }`}
                >
                  {this.props.t("i_Class.btnPresence")}
                </Link>

                <Link
                  to={`/atribuicao-poderes/${this.state.turma.code}/${this.state.workshopId}`}
                  className={`actions-button 
                  ${
                    this.state._step !== 3 ||
                    this.state.currentWorkshop.missionsCompleted ||
                    !this.state.currentWorkshop.presenceCompleted
                      ? "disabled"
                      : ""
                  }`}
                >
                  {this.props.t("i_Class.btnPowers")}
                </Link>
              </div>
              {
                // só retornar esses botões se for modo campanha
                (this.state.turma.type === "campaign" &&
                  this.state.workshops.length > 0) ||
                (this.state.turma.type === "campaignLevel2" &&
                  this.state.workshops.length > 0) ||
                (this.state.turma.type === "campaignLevel1" &&
                  this.state.currentWorkshop ==
                    this.state.workshops[this.state.workshops.length - 1]) ? (
                  <div className="actions-buttons-line">
                    <Link
                      to={`/atribuicao-missoes/${this.state.turma.code}/${this.state.workshopId}`}
                      className={`actions-button campaign-buttons
                      ${
                        this.state._step !== 4 ||
                        this.state.currentWorkshop.starsCompleted ||
                        !this.state.currentWorkshop.presenceCompleted ||
                        !this.state.currentWorkshop.powersCompleted
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {this.props.t("i_Class.btnMission")}
                    </Link>
                    <Link
                      to={`/atribuicao-estrelas/${this.state.turma.code}/${this.state.workshopId}`}
                      className={`actions-button campaign-buttons
                      ${
                        this.state._step !== 5 ||
                        this.state.currentWorkshop.starsCompleted ||
                        !this.state.currentWorkshop.presenceCompleted ||
                        !this.state.currentWorkshop.powersCompleted ||
                        !this.state.currentWorkshop.missionsCompleted
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {this.props.t("i_Class.btnStar")}
                    </Link>
                  </div>
                ) : (
                  ""
                )
              }
            </div>

            {/* <a href={`/edit-turma/${this.state.turma.code}`} className="oficina-instrutor-edit-button button-full">{this.props.t('i_Class.btnEdit')}</a> */}
            <div className="oficina-instrutor-step-buttons">
              <button
                className="oficina-instrutor-button-previous button-full"
                onClick={() => this.backStep()}
              >
                {this.props.t("i_Class.btnPreviousStep")}
              </button>

              <button
                className="oficina-instrutor-button-next button-full"
                onClick={() => this.nextStep()}
              >
                {this.nextStepText()}
              </button>
            </div>
          </main>
        </div>
      </Loading>
    );
  }
}

export default Oficina;
