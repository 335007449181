import { Component } from "react";
import "./CardImagem.scss";

import LocalStorage from "../../../../../services/localStorage.service";

import Swal from "sweetalert2";

import SendMissionService from "../../../../../services/sendmission.service";

export default class CardImagem extends Component {
  constructor(props) {
    super(props);
    this.lang = LocalStorage.retrieve("language");

  }

  adicionarNovaImagem() {
    document
      .getElementById(this.props.indice + "-cardImagem-enviarImagemInput")
      .click();
  }

  apagarImagem() {
    Swal.fire({
      text: this.props.t("i_CardImage.deleteModalTitle"),
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d33',
      confirmButtonText: this.props.t("i_CardImage.deleteModalConfirm"),
      cancelButtonText: this.props.t("i_CardImage.deleteModalCancel"),
      reverseButtons: true,
      width: '400px',
      customClass: {
        content: 'cardImagem-modalDeleteText',
        confirmButton: 'cardimagem-modalDeleteConfirmButton',
        cancelButton: 'cardimagem-modalDeleteCancelButton',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const indice = this.props.indice;
        let participantName = this.props.participantName;

        if (this.props.url) {
          const code = this.props.code;
          const imageDriveId = this.props.imageDriveId;
          SendMissionService.deleteImageByDriveId(code, imageDriveId);
        }

        this.props.apagarImagem(indice, participantName);
      }
    })
  }

  generateBackgroundImage() {
    if (!this.props.imagem) {
      return this.props.url;
    }
    return this.props.imagem;
  }

  render() {
    const backgroundImage = this.generateBackgroundImage();

    return (
      <section id={"cardImagem-section"}>
        <div id={"-item"} className="cardImagem-item" style={{ background: `url('${backgroundImage}') center center / cover`, backgroundSize: "cover" }}>
          <input
            id={"-cardImagem-enviarImagemInput"}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            hidden
          />
        </div>
        <div className="cardImagem-dadosDoParticipante">
          <p className="cardImagem-nomeDoParticipante">
            {
              this.props.participantName
            }
            <span
              className="cardImagem-excluirImagemButton"
              onClick={this.apagarImagem.bind(this)}
            >
              X
            </span>
          </p>
          <p className="cardImagem-missaoDoParticipante">
            {
              this.props.missionName
            }
          </p>
        </div>
      </section>
    );
  }
}
