import React, { Component } from 'react';
import './ForgotPassword.scss';

import logoHorizontal from '../../../../assets/images/logo/logo-horizontal.png';

import InstructorService from '../../../../services/instructor.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class ForgotPassword extends Component {
  constructor(props){
    super(props)
    this.state = {
      email: ''
    }
  }

  changeEmail(e){this.setState({ email: e.target.value })}

  recovery = async() => {
    try{
      if(this.state.email == ''){
        MySwal.fire({
          confirmButtonColor: '#87BF40',
          confirmButtonText: <span>OK</span>,
          icon: 'error',
          title: <p>{this.props.t('forgotPassword.placeholder')}</p>
        })
      } else {
        let res = await InstructorService.recoveryPassword(this.state.email);
        if(res._id){
          MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: "OK",
            icon: 'success',
            title: <p>{this.props.t('forgotPassword.success')}</p>
          }).then(function() {
            window.location = "/";
        });            
        }
      }
    } catch(err){
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: <span>OK</span>,
        icon: 'error',
        title: <p>{this.props.t('forgotPassword.error')}</p>
      })
    }
  }

  render(){
    return(
      <div className="forgot">
        <header className="login-header">
          <img src={logoHorizontal} className="login-header-image" alt="logo IFA" />
          <h1 className="login-header-title">{this.props.t('forgotPassword.title')}</h1>
        </header>

        <main className="forgot-main">
          <h2 className="forgot-subtitle">{this.props.t('forgotPassword.subtitle')}</h2>

          <p className="forgot-text">{this.props.t('forgotPassword.text')}</p>

          <label
            className="forgot-label"
            htmlFor="email"
          >
            {this.props.t('forgotPassword.label')}
          </label>
          <input
            className="forgot-input"
            placeholder={this.props.t('forgotPassword.placeholder')}
            type="email"
            onChange={this.changeEmail.bind(this)}
          />

          <button
            className="button-full forgot-btnRecover"
            onClick={() => this.recovery()}
          >
            {this.props.t('forgotPassword.btnRecover')}
          </button>

          <a
            className="forgot-backLink"
            href="/login-instrutor"
          >
            {this.props.t('forgotPassword.backLink')}
          </a>

        </main>
      </div>
    );
  }
}

export default ForgotPassword;