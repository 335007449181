import React, { Component } from 'react';

import './InstructorRanking.scss';
import Header from '../../../Header/Header';
import { ReactComponent as IfaIcon } from '../../../../assets/images/logo/icone-ifa.svg';
import { ReactComponent as StarIcon } from '../../../../assets/images/participante/estrelas.svg';

import ParticipantService from '../../../../services/participant.service';

export default class Ranking extends Component {
  constructor() {
    super()
    this.state = {
      participants: []
    }
  }

  participantsPodium() {
    let participantsList = []
    let first, second, third

    this.state.participants.map((item, index) => {
      if (index === 1) {
        second = (
          <div className="ranking-podium-item">
            <div>
              {/* <span className="avatar"></span> */}
              <span className="name">{item.name.split(' ')[0]}</span>
              <span className="points">
                <IfaIcon
                  className="ifa-icon"
                />
                x{item.IFAs}
              </span>
            </div>
            <div key={index} className="ranking-podium-base second">
              <span className="placing">{index + 1}</span>
            </div>
          </div>
        )
      } else if (index === 0) {
        first = (
          <div className="ranking-podium-item">
            <div>
              {/* <span className="avatar"></span> */}
              <span className="name">{item.name.split(' ')[0]}</span>
              <span className="points">
                <IfaIcon
                  className="ifa-icon"
                />
                x{item.IFAs}
              </span>
            </div>
            <div key={index} className="ranking-podium-base first">
              <span className="placing">{index + 1}</span>
            </div>
          </div>
        )
      } else if (index === 2) {
        third = (
          <div className="ranking-podium-item">
            <div>
              {/* <span className="avatar"></span> */}
              <span className="name">{item.name.split(' ')[0]}</span>
              <span className="points">
                <IfaIcon
                  className="ifa-icon"
                />
                x{item.IFAs}
              </span>
            </div>
            <div key={index} className="ranking-podium-base third">
              <span className="placing">{index + 1}</span>
            </div>
          </div>
        )
      }
    })

    participantsList.push(second, first, third)
    return participantsList;
  }

  componentWillMount = async () => {
    let urlSplit = window.location.href.split('/');
    const classCode = urlSplit[urlSplit.length - 1];

    const participants = await ParticipantService.rankingByClass(classCode);

    await this.setState({
      participants: participants
    })
  }

  participantsRanking() {
    let participantsList = []

    participantsList = this.state.participants.map((item, index) => {
      if (index >= 3) {
        return (
          <li key={index} className="ranking-list-item">
            <span className="placing">{index + 1}</span>
            <span className="name">
              {item.name.includes(' ') ? 
              `${item.name.split(' ')[0]} ${item.name.split(' ')[item.name.split(' ').length - 1]}` :
              `${item.name}`}
            </span>
            <span className="points">
              <IfaIcon
                className="ifa-icon"
              />
              x{item.IFAs}
            </span>
          </li>
        )
      }
    })
    return participantsList;
  }

  render() {
    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('ranking.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="ranking-main">
          <p className="ranking-main-text">
            {this.props.t('ranking.introInstructor')}
          </p>

          <div className="card ranking-legend">
            <h3>{this.props.t('ranking.subtitle')}</h3>
            <span>
              <StarIcon className="star half" /> = <IfaIcon className="ifa" /> x5
            </span>
            <span>
              <StarIcon className="star full" /> = <IfaIcon className="ifa" /> x10
            </span>
            <span>
              {this.props.t('ranking.quiz')} = <IfaIcon className="ifa" /> x1
            </span>
          </div>

          <div className="ranking-podium">
            {this.participantsPodium()}
          </div>

          <div className="card ranking-content">
            <ul className="ranking-list">
              {this.participantsRanking()}
            </ul>

          </div>
        </main>
      </div>
    );
  }
}