import React, { Component } from 'react';
import './Estrelas.scss';

import Header from '../../Header/Header';
import { ReactComponent as StarIcon } from '../../../assets/images/participante/estrelas.svg';

import LocalStorage from '../../../services/localStorage.service';
import ParticipantService from '../../../services/participant.service';
import MissionService from '../../../services/mission.service';
import PowerService from '../../../services/power.service';
import { ContextAuth } from '../../../context/authContext';

export default class Estrelas extends Component {
  static contextType = ContextAuth;

  constructor(){
    super()
    this.state = {
      stars: []
    }
  }

  componentWillMount = async() => {
    const lang = LocalStorage.retrieve('language');
    const logged = this.context.state.userData;

    let missions = await MissionService.getAll(this.context);
    let powers = await PowerService.getAll();
    missions = missions.map(mission => {
      return {
        _id: mission._id,
        power: mission.power,
        ...mission[lang]
      }
    })

    powers = powers.map(power => {
      return {
        _id: power._id,
        ...power[lang]
      }
    })

    let participant = await ParticipantService.getById(logged._id);
    participant.stars = participant.stars.map(star => {
      const powerId = participant.powers.find(p => p.workshop == star.workshop).powerId;
      const missionId = participant.missions.find(m => m.workshop == star.workshop).missionId;

      star.power = powers.filter(p => p._id == powerId)[0].name;
      star.mission = missions.filter(m => m._id == missionId)[0].name;

      return star;
    });

    await this.setState({
      stars: participant.stars
    })
  }

  renderStarsList(){
    let startsList = []

    startsList = this.state.stars.map((item, index) =>
      {
        return(
          <li className="list-estrelas-item" key={ index }>
            <div className="participant-info">
              <span className="mission">{ item.mission }</span>
              <span className="power">{ item.power }</span>
            </div>

            <div className="star-info">
              {
                item.type == '1' ? <StarIcon className="icon complete" /> : <StarIcon className="icon half" />
              }
            </div>
          </li>
        )
      }
    )

    return startsList;
  }

  render() {
    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('p_stars.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="list-estrelas-main">
          <div className="card">
            <div className="list-estrelas-header">
              <span>{this.props.t('p_stars.missions')}</span>
              <span>{this.props.t('p_stars.stars')}</span>
            </div>

            <ul>
              { this.renderStarsList() }
            </ul>
          </div>

          <a
            className="list-estrelas-button button-full"
            href="/home-participante"
          >
            {this.props.t('p_stars.btnBack')}
          </a>
        </main>
      </div>
    );
  }
}