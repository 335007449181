export const languageToMomentLocale = () => {
  const language = localStorage.getItem("language");

  if (language === '"pt"') {
    return "pt-BR";
  } else if (language === '"es"') {
    return "es-ES";
  } else {
    return "en-US";
  }
}

export const languageMomentPreposition =
languageToMomentLocale() === "en-US" ? " " : " de ";