import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ContextAuth } from '../../../context/authContext';
import LocalStorage from '../../../services/localStorage.service';
import ParticipantService from '../../../services/participant.service';
import SecurityService from '../../../services/security.service';
import missionStatus from '../../../shared/constants/missionStatusTypes';
import participantMissionImagesLimit from '../../../shared/constants/participantMissionImagesLimit';
import getTranslation from '../../../utils/getTranslation';
import Header from '../../Header/Header';
import Loading from '../../Loading/Loading';

import '../Missoes/Missoes.scss';
import AddImage from './components/AddImage/AddImage';

import ImageList from './components/ImageList/ImageList';
import MissionStatusFlag from './components/MissionStatusFlag/MissionStatusFlag';
import SendButton from './components/SendButon/SendButton';

const MissionUploadPage = props => {
  const getDataFromAuthContext = useContext(ContextAuth);
  const context = props.isModal ? props.context : getDataFromAuthContext;

  // I know this is somewhat grotesque, calling a hook inside an if statement but that was the only
  // way I figured to get it working
  let userId, missionId;
  if (props.isModal){
    userId = props.id;
    missionId = props.missionId
  } else
  {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const params = useParams();
    userId = params.id;
    missionId = params.missionId
  }

  const [images, setImages] = useState([]);
  const [imagesAlreadySent, setImagesAlreadySent] = useState(false);
  const [currentUserMissionStatus, setCurrentUserMissionStatus] =
    useState(undefined);
  const [missionInformations, setMissionInformations] = useState(undefined);
  const [isDataFeching, setIsDataFetching] = useState(true);

  const isInstructor = SecurityService.isInstructor(context);
  const lang = LocalStorage.retrieve('language');

  useEffect(() => {
    setIsDataFetching(true);

    ParticipantService.getUserMissionInfo(userId, missionId).then(
      ({ missionDescription, userMission }) => {
        if (
          userMission.status === missionStatus.inProgress ||
          userMission.status === missionStatus.completed
        ) {
          setImages(userMission.images);
          setImagesAlreadySent(true);
        }

        setCurrentUserMissionStatus(userMission.status);
        setMissionInformations(missionDescription[lang]);
        setIsDataFetching(false);
      }
    );
  }, [context.userData]);

  /**
   * This function returns the actual JSX that'll be rendered by the component. It was extracted
   * from the main function's return because the `Loading` wrapper needs to be rendered
   * selectively, only when this component is called outside a modal. Thus, the extraction was
   * needed to prevent code duplication.
   * @returns {JSX.Element}
   */
  function renderContent(){
    return (
      <>
        {props.isModal ? null : <Header/>}
        <div className="page-title-header">
          <h1 className="page-title">
            {isInstructor
              ? getTranslation('participantMissionUpload.instructorViewTitle')
              : getTranslation('participantMissionUpload.sendMission')}
          </h1>
          <span className="page-title-border"/>
        </div>

        <main className="sendMission-main">
          <h3 className="turma-class-name">{missionInformations?.name}</h3>

          <section
            className="listaDeImagens_section"
            style={{ marginTop: '20px' }}>
            <MissionStatusFlag
              currentUserMissionStatus={currentUserMissionStatus}
              imagesAlreadySent={imagesAlreadySent}
            />

            {!imagesAlreadySent && !isInstructor && (
              <div className="listaDeImagens_div">
                <p>
                  {getTranslation('i_ListImages.title')}
                  <SendButton
                    images={images}
                    imagesAlreadySent={imagesAlreadySent}
                    userMissionId={missionId}
                  />
                </p>
                <div>
                  {getTranslation('participantMissionUpload.sendFivePictures')}
                </div>
              </div>
            )}

            <div className="listaDeImagens_fotos">
              <ul className="listaDeImagens_ul">
                <ImageList
                  images={images}
                  imagesAlreadySent={imagesAlreadySent}
                  setImages={setImages}
                />
                {images.length < participantMissionImagesLimit &&
                  !imagesAlreadySent &&
                  !isInstructor && (
                    <AddImage images={images} setImages={setImages}/>
                  )}
              </ul>
            </div>
            {!imagesAlreadySent && !isInstructor && (
              <div className="image-limit-size-warn">
                <p>{getTranslation('participantMissionUpload.sizeLimitInfo')}</p>
              </div>
            )}
          </section>
        </main>
      </>
    );
  }

  return (
    <>
      {!props.isModal ? (
        <Loading fade={isDataFeching}>
          {renderContent()}
        </Loading>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default MissionUploadPage;
