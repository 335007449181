import React, { Component } from "react";
import Header from "../../Header/Header";
import "./Agendamentos.scss";

import * as moment from "moment";
import LocalStorage from "../../../services/localStorage.service";
import ClassService from "../../../services/class.service";
import WorkshopService from "../../../services/workshop.service";
import { ContextAuth } from "../../../context/authContext";

export default class Agendamentos extends Component {
  static contextType = ContextAuth;
  constructor(props) {
    super(props);
    this.state = {
      nextClasses: [],
    };
  }

  componentWillMount = async () => {
    const lang = LocalStorage.retrieve("language");
    const instrutor = this.context.state.userData;

    if (instrutor._id) {
      const classes = await ClassService.classesByIntructor(instrutor._id);
      const finishedClasses = await ClassService.classCompletedByInstructor(
        instrutor._id
      );

      let works = await WorkshopService.getAll();
      
      works = works.map((work) => ({
        _id: work._id,
        last: work.last,
        selected: false,
        ...work[lang],
      }));

      let finishedIds = [];
      let classesStarted = [];

      // Excluir turmas que já foram concluídas
      finishedIds = finishedClasses.map((f) => {
        return f._id;
      });

      classes.forEach((c) => {
        if (!finishedIds.includes(c._id)) {
          classesStarted.push(c);
        }
      });

      let dates = [];
      if (classesStarted.length > 0) {
        for (let c of classesStarted) {
          let classes = await ClassService.getById(c._id);
          let workshops = classes.workshops;

          for (let w of workshops) {
            if (moment(w.dateTime) > moment(new Date())) {
              dates.push({
                name: c.name,
                date: moment(w.dateTime),
                type: classes.type,
                workshop:
                  works.length &&
                  works.find((wk) => wk._id === w.workshop).name,
              });
            }
          }
        }
      }

      dates.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      });

      await this.setState({
        nextClasses: dates,
      });
    }
  };

  render() {
    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t("i_schedules.title")}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="agendamentos-main">
          {this.state.nextClasses.length > 0
            ? this.state.nextClasses.map((nextClass, index) => {
                return (
                  <div className="card agendamentos-card" key={index}>
                    <p>
                      {this.props.t("i_schedules.class")}:{" "}
                      <span>{nextClass.name}</span>
                    </p>
                    <p>
                      {this.props.t("i_schedules.type")}:{" "}
                      <span>
                        {nextClass.type === "workshop"
                          ? `${this.props.t("i_schedules.typeWorkshop")}`
                          : nextClass.type ===
                            "campaign" ? `${this.props.t(
                              "i_schedules.typeCampaign"
                            )}`
                          : nextClass.type ===
                          "campaignLevel1" ? `${this.props.t(
                            "i_schedules.typeCampaignLevel1"
                          )}` : `${this.props.t(
                            "i_schedules.typeCampaignLevel2"
                          )}`}
                      </span>
                    </p>
                    <p>
                      {this.props.t("i_schedules.workshop")}:{" "}
                      <span>{nextClass.workshop}</span>
                    </p>
                    <p>
                      {this.props.t("i_schedules.date")}:{" "}
                      <span>
                        {" "}
                        {nextClass.date
                          .locale(`${this.props.t("i_schedules.dateLang")}`)
                          .format("LL")}
                      </span>
                    </p>
                  </div>
                );
              })
            : null}
        </main>
      </div>
    );
  }
}
