import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import getTranslation from "../../../../../../utils/getTranslation";

const MySwal = withReactContent(Swal);

const AddPresenceListImage = ({ images, setImages }) => {
  const imageHandler = (e) => {
    let newImage = { file: "", previewImg: "" };
    const reader = new FileReader();
    const fileRecieved = e.target.files[0];
    const imageMaxSize = 3145728; // 3MB

    // definir callback
    reader.onload = () => {
      if (reader.readyState === 2) {
        newImage.previewImg = reader.result;

        setImages([...images, newImage]);
      }
    };

    // carregar arquivo
    if (fileRecieved.size > imageMaxSize) {
      MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>OK</span>,
        icon: "error",
        title: (
          <p>
            {getTranslation("participantMissionUpload.sizeError.largeImage")}
          </p>
        ),
        text: getTranslation(
          "participantMissionUpload.sizeError.limitDescription"
        ),
      });
    } else {
      reader.readAsDataURL(fileRecieved);
      newImage.file = fileRecieved;
    }
  };

  return (
    <li>
      <section id={"cardImagem-section"}>
        <div id={"cardImagem-item"} className="cardImagem-item">
          <button
            className="cardImagem-enviarImagemButton"
            onClick={() =>
              document.getElementById("cardImagem-enviarImagemInput").click()
            }
          >
            +
          </button>
          <input
            id={"cardImagem-enviarImagemInput"}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            hidden
            onInput={(e) => imageHandler(e)}
          />
        </div>
      </section>
    </li>
  );
};

export default AddPresenceListImage;
