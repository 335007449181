import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Conteudo.scss";

import Header from "../../../Header/Header";

import LocalStorage from "../../../../services/localStorage.service";
import trainingService from "../../../../services/training.service";

import Loading from "../../../Loading/Loading";
import { navigate } from "../../../../services/navigation.service";
import { ContextAuth } from "../../../../context/authContext";

class ConteudoTreinamento extends Component {
  static contextType = ContextAuth;
  constructor(props) {
    super(props);
    this.state = {
      content: {
        contents: [],
      },
      Loading: true,
      id: "",
    };
  }

  componentWillMount = async () => {
    const lang = LocalStorage.retrieve("language");
    let { id } = this.props.match.params;

    let conteudo = await trainingService.getById(id);

    conteudo = {
      _id: conteudo._id,
      ...conteudo[lang]
    }

    await this.setState({
      content: conteudo,
      Loading: false,
      id: id,
    });
  };

  listContent = () => {
    let listItem = [];
    listItem = this.state.content.contents.map((content, i) => {
      return (
        <div className="conteudo-treinamento-card">
          <input id={`${this.state.content._id}-${i}`} type="checkbox" />
          <label htmlFor={`${this.state.content._id}-${i}`}>
            <span className="conteudo-treinamento-card-title">
              <h3>{content.title}</h3>
              <span></span>
            </span>
          </label>

          {content.contentVideo ? (
            <div className="conteudo-treinamento-card-text">
              <iframe
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="conteudo-treinamento-video"
                frameborder="0"
                height="250"
                src={content.contentVideo}
                style={{ marginTop: "50px" }}
                title={content.title}
                width="100%"
              ></iframe>
              {content.text}
            </div>
          ) : (
            <div className="conteudo-treinamento-card-text">{content.text}</div>
          )}
        </div>
      );
    });
    return listItem;
  };

  render() {
    return (
      <Loading fade={!!this.state.Loading}>
        <div>
          <Header />
          <div className="page-title-header">
            <h1 className="page-title">{this.state.content.name}</h1>
            <span className="page-title-border"></span>
          </div>

          <main className="conteudo-treinamento-main">
            <iframe
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              className="conteudo-treinamento-video"
              frameborder="0"
              height="210"
              src={this.state.content.video}
              title="Sobre o IFA"
              width="100%"
            ></iframe>

            <h1>{this.listContent()}</h1>

            {this.state.id === "62f65732faae58370c12c2bf" ? null : (
              <a
                // to={`/quiz-instrutor/${this.state.content._id}`}
                className="conteudo-treinamento-button button-full"
                onClick={() => {
                  const navigator = navigate.bind(this);
                  navigator({
                    link: `/quiz-instrutor/${this.state.content._id}`,
                  });
                }}
              >
                {this.props.t("i_trainingContent.btnQuiz")}
              </a>
            )}
          </main>
        </div>
      </Loading>
    );
  }
}

export default ConteudoTreinamento;
