import { useEffect, useState } from "react";
import ImageList from "../../../../Participante/MissionUploadPage/components/ImageList/ImageList";
import AddPresenceListImage from "./AddPresenceListImage/AddPresenceListImage";
import SendPresenceListButton from "./Components/SendButton/SendPresenceListButton";
import { useParams } from "react-router";
import Header from "../../../../Header/Header";
import Loading from "../../../../Loading/Loading";
import ClassService from "../../../../../services/class.service";
import WorkshopService from "../../../../../services/workshop.service";
import LocalStorage from "../../../../../services/localStorage.service";
import getTranslation from "../../../../../utils/getTranslation";

const UploadPresenceList = (props) => {
  const presenceListImagesLimit = 3;
  const [images, setImages] = useState([]);
  const [imagesAlreadySent, setImagesAlreadySent] = useState(false);
  const [isDataFeching, setIsDataFetching] = useState(true);
  const [workshopData, setWorkshopData] = useState();
  const [firstWorkshopIncompletedId, setFirstWorkshopIncompletedId] =
    useState();
  const [classInfo, setClassInfo] = useState();
  const language = LocalStorage.retrieve("language");

  const params = useParams();

  const classId = params.code;

  useEffect(() => {
    const loadData = async () => {
      try {
        const classInfo = await ClassService.getById(classId);

        // pegar o primeiro workshop com presenceCompleted falso
        const firstWorkshopIncompleted = classInfo.workshops.find(
          (workshop) => !workshop.completed
        );

        // buscar o worshop no banco pra obter o nome dele
        const workshopData = await WorkshopService.getById(
          firstWorkshopIncompleted.workshop
        );

        setFirstWorkshopIncompletedId(firstWorkshopIncompleted._id);
        setClassInfo(classInfo);
        setWorkshopData(workshopData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsDataFetching(false);
      }
    };

    loadData();
  }, []);

  return (
    <>
      {isDataFeching ? (
        <Loading fade={isDataFeching} />
      ) : (
        <>
          <Header />
          <div className="page-title-header">
            <h1 className="page-title">
              {getTranslation("i_SendPresenceListImages.title")}
            </h1>
            <span className="page-title-border" />
          </div>
          <main className="sendMission-main">
            <h3 className="turma-class-name">
              {getTranslation("i_SendPresenceListImages.workshop")}{" "}
              {workshopData[language].name}
            </h3>

            <section
              className="listaDeImagens_section"
              style={{ marginTop: "20px" }}
            >
              <div className="listaDeImagens_div">
                <p>
                  <SendPresenceListButton
                    images={images}
                    classId={classId}
                    workshopId={firstWorkshopIncompletedId}
                  />
                </p>
                <div>
                  {getTranslation(
                    "i_SendPresenceListImages.textImagesMaxLength"
                  )}
                  <p>
                    {getTranslation(
                      "i_SendPresenceListImages.textImageMaxSize"
                    )}
                  </p>
                </div>
              </div>

              <div className="listaDeImagens_fotos">
                <ul className="listaDeImagens_ul">
                  <ImageList
                    images={images}
                    imagesAlreadySent={imagesAlreadySent}
                    setImages={setImages}
                  />
                  {images.length < presenceListImagesLimit && (
                    <AddPresenceListImage
                      images={images}
                      setImages={setImages}
                    />
                  )}
                </ul>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default UploadPresenceList;
