import React from 'react';
import LocalStorage from '../services/localStorage.service';
import { UserDataService } from '../services/userData.service';

const ContextAuth = React.createContext({
    token: null,
    tokenExp: null,
    role: null,
    userData: null,
    setToken: null,
    setRole: null,
    setUserData: null,
    setContext: null
});

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        token: LocalStorage.retrieve('token'),
        tokenExp: LocalStorage.retrieve('tokenExp'),
        role: LocalStorage.retrieve('role'),
        userData: LocalStorage.retrieve('userData')
    }

    setContext = (newState, callback) => {
    
        for (const [key, value] of Object.entries(newState)) {
            LocalStorage.persist(key, value)
        }
        
        this.setState(state => ({ ...state, ...newState }), () => {
            if (typeof callback == 'function') callback()
        })

    }

    clearContext = () => {
        this.setState(state => {
            const stateWithNullValues = {}

            for (const key in state) {
                stateWithNullValues[key] = null
            }

            return ({ ...stateWithNullValues })
        }, () => {
            LocalStorage.clear();
         })
    }

    async updateData () {
        if(!this.userIsLoggedIn()) return

        const userDataService = new UserDataService(this);
        const newUserData = await userDataService.fetch({ 
            role: this.state.role,
            id: this.state.userData._id 
        });

        this.setContext({ userData: newUserData })
    } 

    userIsLoggedIn() {
        return Boolean(this.state.userData?._id && this.state.token)
    }

    getLanguage() {
        return LocalStorage.retrieve('language') ?? 'pt'
    }

    componentDidMount() {
        this.updateData();
    }

    render() {

        const state = this.state;
        const { 
            setContext, 
            clearContext,
            getLanguage 
        } = this;

        return (
            <ContextAuth.Provider value={{ 
                    state, 
                    setContext,
                    clearContext,
                    getLanguage
                }}>
                {this.props.children}
            </ContextAuth.Provider>
        )
    }
}
const AuthConsumer = ContextAuth.Consumer;

export { ContextAuth, AuthProvider, AuthConsumer }