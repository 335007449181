import React, { Component } from 'react';

import './Content/Participante/Content.scss'
import Header from '../../Header/Header';
import ProgressoCampanha from './Content/Participante/Campanha';

import LocalStorage from '../../../services/localStorage.service';
import ClassService from '../../../services/class.service';
import WorkshopService from '../../../services/workshop.service';
import AwsService from '../../../services/aws.service';
import { ContextAuth } from '../../../context/authContext';

let slideItem = 1;

class Oficina extends Component {
  static contextType = ContextAuth;
  
  constructor(props) {
    super(props);
    this.state = {
      classType: '',
      workshopId: '',
      workshops: '',
      currentWorkshop: '',
      stepCampaign: 0,
      AwsService: new AwsService(),
      fileList: [],
      lang: LocalStorage.retrieve('language'),
    }
  }


  // Se for modo Campanha , renderiza Progresso da Campanha
  renderCampanha() {
    if (this.state.classType === 'campaign') {
      return <ProgressoCampanha campaign={this.state.workshops} current={this.state.stepCampaign} t={this.props.t} handleClick={this.setStepCampaign}/>
    }
  }

  getLastCompletedWorkshop = async workshops => {
    await this.setState({
      stepCampaign: workshops.length - 1,
      workshops,
      workshopId: workshops[workshops.length - 1].workshop
    });

    //retorna ultimo workshop - todos completps
    return await WorkshopService.getById(workshops[workshops.length - 1].workshop);
  }

  getLastDoneWorkshop = async (workshops) => {
    const incompleteWorkshop = workshops.find(workshop => !workshop.completed);
    const workshopId = incompleteWorkshop.workshop;

    await this.setState({
        stepCampaign: workshops.findIndex(e => e.workshop === workshopId),
        workshops,
        workshopId: workshopId
    });

    return await WorkshopService.getById(workshopId);
  }

  // Altera dinamicamente o conteudo da pagina

  setStepCampaign = async step => {
    await this.changeContentByStep(this.state.workshops, step);
  }

  changeContentByStep = async (workshops, index) => {
    let workshopId = workshops[index].workshop;
    let currentWorkshop = await this.changeCurrentWorkshop(workshopId);

    await this.setState({
      stepCampaign: index,
      workshops,
      workshopId,
      currentWorkshop
    });
  }

  changeCurrentWorkshop = async workshopId => {
    let currentWorkshop = await WorkshopService.getById(workshopId);
    
    currentWorkshop = {
      _id: currentWorkshop._id,
      last: currentWorkshop.last,
      ...currentWorkshop[this.state.lang]
    }

    return currentWorkshop;
  }


  onlyOneWorkshop = async workshopId => {
    await this.setState({ workshopId});
    return await WorkshopService.getById(workshopId);
  }

  componentWillMount = async () => {
    const lang = LocalStorage.retrieve('language');
    const participant = this.context.state.userData;
    if (participant._id) {

      const classCode = await ClassService.classByParticipant(participant._id);
     
      // set - conteudo da pagina
      let currentWorkshop;
      if (classCode.type === 'workshop') {
        currentWorkshop = await this.onlyOneWorkshop(classCode.workshops[0].workshop);

      } else {

        const allCompleted = classCode.workshops.filter(wk => !wk.completed);

        if (allCompleted.length == 0){
          currentWorkshop = await this.getLastCompletedWorkshop(classCode.workshops);
        } else {
          //ultimo workshop realizado
          currentWorkshop = await this.getLastDoneWorkshop(classCode.workshops);  
        }
        
      }

      currentWorkshop = {
        _id: currentWorkshop._id,
        last: currentWorkshop.last,
        ...currentWorkshop[lang]
      }

      await this.setState({
        classType: classCode.type,
        currentWorkshop: currentWorkshop,
        lang: lang
      })
    }

    this.showSlides(1);
  }


  showSlides = async (n) => {
    let options = document.getElementsByClassName("oficina-main-menu-item");
    let slides = document.getElementsByClassName("item");
    if (n > slides.length) slideItem = 1
    if (n < 1) slideItem = slideItem.length

    for (let i = 0; i < slides.length; i++)
      slides[i].style.display = "none";
    for (let i = 0; i < options.length; i++)
      options[i].className = options[i].className.replace(" active", "");

    slides[n - 1].style.display = "block";
    options[n - 1].className += " active";
  }

  render() {
    return (
      <div>
        <Header />
        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('p_workshop.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="oficina-main">
          <h2 className="oficina-main-title oficina-name">
            {this.state.currentWorkshop.name}
          </h2>

          {this.renderCampanha()}

          <h2 className="oficina-main-title content">{this.props.t('p_workshop.contentTitle')}</h2>

          <ul className="oficina-main-menu">
            <li
              className="oficina-main-menu-item"
              onClick={() => this.showSlides(1)}
            >
              {this.props.t('p_workshop.presentation')}
            </li>
            <li
              className="oficina-main-menu-item"
              onClick={() => this.showSlides(2)}
            >
              {this.props.t('p_workshop.handsOn')}
            </li>
            <li
              className="oficina-main-menu-item"
              onClick={() => this.showSlides(3)}
            >
              {this.props.t('p_workshop.results')}
            </li>
          </ul>

          <div className="slider">
            <div className="item apresentacao">
              <img
                className="img-carousel"
                alt="Conteúdo da Oficina"
                src={`https://ifa-app.s3.us-east-2.amazonaws.com/${
                  this.state.workshopId
                }/apresentation_${
                  this.state.lang
                }.png`}
              />
            </div>
            <div className="item massa">
              <img
                className="img-carousel"
                alt="Conteúdo da Oficina"
                src={`https://ifa-app.s3.us-east-2.amazonaws.com/${
                  this.state.workshopId
                }/handson_${
                  this.state.lang
                }.png`}
              />
            </div>
            <div className="item resultados">
              <img
                className="img-carousel"
                alt="Conteúdo da Oficina"
                src={`https://ifa-app.s3.us-east-2.amazonaws.com/${
                  this.state.workshopId
                }/result_${
                  this.state.lang
                }.png`}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Oficina;