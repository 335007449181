import React, { Component } from 'react';

import Header from '../../../Header/Header';
import '../Profile.scss';
import './Avatar.scss';

import Image01 from '../../../../assets/images/avatar/01.png';
import Image02 from '../../../../assets/images/avatar/02.png';
import Image03 from '../../../../assets/images/avatar/03.png';
import Image04 from '../../../../assets/images/avatar/04.png';
import Image05 from '../../../../assets/images/avatar/05.png';
import Image06 from '../../../../assets/images/avatar/06.png';
import Image07 from '../../../../assets/images/avatar/07.png';
import Image08 from '../../../../assets/images/avatar/08.png';
import Image09 from '../../../../assets/images/avatar/09.png';

export default class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: 1,
      highlight: 1,
      avatares: [Image01, Image02, Image03, Image04, Image05, Image06, Image07, Image08, Image09 ]
    }
  }

  changeAvatar = async (clicked) => {
    await this.setState({
      avatar: clicked,
      highlight: parseInt(clicked)
    })
  }

  highlight(){
    let highlight = this.state.highlight

    switch (highlight) {
      case 1:
        return <img src={Image01} />

      case 2:
        return <img src={Image02} />

      case 3:
        return <img src={Image03} />

      case 4:
        return <img src={Image04} />

      case 5:
        return <img src={Image05} />

      case 6:
        return <img src={Image06} />

      case 7:
        return <img src={Image07} />

      case 8:
        return <img src={Image08} />

      case 9:
        return <img src={Image09} />

      default:
        return <img src={Image01} />
    }
  }

  render() {
    return (
      <div className="profile">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('p_avatar.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="profile-main">
          <div className="profile-highlight">
            { this.highlight() }
          </div>


          <div>
            {
              this.state.avatares.map((item, index) => {
                return (
                  <div className="avatares-item" key={index}>
                    <input
                      defaultChecked={index == 0 ? true : false}
                      id={index}
                      name="avatar"
                      onChange={() => this.changeAvatar(index+1)}
                      type="radio"
                    />
                    <label htmlFor={index}>
                      <img src={item} alt={'Avatar' + index} />
                    </label>
                  </div>
                )
              })
            }
          </div>

          <a
            className="button-full button-next"
            href={`/participant-hair?avatar=${this.state.avatar}`}
          >
            {this.props.t('p_avatar.btnContinue')}
          </a>
        </main>
      </div>
    );
  }
}