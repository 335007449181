import { useContext, useEffect, useState } from "react";

import "./Consult.scss";
import Header from "../../../Header/Header";
import Loading from "../../../Loading/Loading";

import { ReactComponent as StarIcon } from "../../../../assets/images/instrutor/icon-star.svg";
import EstrelasModal from "../Estrelas/Modal/EstrelasModal";
import DescriptionModal from "../Oficina/DescriptionModal/DescriptionModal";
import ModalMissions from "./ModalConsultMissions/ModalConsultMissions";

import LocalStorage from "../../../../services/localStorage.service";
import ClassService from "../../../../services/class.service";
import MissionService from "../../../../services/mission.service";
import PowerService from "../../../../services/power.service";
import WorkshopService from "../../../../services/workshop.service";
import ParticipantService from "../../../../services/participant.service";

import { useParams } from "react-router";
import { ContextAuth } from "../../../../context/authContext";

export default function ConsultTurma({ t }) {
  const authContext = useContext(ContextAuth)

  let lang = LocalStorage.retrieve("language");
  lang = lang ? lang : "en";

  const code = useParams().code;
  const [classCode, setClass] = useState();

  const [participants, setParticipants] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [powers, setPowers] = useState([]);
  const [missions, setMissions] = useState([]);
  const [modal, setModal] = useState(<></>);
  const [wkIndex, setWkIndex] = useState(0);
  const [dummy, setDummy] = useState(false);
  const [event, setEvent] = useState("");
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [selectPowersOptions, setSelectPowersOptions] = useState([]);
  const [error, setError] = useState({
    error: "",
    active: false,
  });

  useEffect(() => {
    ClassService.getByCode(code).then((res) => setClass(res));
    ClassService.participantsByClass(code).then((res) => setParticipants(res));
    WorkshopService.getAll().then((res) => setWorkshops(res));
    PowerService.getAll().then((res) => setPowers(res));
    MissionService.getAll(authContext).then((res) => setMissions(res));
  }, [dummy]);

  useEffect(() => {
    let modalElement = document.querySelector(".modal");

    if (modalElement) modalElement.style.display = "block";
  }, [modal]);

  useEffect(() => {
    const powersList = powers?.map((power) => power[lang].name);
    if (selectPowersOptions.length === 0) setSelectPowersOptions(powersList);
  }, [powers]);

  function loaded() {
    return (
      classCode &&
      participants.length > 0 &&
      workshops.length > 0 &&
      powers.length > 0 &&
      missions.length > 0
    );
  }

  function reload() {
    setDummy(!dummy);
  }

  const actualWorkshop = () => classCode.workshops[wkIndex];

  async function saveStar(participantId, star) {
    ParticipantService.assignStars([
      {
        _id: participantId,
        workshop: actualWorkshop().workshop,
        star,
      },
    ]).then(reload);
  }

  function getPopulatedPresenceList(index) {
    const workshop = classCode.workshops[index];
    return workshop?.presenceList.map((p) => {
      const participant = participants.find((part) => part._id == p._id);
      let power, mission, star;

      let powerAux = participant?.powers.find(
        (p) => p.workshop == workshop.workshop
      );
      if (powerAux) power = powers.find((p) => p._id == powerAux.powerId);

      let missionAux = participant?.missions.find(
        (m) => m.workshop == workshop.workshop
      );
      if (missionAux)
        mission = missions.find((m) => m._id == missionAux.missionId);

      let starAux = participant?.stars.find(
        (s) => s.workshop == workshop.workshop
      );
      if (starAux) star = starAux.type;

      return {
        presence: p.presence,
        participant: {
          _id: participant?._id,
          name: participant?.name,
          power,
          mission,
          star,
        },
      };
    });
  }

  function renderWorkshopsNavigation() {
    let items = [];
    for (let i = 1; i <= classCode.workshops.length; i++) {
      items.push(
        <li
          className={
            "oficina-carousel-menu-item" + (wkIndex === i - 1 ? " active" : "")
          }
          onClick={() => {
            setWkIndex(i - 1);
            if (filteredParticipants.length > 0) setFilteredParticipants([]);
          }}
          key={i}
        >
          {i}
        </li>
      );
    }
    return items;
  }

  function openStarModal(participant) {
    setModal(
      <EstrelasModal
        t={t}
        to={`${participant.name} / ${participant.power}`}
        star={participant.star}
        onChooseStar={(star) => saveStar(participant._id, star)}
      />
    );
  }

  function openPowerModal(power) {
    const content = {
      oficinaName: power[lang].name,
      description: power[lang].description,
    };

    setModal(<DescriptionModal conteudo={content} t={t} />);
  }

  function openMissionModal(mission) {
    setModal(
      <ModalMissions
        name={mission[lang].name}
        context={mission[lang].context}
        todo={mission[lang].toDo}
        result={mission[lang].result}
        tip={mission[lang].tip}
        t={t}
      />
    );
  }

  function renderParticipants(e) {
    if (e) setEvent(e);

    if (e === "all") setFilteredParticipants([]);

    const presentParticipants = getPopulatedPresenceList(wkIndex)
      .map((participant) => participant)
      .filter((participant) => participant?.presence)
      .filter(
        (participant) => participant?.participant?.power?.[lang].name === e
      );

    if (e && presentParticipants?.length > 0) {
      setFilteredParticipants(presentParticipants);
    }

    if (filteredParticipants?.length > 0) {
      if (error.active) {
        setFilteredParticipants([]);
      }
    }

    if (e && presentParticipants?.length < 1 && e !== "all") {
      if (lang === "pt") {
        setError({
          error: "Não há participantes com este poder.",
          active: true,
        });
        if (error);
        setTimeout(() => {
          setError({
            error: "",
            active: false,
          });
        }, 3000);
      } else if (lang === "en") {
        setError({
          error: "There are no participants with this power.",
          active: true,
        });
        if (error);
        setTimeout(() => {
          setError({
            error: "",
            active: false,
          });
        }, 3000);
      } else {
        setError({
          error: "No hay participantes con este poder.",
          active: true,
        });
        if (error);
        setTimeout(() => {
          setError({
            error: "",
            active: false,
          });
        }, 3000);
      }
    }

    if (filteredParticipants?.length > 0) {
      return filteredParticipants.map((item, index) => {
        return (
          <li key={index} className="turma-oficina-list-item">
            <div className="participant-info">
              <span>{item.participant.name}</span>
              {!item.participant.power ? null : (
                <span
                  onClick={() => openPowerModal(item.participant.power)}
                  className="consult-power-info"
                >
                  {item.participant.power[lang].name}
                </span>
              )}
              {!item.participant.mission ? null : (
                <span
                  onClick={() => openMissionModal(item.participant.mission)}
                  className="consult-power-info"
                >
                  {item.participant.mission[lang].name}
                </span>
              )}
            </div>
            <div className="attendance">
              {item.presence ? (
                <span className="attend">{t("i_ConsultClass.attend")}</span>
              ) : (
                <span className="absence">{t("i_ConsultClass.absence")}</span>
              )}
            </div>
            {classCode.type === "workshop" ||
            (classCode.type === "campaignLevel1" && wkIndex !== 4) ? (
              ""
            ) : (
              <button
                disabled={
                  classCode.type === "workshop" ||
                  !item.presence ||
                  item.participant.star ||
                  !item.participant.mission ||
                  !actualWorkshop().missionsCompleted
                }
                onClick={() => openStarModal(item.participant)}
              >
                <div className="star">
                  {item.participant.star == "1" ? (
                    <StarIcon className="icon completed" />
                  ) : item.participant.star == "2" ? (
                    <StarIcon className="icon later" />
                  ) : (
                    <StarIcon className="icon incomplete" />
                  )}
                </div>
              </button>
            )}
          </li>
        );
      });
    }

    return getPopulatedPresenceList(wkIndex)
      .filter((participant) => participant.participant._id != undefined)
      .map((item, index) => {
        return (
          <li key={index} className="turma-oficina-list-item">
            <div className="participant-info">
              <span>{item.participant.name}</span>
              {!item.participant.power ? null : (
                <span
                  onClick={() => openPowerModal(item.participant.power)}
                  className="consult-power-info"
                >
                  {item.participant.power[lang].name}
                </span>
              )}
              {!item.participant.mission ? null : (
                <span
                  onClick={() => openMissionModal(item.participant.mission)}
                  className="consult-power-info"
                >
                  {item.participant.mission[lang].name}
                </span>
              )}
            </div>
            <div className="attendance">
              {item.presence ? (
                <span className="attend">{t("i_ConsultClass.attend")}</span>
              ) : (
                <span className="absence">{t("i_ConsultClass.absence")}</span>
              )}
            </div>
            {classCode.type === "workshop" ||
            (classCode.type === "campaignLevel1" && wkIndex !== 4) ? (
              ""
            ) : (
              <button
                disabled={
                  classCode.type === "workshop" ||
                  !item.presence ||
                  item.participant.star ||
                  !item.participant.mission ||
                  !actualWorkshop().missionsCompleted
                }
                onClick={() => openStarModal(item.participant)}
              >
                <div className="star">
                  {item.participant.star == "1" ? (
                    <StarIcon className="icon completed" />
                  ) : item.participant.star == "2" ? (
                    <StarIcon className="icon later" />
                  ) : (
                    <StarIcon className="icon incomplete" />
                  )}
                </div>
              </button>
            )}
          </li>
        );
      });
  }

  function renderSelect() {
    return (
      <select
        name="select"
        className="filter-turma-select"
        onChange={(e) => renderParticipants(e.target.value)}
        id="selectOptions"
      >
        <option selected disabled>
          {t("i_Class.selectDefaultOption")}
        </option>
        <option value="all">{t("i_Class.selectShowAllOption")}</option>
        {powers &&
          selectPowersOptions?.map((power, index) => {
            return (
              <option key={index} value={power}>
                {power}
              </option>
            );
          })}
      </select>
    );
  }

  return (
    <Loading fade={!loaded()}>
      <Header />

      <div className="page-title-header">
        <h1 className="page-title">{t("i_ConsultClass.title")}</h1>
        <span className="page-title-border" />
      </div>

      {loaded() ? (
        <>
          <main className="turma-main">
            <h3 className="turma-class-name">
              {classCode.name}
              <span>
                {t("i_Class.code")}: {code}
              </span>
            </h3>

            <ul className="card oficina-carousel-menu">
              {renderWorkshopsNavigation()}
            </ul>

            <div className="filter-turma">
              <h4>{t("i_Class.selectTitle")}</h4>
              <div className="filter-turma-select-arrow">
                {/* <Select
                  powers={powers}
                  selectPowersOptions={selectPowersOptions}
                  renderParticipants={(e) => renderParticipants(e)}
                /> */}
                {renderSelect()}
              </div>
              <small>{error ? error.error : null}</small>
            </div>

            <div className="item card turma-oficina-content">
              <span className="oficina-title">
                {
                  workshops.find((w) => w._id === actualWorkshop().workshop)
                    .name
                }
              </span>
              <ul className="turma-oficina-list">{renderParticipants()}</ul>
            </div>

            {modal}
          </main>
        </>
      ) : null}
    </Loading>
  );
}
