import React, { Component } from 'react';

import Header from '../../../Header/Header';
import './Hair.scss';

import { ReactComponent as Avatar01 } from '../../../../assets/images/avatar/01.svg';
import { ReactComponent as Avatar02 } from '../../../../assets/images/avatar/02.svg';
import { ReactComponent as Avatar03 } from '../../../../assets/images/avatar/03.svg';
import { ReactComponent as Avatar04 } from '../../../../assets/images/avatar/04.svg';
import { ReactComponent as Avatar05 } from '../../../../assets/images/avatar/05.svg';
import { ReactComponent as Avatar06 } from '../../../../assets/images/avatar/06.svg';
import { ReactComponent as Avatar07 } from '../../../../assets/images/avatar/07.svg';
import { ReactComponent as Avatar08 } from '../../../../assets/images/avatar/08.svg';
import { ReactComponent as Avatar09 } from '../../../../assets/images/avatar/09.svg';

import { ReactComponent as Bald01 } from '../../../../assets/images/avatar/1-bald.svg';
import { ReactComponent as Bald02 } from '../../../../assets/images/avatar/2-bald.svg';
import { ReactComponent as Bald03 } from '../../../../assets/images/avatar/3-bald.svg';
import { ReactComponent as Bald04 } from '../../../../assets/images/avatar/4-bald.svg';
import { ReactComponent as Bald05 } from '../../../../assets/images/avatar/5-bald.svg';
import { ReactComponent as Bald06 } from '../../../../assets/images/avatar/6-bald.svg';
import { ReactComponent as Bald07 } from '../../../../assets/images/avatar/7-bald.svg';
import { ReactComponent as Bald08 } from '../../../../assets/images/avatar/8-bald.svg';
import { ReactComponent as Bald09 } from '../../../../assets/images/avatar/9-bald.svg';

export default class Hair extends Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: [
        {
          name: 'brown',
          hexa: '#D07B5F'
        },
        {
          name: 'black',
          hexa: '#28221F'
        },
        {
          name: 'blond',
          hexa: '#E0AE44'
        },
        {
          name: 'darkBrown',
          hexa: '#6E4024'
        },
        {
          name: 'redhead',
          hexa: '#F9693A'
        },
        {
          name: 'blue',
          hexa: '#3E68B2'
        }
      ],
      avatar: '',
      avatars: [
        <Avatar01 className="profile-highlight avatar" />,
        <Avatar02 className="profile-highlight avatar" />,
        <Avatar03 className="profile-highlight avatar" />,
        <Avatar04 className="profile-highlight avatar" />,
        <Avatar05 className="profile-highlight avatar" />,
        <Avatar06 className="profile-highlight avatar" />,
        <Avatar07 className="profile-highlight avatar" />,
        <Avatar08 className="profile-highlight avatar" />,
        <Avatar09 className="profile-highlight avatar" />
      ],
      balds: [
        <Bald01 className="profile-highlight baldAvatar" />,
        <Bald02 className="profile-highlight baldAvatar" />,
        <Bald03 className="profile-highlight baldAvatar" />,
        <Bald04 className="profile-highlight baldAvatar" />,
        <Bald05 className="profile-highlight baldAvatar" />,
        <Bald06 className="profile-highlight baldAvatar" />,
        <Bald07 className="profile-highlight baldAvatar" />,
        <Bald08 className="profile-highlight baldAvatar" />,
        <Bald09 className="profile-highlight baldAvatar" />
      ],
      bald: false,
      color: ''
    }
  }

  componentWillMount = () => {
    const avatar = this.props.location.search.split('=')[1]

    this.setState({
      avatar: avatar
    })

  }

  // Color options
  renderColors() {
    return this.state.colors.map((item, index) => {
      return (
        <div className="hair-item" key={index}>
          <input
            id={item.name}
            className="input-color"
            name="hairColor"
            onChange={() => this.changeColor(item.name)}
            type="radio"
          />
          <label
            className="label-color"
            htmlFor={item.name}
            style={{ background: item.hexa }}
          >
          </label>
        </div>
      )
    })
  }

  changeColor = (name) => {
    let hair = document.querySelectorAll('.cls-2')

    hair.forEach(item => {
      item.classList = `cls-2 ${name}`
    })

    this.setState({
      color: name
    })
  }

  highlight = (selectedAvatar) => {
    let avatars = this.state.avatars

    return avatars[selectedAvatar - 1]
  }

  // No hair - option
  noHair = (selectedAvatar) => {
    let balds = this.state.balds

    return balds[selectedAvatar - 1]
  }

  // Show No hair avatar
  baldAvatar = () => {
      let highlight = document.querySelector('.avatar')
      let bald = document.querySelector('.baldAvatar')

      highlight.style.display = 'none'
      bald.style.display = 'block'
  }

  // Desabilitar seleção de cores depois de selecionar sem cabelo
  disableColors = () => {
    let noHair = document.querySelector('.input-noHair')
    let colors = document.querySelectorAll('.input-color')
    let labels = document.querySelectorAll('.label-color')

    if(noHair.checked){
      colors.forEach(color => {
        labels.forEach(label => {
          label.style.opacity = '.5'
        })
        color.disabled = true
      })
      this.setState({
        bald: true
      })
    }
  }

  // Retorna a opção com cabelo
  changeHairAvatar(){
    let bald = document.querySelector('.baldAvatar')
    let highlight = document.querySelector('.avatar')

    highlight.style.display = 'block'
    bald.style.display = 'none'
  }

  // habilita novamente a cor do cabelo
  enableColors(){
    let withHair = document.querySelector('.input-withHair')
    let colors = document.querySelectorAll('.input-color')
    let labels = document.querySelectorAll('.label-color')

    if(withHair.checked){
      colors.forEach(color => {
        labels.forEach(label => {
          label.style.opacity = '1'
        })
        color.disabled = false
      })
      this.setState({
        bald: false
      })
    }

  }


  render() {
    return (
      <div className="profile">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('p_hair.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="profile-main">
          {this.noHair(this.state.avatar)}
          {this.highlight(this.state.avatar)}

          <div>
            {this.renderColors()}
          </div>


          <div className="hair-item item-noHair">
            <input
              className="hair-bald input-noHair"
              id="no-hair"
              name="hairColor"
              onChange={() => this.disableColors()}
              onClick={() => this.baldAvatar()}
              type="radio"
            />
            <label
              className="label-noHair"
              htmlFor="no-hair"
            >
              {this.props.t('p_hair.noHair')}
            </label>
          </div>
          <div className="hair-item item-withHair">
            <input
              className="hair-bald input-withHair"
              id="with-hair"
              name="hairColor"
              onClick={() => this.changeHairAvatar()}
              onChange={() => this.enableColors() }
              type="radio"
            />
            <label
              className="label-withHair"
              htmlFor="with-hair"
            >
              {this.props.t('p_hair.withHair')}
            </label>
          </div>

          <a
            className="button-full button-next"
            href={`/participant-skin?avatar=${this.state.avatar}&bald=${this.state.bald}&color=${this.state.color}`}
          >
            {this.props.t('p_hair.btnContinue')}
          </a>
        </main>
      </div>
    );
  }
}