import React, { Component } from 'react';
import './List/List.scss';
import Header from '../../Header/Header';

import Oficina from './List/Oficina';
import Campanha from './List/Campanha';

class ListOficina extends Component {
  constructor(props){
    super(props);
    this.state = {
      oficinaType: 'campanha'
    }
  }

  oficinaType(value){
    let type = this.state.oficinaType

    switch(type){
      case 'unica':
        return <Oficina />
      case 'campanha':
        return <Campanha />
    }
  }

  render() {
    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">OFICINAS E CAMPANHAS</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="list-oficinas-campanha-main">
          <p className="list-oficinas-campanha-text">Aqui você vai dolor sit amet, consectetur adipiscing elit. Abacte.</p>

          { this.oficinaType() }
        </main>

      </div>
    );
  }
}

export default ListOficina;