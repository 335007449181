import React from 'react';
import ReactDOM from 'react-dom';
import { setTranslations, setDefaultLanguage } from 'react-multi-lang';
import LocalStorage from './services/localStorage.service';
import pt from './translations/pt.json';
import en from './translations/en.json';
import es from "./translations/es.json"
import { Provider } from 'react-redux';
import { store } from './store';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Route } from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

import App from './components/App';
import { AuthProvider } from './context/authContext';
import RootProvider from './components/Providers/RootProvider';

setTranslations({ pt, en, es })
if(!LocalStorage.retrieve('language')){
    LocalStorage.persist('language', 'pt')
    setDefaultLanguage('pt')
} else {
    setDefaultLanguage(LocalStorage.retrieve('language'))
}

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <RootProvider>
        <Route path="/" component={App} />
      </RootProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();