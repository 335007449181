import React from "react";
import "./CadastroInstrutor.scss";
import Header from "../../Header/Header";

import { ReactComponent as IconPerson } from "../../../assets/images/instrutor/person.svg";
import { ReactComponent as CheckMark } from "../../../assets/images/instrutor/check-mark.svg";

import InformacoesAcesso from "./InformacoesAcesso/InformacoesAcesso";
import DadosCadastrais from "./DadosCadastrais/DadosCadastrais";
import EnderecoContato from "./EnderecoContato/EnderecoContato";
import DadosInstituicao from "./DadosInstituicao/DadosInstituicao";
import InformacoesAdicionais from "./InformacoesAdicionais/InformacoesAdicionais";
import ConfirmacaoDados from "./ConfirmacaoDados/ConfirmacaoDados";

import LocalStorage from "../../../services/localStorage.service";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class CadastroInstrutor extends React.Component {
  constructor() {
    super();
    this.state = {
      currentStep: "",
      informacoesAcesso: {},
      dadosCadastrais: {},
      endereco: {},
      instituicao: {},
      informacaoAdicional: {}
    };
  }

  componentWillMount = () => {
    const cacheInstructor = LocalStorage.retrieve("cacheInstructor");
    const backOperation = LocalStorage.retrieve("backOperation");

    if(backOperation && backOperation === 'true'){
      this.restoreCacheInstructor();

    } else if(cacheInstructor && cacheInstructor === 'true'){
      MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        cancelButtonText: `${this.props.t("i_registration.no")}`,
        cancelButtonColor: "#87BF40",
        confirmButtonColor: "#87BF40",
        confirmButtonText: (<a href="javascript:void(0)">{this.props.t("i_registration.yes")}</a>),
        customClass: {
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class"
        },
        icon: "warning",
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        title: `${this.props.t("i_registration.cacheInstructor")}`
      }).then(result => {
        if (result.value){
          this.restoreCacheInstructor();
        } else if(result.dismiss == 'cancel') {
          this.clearCacheInstructor();
        }
      });
    }
  }

  restoreCacheInstructor = async () => {
    
    let backOperation = LocalStorage.retrieve("backOperation");
    let backNav = LocalStorage.retrieve("backNav");

    let informacoesAcesso = LocalStorage.retrieve('informacoesAcesso');
    let dadosCadastrais = LocalStorage.retrieve('dadosCadastrais');
    let endereco = LocalStorage.retrieve('endereco');
    let instituicao = LocalStorage.retrieve('instituicao');
    let informacaoAdicional = LocalStorage.retrieve('informacaoAdicional');

    if(informacaoAdicional){
      await this.setState({
        currentStep: "confirmacaoDados",
        informacaoAdicional: informacaoAdicional,
        instituicao: instituicao,
        endereco: endereco,
        dadosCadastrais: dadosCadastrais,
        informacoesAcesso: informacoesAcesso
      })
    } else if(instituicao){
      await this.setState({
        currentStep: "informacoesAdicionais",
        instituicao: instituicao,
        endereco: endereco,
        dadosCadastrais: dadosCadastrais,
        informacoesAcesso: informacoesAcesso
      })
    } else if(endereco){
      await this.setState({
        currentStep: "dadosInstituicao",
        endereco: endereco,
        dadosCadastrais: dadosCadastrais,
        informacoesAcesso: informacoesAcesso
      })
    } else if(dadosCadastrais){
      await this.setState({
        currentStep: "enderecoContato",
        dadosCadastrais: dadosCadastrais,
        informacoesAcesso: informacoesAcesso
      })
    } else if(informacoesAcesso){
      await this.setState({
        currentStep: "dadosCadastrais",
        informacoesAcesso: informacoesAcesso
      })
    } else {
      console.log('LocalStorage vazio');
    }

    if(backOperation && backOperation === 'true'){
      if(backNav && backNav === 'dadosCadastrais'){
        await this.setState({ currentStep: 'dadosCadastrais' })
      } else if(backNav && backNav === 'informacoesAcesso'){
        await this.setState({ currentStep: 'informacoesAcesso' })
      } else if(backNav && backNav === 'enderecoContato'){
        await this.setState({ currentStep: 'enderecoContato' })
      } else if(backNav && backNav === 'dadosInstituicao'){
        await this.setState({ currentStep: 'dadosInstituicao' })
      } else if(backNav && backNav === 'informacoesAdicionais'){
        await this.setState({ currentStep: 'informacoesAdicionais' })
      } else {

      }
    }

    LocalStorage.remove('backOperation')
    LocalStorage.remove('backNav')
  }

  clearCacheInstructor = () => {
    LocalStorage.remove('informacoesAcesso');
    LocalStorage.remove('dadosCadastrais');
    LocalStorage.remove('endereco');
    LocalStorage.remove('instituicao');
    LocalStorage.remove('informacaoAdicional');
    LocalStorage.remove('cacheInstructor');
    LocalStorage.remove('backOperation');
    LocalStorage.remove('backNav');
  }

  getInfo(page, value) {

    if (value) {
      this.setState({ currentStep: page });
      switch (page) {
        case "dadosCadastrais":
          LocalStorage.persist('cacheInstructor', 'true')
          LocalStorage.persist('informacoesAcesso', value)
          this.setState({ informacoesAcesso: value });
          break;
        case "enderecoContato":
          LocalStorage.persist('cacheInstructor', 'true')
          LocalStorage.persist('dadosCadastrais', value)
          this.setState({ dadosCadastrais: value });
          break;
        case "dadosInstituicao":
          LocalStorage.persist('cacheInstructor', 'true')
          LocalStorage.persist('endereco', value)
          this.setState({ endereco: value });
          break;
        case "informacoesAdicionais":
          LocalStorage.persist('cacheInstructor', 'true')
          LocalStorage.persist('instituicao', value)
          this.setState({ instituicao: value });
          break;
        case "confirmacaoDados":
          LocalStorage.persist('cacheInstructor', 'true')
          LocalStorage.persist('informacaoAdicional', value)
          this.setState({ informacaoAdicional: value });
          break;
        case "conslusion":
          this.setState({ informacaoAdicional: value });
          break;
        default:
          LocalStorage.persist('cacheInstructor', 'true')
          LocalStorage.persist('informacoesAcesso', value)
          this.setState({ informacoesAcesso: value });
      }
    }
  }

  render() {
    let renderCadastro = () => {
      var step = this.state.currentStep;
      switch (step) {
        case "dadosCadastrais":
          return (
            <DadosCadastrais
              iconPerson={<IconPerson />}
              iconCheck={<CheckMark />}
              getInfo={this.getInfo.bind(this)}
              t={this.props.t}
              dataExist={true}
              dataAux={this.state.dadosCadastrais}
            />
          );

        case "enderecoContato":
          return (
            <EnderecoContato
              iconPerson={<IconPerson />}
              iconCheck={<CheckMark />}
              getInfo={this.getInfo.bind(this)}
              t={this.props.t}
              dataExist={true}
              dataAux={this.state.endereco}
            />
          );

        case "dadosInstituicao":
          return (
            <DadosInstituicao
              iconPerson={<IconPerson />}
              iconCheck={<CheckMark />}
              getInfo={this.getInfo.bind(this)}
              t={this.props.t}
              dataExist={true}
              dataAux={this.state.instituicao}
            />
          );

        case "informacoesAdicionais":
          return (
            <InformacoesAdicionais
              iconPerson={<IconPerson />}
              iconCheck={<CheckMark />}
              getInfo={this.getInfo.bind(this)}
              t={this.props.t}
              dataExist={true}
              dataAux={this.state.informacaoAdicional}
            />
          );

        case "confirmacaoDados":
          return (
            <ConfirmacaoDados
              getInfo={this.getInfo.bind(this)}
              dados={this.state}
              t={this.props.t}
              history={this.props.history}
            />
          );

        default:
          return (
            <InformacoesAcesso
              iconPerson={<IconPerson />}
              iconCheck={<CheckMark />}
              getInfo={this.getInfo.bind(this)}
              t={this.props.t}
              dataExist={true}
              dataAux={this.state.informacoesAcesso}
            />
          );
      }
    };

    return (
      <div className="cadastro-instrutor">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t("i_registration.title")}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="cadastro-instrutor-main">{renderCadastro()}</main>
      </div>
    );
  }
}

export default CadastroInstrutor;