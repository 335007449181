import React, { Component } from "react";

import "./Confirm.scss";
import Header from "../../../Header/Header";
import LocalStorage from "../../../../services/localStorage.service";
import ParticipantService from "../../../../services/participant.service";

import { ReactComponent as Avatar01 } from "../../../../assets/images/avatar/01.svg";
import { ReactComponent as Avatar02 } from "../../../../assets/images/avatar/02.svg";
import { ReactComponent as Avatar03 } from "../../../../assets/images/avatar/03.svg";
import { ReactComponent as Avatar04 } from "../../../../assets/images/avatar/04.svg";
import { ReactComponent as Avatar05 } from "../../../../assets/images/avatar/05.svg";
import { ReactComponent as Avatar06 } from "../../../../assets/images/avatar/06.svg";
import { ReactComponent as Avatar07 } from "../../../../assets/images/avatar/07.svg";
import { ReactComponent as Avatar08 } from "../../../../assets/images/avatar/08.svg";
import { ReactComponent as Avatar09 } from "../../../../assets/images/avatar/09.svg";

import { ReactComponent as Bald01 } from "../../../../assets/images/avatar/1-bald.svg";
import { ReactComponent as Bald02 } from "../../../../assets/images/avatar/2-bald.svg";
import { ReactComponent as Bald03 } from "../../../../assets/images/avatar/3-bald.svg";
import { ReactComponent as Bald04 } from "../../../../assets/images/avatar/4-bald.svg";
import { ReactComponent as Bald05 } from "../../../../assets/images/avatar/5-bald.svg";
import { ReactComponent as Bald06 } from "../../../../assets/images/avatar/6-bald.svg";
import { ReactComponent as Bald07 } from "../../../../assets/images/avatar/7-bald.svg";
import { ReactComponent as Bald08 } from "../../../../assets/images/avatar/8-bald.svg";
import { ReactComponent as Bald09 } from "../../../../assets/images/avatar/9-bald.svg";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { navigate } from "../../../../services/navigation.service";
import { ContextAuth } from "../../../../context/authContext";
import sanitizeString from "../About/utils/sanitizeString";
const MySwal = withReactContent(Swal);

export default class Confirm extends Component {
  static contextType = ContextAuth;
  component = this;

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      lang: "",
      avatar: "",
      bald: false,
      avatars: [
        <Avatar01 className="profile-highlight" />,
        <Avatar02 className="profile-highlight" />,
        <Avatar03 className="profile-highlight" />,
        <Avatar04 className="profile-highlight" />,
        <Avatar05 className="profile-highlight" />,
        <Avatar06 className="profile-highlight" />,
        <Avatar07 className="profile-highlight" />,
        <Avatar08 className="profile-highlight" />,
        <Avatar09 className="profile-highlight" />,
      ],
      balds: [
        <Bald01 className="profile-highlight" />,
        <Bald02 className="profile-highlight" />,
        <Bald03 className="profile-highlight" />,
        <Bald04 className="profile-highlight" />,
        <Bald05 className="profile-highlight" />,
        <Bald06 className="profile-highlight" />,
        <Bald07 className="profile-highlight" />,
        <Bald08 className="profile-highlight" />,
        <Bald09 className="profile-highlight" />,
      ],
      avatarImage: "",
      hairColor: "",
      skinColor: "",
      nickname: "",
      favoriteColor: "",
      favoriteAnimal: "",
      name: "",
    };
  }

  componentWillMount = () => {
    const lang = LocalStorage.retrieve("language");
    const participantLocal = this.context.state.userData;

    let query = this.props.location.search;
    query = query.replace(/\?/g, "");
    let variables = query.split("&");

    const avatar = variables[0].split("=")[1];
    const bald = variables[1].split("=")[1];
    const color = variables[2].split("=")[1];
    const skinColor = variables[3].split("=")[1];
    const nickname = sanitizeString(variables[4].split("=")[1]);
    const favoriteColor = sanitizeString(variables[5].split("=")[1]);
    const favoriteAnimal = sanitizeString(variables[6].split("=")[1]);

    let avatarImage;

    if (bald == "true") {
      avatarImage = this.state.balds[avatar - 1];
    } else {
      avatarImage = this.state.avatars[avatar - 1];
    }

    this.setState({
      id: participantLocal._id,
      avatar: avatar,
      bald: bald,
      avatarImage: avatarImage,
      hairColor: color,
      skinColor: skinColor,
      nickname: nickname,
      favoriteColor: favoriteColor,
      favoriteAnimal: favoriteAnimal,
      name: participantLocal.name,
      lang: lang,
    });
  };

  componentDidMount() {
    // Change Hair Color
    let hair = document.querySelectorAll(".cls-2");
    hair.forEach((item) => {
      item.classList = `cls-2 ${this.state.hairColor}`;
    });

    // Change Skin Color
    if (this.state.bald !== "true") {
      let skin = document.querySelectorAll(".cls-1");

      skin.forEach((item) => {
        item.classList = `cls-1 ${this.state.skinColor}`;
      });
    } else {
      let skin = document.querySelectorAll(".noHair-cls-1");

      skin.forEach((item) => {
        item.classList = `noHair-cls-1 ${this.state.skinColor}`;
      });
    }
  }

  confirmAvatar = async () => {
    const component = this;
    const updateParticipant = {
      avatar: {
        type: this.state.avatar,
        bald: this.state.bald === "true" ? true : false,
        hairColor: this.state.hairColor,
        skinColor: this.state.skinColor,
      },
      favoritePet: this.state.favoriteAnimal,
      favoriteColor: this.state.favoriteColor,
      nickname: this.state.nickname,
    };

    let res = await ParticipantService.update(this.state.id, updateParticipant);
    let getParticipant = await ParticipantService.getById(this.state.id);
    this.context.setContext({ userData: getParticipant });

    if (res._id) {
      MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: `${this.props.t("p_confirm.btnAlert")}`,
        icon: "success",
        title: `${this.props.t("p_confirm.alertTitle")}`,
        text: `${this.props.t("p_confirm.alertText")}`,
      }).then(function () {
        const navigator = navigate.bind(component);

        navigator({ link: "/home-participante" });
      });
    }
  };

  render() {
    return (
      <div className="profile">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t("p_confirm.title")}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="profile-main">
          {this.state.avatarImage}

          <h3 className="confirm-name">{this.state.name}</h3>

          <div className="card">
            <div className="confirm-info">
              <span>{this.props.t("p_confirm.nickname")}</span>
              <span className="confirm-nickname">{this.state.nickname}</span>
            </div>

            <div className="confirm-info">
              <span>{this.props.t("p_confirm.color")}</span>
              <span>
                {this.state.lang == "pt"
                  ? this.state.favoriteColor == "red"
                    ? "Vermelho"
                    : this.state.favoriteColor == "yellow"
                    ? "Amarelo"
                    : this.state.favoriteColor == "blue"
                    ? "Azul"
                    : ""
                  : this.state.favoriteColor}
              </span>
            </div>

            <div className="confirm-info">
              <span>{this.props.t("p_confirm.animal")}</span>
              <span>
                {this.state.lang == "pt"
                  ? this.state.favoriteAnimal == "dog"
                    ? "Cachorro"
                    : this.state.favoriteAnimal == "cat"
                    ? "Gato"
                    : this.state.favoriteAnimal == "bird"
                    ? "Pássaro"
                    : ""
                  : this.state.favoriteAnimal}
              </span>
            </div>
          </div>

          <div className="confirm-buttons">
            <button
              className="button-full confirm"
              onClick={() => {
                this.confirmAvatar();
              }}
            >
              {this.props.t("p_confirm.btnConfirm")}
            </button>

            <a className="repeat" href="/participant-avatar">
              {this.props.t("p_confirm.startAgain")}
            </a>
          </div>
        </main>
      </div>
    );
  }
}
