import Swal from "sweetalert2";
import getTranslation from "../../../../../utils/getTranslation";

const ImageList = ({ images, imagesAlreadySent, setImages }) => {
    
  const deleteImage = (index) => {
    Swal.fire({
      text: getTranslation("i_CardImage.deleteModalTitle"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#d33",
      confirmButtonText: getTranslation("i_CardImage.deleteModalConfirm"),
      cancelButtonText: getTranslation("i_CardImage.deleteModalCancel"),
      reverseButtons: true,
      width: "400px",
      customClass: {
        content: "cardImagem-modalDeleteText",
        confirmButton: "cardimagem-modalDeleteConfirmButton",
        cancelButton: "cardimagem-modalDeleteCancelButton",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
      }
    });
  };

  return images.map((image, index) => {
    return (
      <li className="listaDeImagens-item" key={index}>
        <section id={"cardImagem-section"}>
          <div
            id={"-item"}
            className="cardImagem-item"
            style={{
              background: imagesAlreadySent
                ? `url('${image}') center center / cover`
                : `url('${image.previewImg}') center center / cover`,
            }}
          >
            <input
              id={"-cardImagem-enviarImagemInput"}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              hidden
            />
          </div>
          <div className="cardImagem-dadosDoParticipante">
            <p className="cardImagem-nomeDoParticipante">
              {getTranslation("participantMissionUpload.cardImage.imageDescription")}
              {!imagesAlreadySent && (
                <span
                  className="cardImagem-excluirImagemButton"
                  onClick={() => deleteImage(index)}
                >
                  X
                </span>
              )}
            </p>
          </div>
        </section>
      </li>
    );
  });
};

export default ImageList;
