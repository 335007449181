export default class ArrayDeImagens {
  constructor() {
    this.imagens = [];
    this._inscritos = [];
  }

  adicionarImagemDoAluno(participante, file, imagem, instructor, classCode, workshop, dateTime) {
    if (this.imagens.length < 10) {
      this.imagens.push(new Imagem(participante, file, imagem, instructor, classCode, workshop, dateTime));
      this.notificar();
    }
  }

  adicionarImagemDoAlunoQueJaFoiEnviada(missionName, missionId, url, participantName, participantId, imageDriveId) {
    if (this.imagens.length < 10) {
      this.imagens.push(new ImagemEnviada(missionName, missionId, url, participantName, participantId, imageDriveId));
      this.notificar();
    }
  }

  apagarImagemDoAluno(indice) {
    this.imagens.splice(indice, 1);
    this.notificar();
  }

  limparImagens() {
    this.imagens = [];
    this.notificar();
  }

  inscrever(func) {
    this._inscritos.push(func);
  }

  desinscrever(func) {
    this._inscritos = this._inscritos.filter((f) => f !== func);
  }

  notificar() {
    this._inscritos.forEach((func) => {
      func(this.imagens);
    });
  }
}

class Imagem {
  constructor(participante, file, imagem, instructor, classCode, workshop, dateTime) {
    this.participante = participante;
    this.file = file;
    this.imagem = imagem;
    this.instructor = instructor;
    this.classCode = classCode;
    this.workshop = workshop;
    this.dateTime = dateTime;
  }
}


class ImagemEnviada {
  constructor(missionName, missionId, url, participantName, participantId, imageDriveId) {
    this.missionName = missionName;
    this.missionId = missionId;
    this.url = url;
    this.participantName = participantName;
    this.participantId = participantId;
    this.imageDriveId = imageDriveId;
  }
}

