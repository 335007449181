import ApproveMissionBox from "../ApproveMissionBox/ApproveMissionBox"
import { ReactComponent as StarIcon } from '../../../../../../assets/images/instrutor/icon-star.svg';
import getTranslation from "../../../../../../utils/getTranslation";

const ParticipantElement = ({ participant, star, openModal }) => {
    const { name, poder } = participant.data

    return (
        <li className="estrelas-instrutor-list-item">
          <div className="participant-info item-info">
            <span>
              {name.includes(' ') ?
                `${name.split(' ')[0]} ${name.split(' ')[name.split(' ').length - 1]}` :
                `${name}`}
            </span>
            <span>{ poder}</span>
            <span>{
               star === '1' ? `${getTranslation('i_stars.fullStar')}` :
               star === '2' ? `${getTranslation('i_stars.halfStar')}` :
              `${getTranslation('i_stars.noStar')}`
            }</span>

          </div>

          <div className="star-info item-info">
            {
               star == '1' ? <StarIcon className="icon completed" /> :
               star == '2' ? <StarIcon className="icon later" /> :
              <StarIcon className="icon incomplete" />
            }
            <span
              onClick={() => openModal(participant._id)}
            >
              {getTranslation('i_stars.chooseBtn')}
            </span>
          </div>
          <ApproveMissionBox participant={participant} />
        </li>
    )
}

export default ParticipantElement