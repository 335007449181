let localStorage = window.localStorage;

const LocalStorage = {
    clear(){
        const languageValue = localStorage.getItem("language")
        localStorage.clear();
        localStorage.setItem("language", languageValue)
    },
    persist(key, item){
        if(key)
            return localStorage.setItem(key, JSON.stringify(item))
            return localStorage.setItem(key, item);
    },
    retrieve(key){
        const value = localStorage.getItem(key)

        try {
            const parsedValue = JSON.parse(value)

            if(parsedValue == 'null' || parsedValue == 'undefined') {
                throw new Error(`${value} is null or undefined in localstorage`)
            }

            return parsedValue

        } catch(err) {
            return null
        }
    },
    remove(key){
        localStorage.removeItem(key);
    }
}

export default LocalStorage;

