import React from "react";
import "moment/locale/pt-br";
import "../CertificadoParticipant.scss";
import logoIfa from "../../../../../assets/images/instrutor/logo-ifa.png";
import assinatura from "../../../../../assets/images/instrutor/assinatura.png";
import {
  Document,
  Page,
  Text,
  Image,
  View,
} from "@react-pdf/renderer";
import { styles } from "../utils/styles";
import { languageMomentPreposition } from "../utils/languageToMomentLocale";

const PdfDocument = (props) => {
  const certificationTextByWorkshopType = {
    "workshop": [
      props.t("i_certifiedParticipant.cTextWorkshop01"), 
      props.t("i_certifiedParticipant.cTextWorkshop02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextWorkshop03")
    ],
    "campaign": [
      props.t("i_certifiedParticipant.cTextCampaign"),
      props.t("i_certifiedParticipant.cTextCampaign02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextCampaign03")
    ],
    "campaignLevel1": [
      props.t("i_certifiedParticipant.cTextCampaignLevel1"),
      props.t("i_certifiedParticipant.cTextCampaign02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextCampaign03")
    ],
    "default": [
      props.t("i_certifiedParticipant.cTextCampaignLevel2"),
      props.t("i_certifiedParticipant.cTextCampaign02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextCampaign03")
    ]
  }
  let { type } = props.currentClass;
  type = certificationTextByWorkshopType.hasOwnProperty(type) ? type : "default"

    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          {props.data.map((item) => {
            return (
              <View key={item._id}>
                <Image src={logoIfa} style={styles.logo} />
                <Text style={styles.title}>
                  {props.t("i_certifiedParticipant.cTitle")}
                </Text>
                <Text style={styles.intro}>
                  {props.t("i_certifiedParticipant.cCertify")}
                </Text>
                <Text style={styles.name}>{item.name}</Text>
                  <Text style={styles.text}>
                    {certificationTextByWorkshopType[type][0]}
                    <Text style={styles.textBold}>
                      {certificationTextByWorkshopType[type][1]}
                    </Text>
                    <Text style={styles.text}>
                      {certificationTextByWorkshopType[type][2]}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    {certificationTextByWorkshopType[type][3]}
                    {props.momentDate.format("MMMM") +
                      languageMomentPreposition +
                      props.momentDate.format("YYYY") +
                      "."}
                  </Text>
                <Text style={styles.date}>São Paulo, {props.date}.</Text>
                <Image src={assinatura} style={styles.assinatura} />
                <Text style={styles.signature}>
                  {props.t("i_certifiedParticipant.cSignature")}
                </Text>
              </View>
            );
          })}
        </Page>
      </Document>
    );
  }

  export default PdfDocument;