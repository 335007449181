import ParticipantService from "./participant.service";
import InstructorService from "./instructor.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { InstructorDTO } from "../utils/instructor.dto";
import getTranslation from "../utils/getTranslation";

export class UserDataService {
  _context;
  MySwal = withReactContent(Swal);

  constructor(context) {
    this._context = context;
  }

  async fetch(config) {
    let response;

    try {
      response =
        config.role == "instructor"
          ? new InstructorDTO(await InstructorService.getById(config.id))
          : await ParticipantService.getById(config.id);
    } catch (err) {
      this._context.clearContext();

      this.MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: `OK`,
        title: getTranslation("login.errors.expiredSession"),
      }).then(function () {
        window.location = "/";
      });
    }

    return response;
  }

  throwExpiredToken() {
    this._context.clearContext();

    this.MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `OK`,
      title: getTranslation("login.errors.expiredSession"),
    }).then(function () {
      window.location = "/";
    });
  }
}
