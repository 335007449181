import React, { Component } from 'react';
import './ModalConsultMissions.scss';

export default function ModalMissoes({ t, name, context, todo, result, tip }) {

    async function closeModal() {
        let modal = document.querySelector('.missoes-participant-modal')
        modal.style.display = 'none';
    }

    return (
        <div className="modal missoes-participant-modal">
            <div className="modal-content">
                <h2 className="mission-title">{name}</h2>
                <p className="mission-context">{context}</p>

                <div className="modal-card">
                    <input id="todo" type="checkbox" />

                    <label htmlFor="todo">
                        <span className="modal-card-title">
                            <h3>{t('p_missions.modal.toDo')}</h3>
                            <span/>
                        </span>
                    </label>

                    <div className="modal-card-text">
                        {todo}
                    </div>
                </div>

                <div className="modal-card">
                    <input id="result" type="checkbox" />

                    <label htmlFor="result">
                        <span className="modal-card-title">
                            <h3>{t('p_missions.modal.result')}</h3>
                            <span></span>
                        </span>
                    </label>

                    <div className="modal-card-text">
                        {result}
                    </div>
                </div>

                <div className="modal-card">
                    <input id="tip" type="checkbox" />

                    <label htmlFor="tip">
                        <span className="modal-card-title">
                            <h3>{t('p_missions.modal.tip')}</h3>
                            <span></span>
                        </span>
                    </label>

                    <div className="modal-card-text">
                        {tip}
                    </div>
                </div>

                <span
                    className="modal-close-button"
                    onClick={() => closeModal()}
                >
                    {t('p_missions.modal.btnBack')}
                </span>
            </div>
        </div>
    )
}