import './Home.scss';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import iconEstrelas from '../../../assets/images/participante/icon-estrelas.png';
import { ReactComponent as IconJogos } from '../../../assets/images/participante/icon-jogos.svg';
import { ReactComponent as IconMissoes } from '../../../assets/images/participante/icon-missoes.svg';
import { ReactComponent as IconOficina } from '../../../assets/images/participante/icon-oficinas.svg';
import iconPoderes from '../../../assets/images/participante/icon-poderes.png';
import { ReactComponent as IconRanking } from '../../../assets/images/participante/icon-ranking.svg';
import participanteHome from '../../../assets/images/participante/participante-home.png';
import Header from '../../../components/Header/Header';
import { ContextAuth } from '../../../context/authContext';
import ClassService from '../../../services/class.service';
import Modal from './Modal/Modal';

import { toast } from "react-toastify";
import LocalStorage from '../../../services/localStorage.service';

export default class HomeParticipante extends Component {
  static contextType = ContextAuth;

  state = {
    name: '',
    stars: 0,
    powers: 0,
    workshops: [],
    meetingNumbers: 0,
  }

  componentWillMount = async() => {
    const participantLocal = this.context.state.userData;

    const { workshops, meetingNumbers } = await ClassService.classExist(participantLocal.class);
    
   
    this.setState({
      name: participantLocal ? participantLocal.name.split(' ')[0] : '',
      stars: participantLocal.stars.length,
      powers: participantLocal.powers.length,
      workshops,
      meetingNumbers
    })

    await this.openModal();
  }

  componentDidMount = async () => {
    const participantData = this.context.state.userData;

    const hasSomeMissionPending = participantData.missions.some(
      (mission) => mission.status === "incomplete"
    );

    const wasMissionWarnClosed = LocalStorage.retrieve("wasMissionWarnClosed");

    this.timeoutID = setTimeout(() => {
      if (hasSomeMissionPending && !wasMissionWarnClosed) {
        toast.warning(this.props.t("participantHome.missionNotification"), {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => LocalStorage.persist("wasMissionWarnClosed", true),
        });
        toast.clearWaitingQueue();
      }
    }, 2000);
  }

  componentWillUnmount = async () => {
    clearTimeout(this.timeoutID)
  }

  openModal = async () => {
    let modal = document.querySelector('.poll-modal')

    if(this.state.meetingNumbers < 2) {
      return modal.style.display = 'none';
    }

    if(this.isLastWorkshop() && !this.pollAnswered()) {
        return modal.style.display = 'block';
    }
    
    return modal.style.display = 'none';
    
  }

  pollAnswered = () => {
    let participant = this.context.state.userData;
    return participant.pollAnswered;
  }

  isLastWorkshop = () => {
    return this.state.workshops[3].completed;
  }

  render() {
    return (
      <div className="home-participante">
        <Header />
        <main className="home-participante-main">
          <div className="home-participante-header">
            <h1 className="home-participante-welcome">
              {this.props.t('participantHome.hello')},
              <span className="participante-name"> { this.state.name }</span>
            </h1>

            <div className="home-participante-conquistas">
              <h3 className="participante-conquista-title">{this.props.t('participantHome.achievements')}</h3>
              <div className="participante-quadro">
                <div>
                  <Link to="/poderes">
                    <h5 className="participante-titles">{this.props.t('participantHome.powers')}</h5>
                    <img src={iconPoderes} className="participante-quadro-icon" alt=""/>
                    <span className="participante-quadro-count">{ this.state.powers }</span>
                  </Link>

                  <a href="/list-estrelas">
                    <h5 className="participante-titles">{this.props.t('participantHome.stars')}</h5>
                    <img src={iconEstrelas} className="participante-quadro-icon" alt=""/>
                    <span className="participante-quadro-count">{ this.state.stars }</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="home-partipante-content">
            <img src={participanteHome} alt="Home do Participante" />

            <Link to="/oficina-participante" className="home-participante-modulos oficina">
              <div>
                <IconOficina />
                <span className="home-participante-modulos-title">{this.props.t('participantHome.btnWorkshops')}</span>
              </div>
            </Link>

            <Link to="/missoes-menu" className="home-participante-modulos missoes">
              <div>
                <IconMissoes />
                <span className="home-participante-modulos-title">{this.props.t('participantHome.btnMissions')}</span>
              </div>
            </Link>

            <Link to="/game-types" className="home-participante-modulos jogos">
              <div>
                <IconJogos />
                <span className="home-participante-modulos-title">{this.props.t('participantHome.btnQuiz')}</span>
              </div>
            </Link>

            <Link to="/ranking" className="home-participante-modulos ranking">
              <div>
                <IconRanking />
                <span className="home-participante-modulos-title">{this.props.t('participantHome.btnScore')}</span>
              </div>
            </Link>
          </div>

          <Modal 
            name={this.state.name}
            t={this.props.t}
           />
        </main>
      </div>
    );
  }
}