const WorkshopSliderItem = ({
  imgSource,
  divClassName,
  addCount,
  count,
  changeLoadingToFalse,
}) => {
  return (
    <div className={divClassName}>
      <img
        onLoad={() => {
          if (count >= 3) changeLoadingToFalse();
          addCount();
        }}
        className="img-carousel"
        alt="Conteúdo da Oficina"
        src={`https://ifa-app.s3.us-east-2.amazonaws.com${imgSource}`}
      />
    </div>
  );
};

export default WorkshopSliderItem;
