import WorkshopSliderItem from "./WorkshopSliderItem/WorkshopSliderItem";

const WorkshopSlider = ({
  changeLoadingToFalse,
  count,
  addCount,
  workshopId,
  language,
}) => {
  const items = [
    {
      divClassName: "item apresentacao",
      imgSource: `/${workshopId}/apresentation_${language}.png`,
    },
    {
      divClassName: "item massa",
      imgSource: `/${workshopId}/handson_${language}.png`,
    },
    {
      divClassName: "item resultados",
      imgSource: `/${workshopId}/result_${language}.png`,
    },
  ];

  return (
    <div className="slider">
      {items.map((item, index) => (
        <WorkshopSliderItem
          key={index}
          addCount={addCount}
          count={count}
          divClassName={item.divClassName}
          changeLoadingToFalse={changeLoadingToFalse}
          imgSource={item.imgSource}
        />
      ))}
    </div>
  );
};

export default WorkshopSlider;
