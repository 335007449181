import React, { Component } from "react";

import "./Loading.scss";
import { ReactComponent as IfaIcon } from "../../assets/images/header/icone-ifa.svg";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: true,
      exclude: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fade !== this.state.fade) {
      this.SetTimerCloseLoading(nextProps.fade)
    }
  }

  SetTimerCloseLoading(value) {
    setTimeout(() => {
      this.setState({
        fade: value
      });

      setTimeout(() => {
        this.setState({ exclude: !value });
      }, 500);
    }, 500);
  }

  renderContent() {
    return this.state.exclude ? null : (
      <div className={`loading-container ${this.state.fade ? "fadeIn" : "fadeOut"}`}>
        <div className="loading">
          <IfaIcon />
        </div>
        <div className="loader-2 center">
          <span></span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderContent()}
        <>{this.props.children}</>
      </>
    );
  }
}
export default Loading;
