import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./List.scss";

import Header from "../../../Header/Header";
import ClassService from "../../../../services/class.service";
import { ReactComponent as MenuIcon } from "../../../../assets/images/instrutor/menu.svg";

import { ReactComponent as DeleteIcon } from "../../../../assets/images/instrutor/delete.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/images/instrutor/info.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/instrutor/edit.svg";
import podiumIcon from "../../../../assets/images/instrutor/podium.png";
import { FiUserCheck } from "react-icons/fi";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loading from "../../../Loading/Loading";
import { navigate } from "../../../../services/navigation.service";
import { ContextAuth } from "../../../../context/authContext";
import LocalStorage from "../../../../services/localStorage.service";
import getTranslation from "../../../../utils/getTranslation";

const MySwal = withReactContent(Swal);

export default class List extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
    this.state = {
      turmas: [],
      removeClassByCode: "",
      Loading: true,
      filteredClasses: [],
      lang: "",
    };
  }

  async componentWillMount() {
    const lang = LocalStorage.retrieve("language");

    const instrutor = this.context.state.userData;
    // Listar Oficinas em andamento, excluindo as concluídas
    if (instrutor._id) {
      const classes = await ClassService.classesByIntructor(instrutor._id);
      const finishedClasses = await ClassService.classCompletedByInstructor(
        instrutor._id
      );
      let finishedIds = [];
      let classesStarted = [];

      finishedIds = finishedClasses.map((f) => {
        return f._id;
      });

      classes.map((c) => {
        if (!finishedIds.includes(c._id)) {
          classesStarted.push(c);
        }
      });

      await this.setState({
        turmas: classesStarted,
        Loading: false,
        lang,
      });
    }
  }

  removeClass(code) {
    const history = this.props.history;
    //Modal de confirmação de exclusão.

    if (!code) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_ListClasses.deletionDenied")}`,
        "#"
      );
    } else {
      MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        cancelButtonText: `${this.props.t("i_ListClasses.btnBack")}`,
        cancelButtonColor: "#87BF40",
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <a href="javascript:void(0)">
            {this.props.t("i_ListClasses.btnDelete")}
          </a>
        ),
        customClass: {
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class",
        },
        icon: "warning",
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        title: `${this.props.t("i_ListClasses.deletionConfirmation")}${code}`,
      })
        .then((result) => {
          this.setState({ Loading: true });

          if (result.value) {
            ClassService.deleteByCode(this.state.removeClassByCode);
            // window.location.reload(false);
          }
        })
        .finally(() => this.setState({ Loading: false }));
    }

    this.setState({ removeClassByCode: code });
  }

  showAlert(text, icon, title, url) {
    const component = this;
    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${text}`,
      icon: icon,
      title: `<p>${title}</p>`,
    }).then(function () {
      if (url !== "#") {
        const navigator = navigate.bind(component);

        navigator({ link: url });
      }
    });
  }

  render() {
    return (
      <Loading fade={this.state.Loading}>
        <div>
          <Header />

          <div className="page-title-header">
            <h1 className="page-title">
              {this.props.t("i_ListClasses.title")}
            </h1>
            <span className="page-title-border"></span>
          </div>

          <main className="list-turma-main">
            <p className="list-turma-text">
              {this.props.t("i_ListClasses.intro")}
            </p>

            <Link
              to="/turmas-create"
              className="list-main-create-button button-full"
            >
              {this.props.t("i_ListClasses.btnCreate")}
            </Link>

            <h3 className="list-main-title">
              {this.props.t("i_ListClasses.listTitle")}
            </h3>

            <div className="card">
              <table className="list-turma-table">
                <thead>
                  <tr>
                    <th>{this.props.t("i_ListClasses.listClass")}</th>
                    <th>{this.props.t("i_ListClasses.listActions")}</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.turmas.map((turma, index) => {
                    let filtrado = [];

                    if (turma.workshops) {
                      filtrado = turma.workshops.filter(
                        (workshop) =>
                          moment(
                            moment(workshop.dateTime).format("YYYY-MM-DD")
                          ).diff(moment().format("YYYY-MM-DD")) <= 0
                      );
                    }

                    return (
                      <tr key={index}>
                        <td>
                          {turma.name}{" "}
                          <span className="code">COD: {turma.code}</span>
                        </td>

                        <section className="start-or-send">
                          <td id="start-or-send">
                            <Link
                              to={`/oficina-instrutor/${turma.code}`}
                              className="view-button"
                            >
                              {this.props.t("i_ListClasses.listItemBtn")}
                            </Link>

                            <div className="turma-item-dropdown">
                              <MenuIcon className="menu-icon" />
                              <input
                                id={`icon-menu-` + index}
                                name="turma-menu"
                                type="checkbox"
                                value="true"
                              />
                              <label htmlFor={`icon-menu-` + index}>
                                <ul className="dropdown-list">
                                  <li key="info">
                                    <Link to={`/turmas-consult/${turma.code}`}>
                                      <InfoIcon className="list-icon" />
                                      {this.props.t(
                                        "i_ListClasses.listItemInfo"
                                      )}
                                    </Link>
                                  </li>

                                  <li key="ranking">
                                    <Link to={`/ranking/class/${turma.code}`}>
                                      <img
                                        src={podiumIcon}
                                        className="list-icon"
                                      />
                                      {this.props.t(
                                        "i_ListClasses.listItemRanking"
                                      )}
                                    </Link>
                                  </li>

                                  <li key="edit">
                                    <Link to={`/edit-turma/${turma.code}`}>
                                      <EditIcon className="list-icon" />
                                      {this.props.t(
                                        "i_ListClasses.listItemEdit"
                                      )}
                                    </Link>
                                  </li>

                                  <li key="presence">
                                    <Link to={`/submit-presence-list/${turma._id}`}>
                                      <FiUserCheck className="list-icon" />
                                      {getTranslation("i_ListClasses.listItemPresence")}
                                    </Link>
                                  </li>

                                  <li key="remove" className="delete">
                                    {filtrado.length ? (
                                      <button
                                        onClick={() =>
                                          this.removeClass((turma.code = false))
                                        }
                                      >
                                        <DeleteIcon className="list-icon" />

                                        {this.props.t(
                                          "i_ListClasses.listItemDelete"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          this.removeClass(turma.code)
                                        }
                                      >
                                        <DeleteIcon className="list-icon" />
                                        {this.props.t(
                                          "i_ListClasses.listItemDelete"
                                        )}
                                      </button>
                                    )}
                                  </li>
                                </ul>
                              </label>
                            </div>
                          </td>
                          {(turma?.type == "campaignLevel2" ||
                            turma?.type == "campaign") && (
                            <Link
                              to={`/envio-missoes/${turma.code}`}
                              className="view-button"
                              id="list-sendmissionbutton"
                            >
                              {this.props.t("i_ListClasses.btnSendMissions")}
                            </Link>
                          )}
                        </section>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Link
              to="turmas-finished"
              className="list-main-turmas-button button-full"
            >
              {this.props.t("i_ListClasses.btnCompleted")}
            </Link>
          </main>
        </div>
      </Loading>
    );
  }
}
