import React, { Component } from 'react';
import './Skin.scss';
import Header from '../../../Header/Header';

import { ReactComponent as Avatar01 } from '../../../../assets/images/avatar/01.svg';
import { ReactComponent as Avatar02 } from '../../../../assets/images/avatar/02.svg';
import { ReactComponent as Avatar03 } from '../../../../assets/images/avatar/03.svg';
import { ReactComponent as Avatar04 } from '../../../../assets/images/avatar/04.svg';
import { ReactComponent as Avatar05 } from '../../../../assets/images/avatar/05.svg';
import { ReactComponent as Avatar06 } from '../../../../assets/images/avatar/06.svg';
import { ReactComponent as Avatar07 } from '../../../../assets/images/avatar/07.svg';
import { ReactComponent as Avatar08 } from '../../../../assets/images/avatar/08.svg';
import { ReactComponent as Avatar09 } from '../../../../assets/images/avatar/09.svg';

import { ReactComponent as Bald01 } from '../../../../assets/images/avatar/1-bald.svg';
import { ReactComponent as Bald02 } from '../../../../assets/images/avatar/2-bald.svg';
import { ReactComponent as Bald03 } from '../../../../assets/images/avatar/3-bald.svg';
import { ReactComponent as Bald04 } from '../../../../assets/images/avatar/4-bald.svg';
import { ReactComponent as Bald05 } from '../../../../assets/images/avatar/5-bald.svg';
import { ReactComponent as Bald06 } from '../../../../assets/images/avatar/6-bald.svg';
import { ReactComponent as Bald07 } from '../../../../assets/images/avatar/7-bald.svg';
import { ReactComponent as Bald08 } from '../../../../assets/images/avatar/8-bald.svg';
import { ReactComponent as Bald09 } from '../../../../assets/images/avatar/9-bald.svg';

export default class Skin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skinColors: [
        {
          name: 'skin1',
          hexa: '#ffd8c4'
        },
        {
          name: 'skin2',
          hexa: '#ffc0a0'
        },
        {
          name: 'skin3',
          hexa: '#e1a27c'
        },
        {
          name: 'skin4',
          hexa: '#bf8460'
        },
        {
          name: 'skin5',
          hexa: '#7e5237'
        },
        {
          name: 'skin6',
          hexa: '#f1c982'
        }
      ],
      avatar: '',
      avatars: [
        <Avatar01 className="profile-highlight" />,
        <Avatar02 className="profile-highlight" />,
        <Avatar03 className="profile-highlight" />,
        <Avatar04 className="profile-highlight" />,
        <Avatar05 className="profile-highlight" />,
        <Avatar06 className="profile-highlight" />,
        <Avatar07 className="profile-highlight" />,
        <Avatar08 className="profile-highlight" />,
        <Avatar09 className="profile-highlight" />
      ],
      balds: [
        <Bald01 className="profile-highlight" />,
        <Bald02 className="profile-highlight" />,
        <Bald03 className="profile-highlight" />,
        <Bald04 className="profile-highlight" />,
        <Bald05 className="profile-highlight" />,
        <Bald06 className="profile-highlight" />,
        <Bald07 className="profile-highlight" />,
        <Bald08 className="profile-highlight" />,
        <Bald09 className="profile-highlight" />
      ],
      bald: false,
      hairColor: '',
      skinColor: '',
      avatarImage: ''
    }
  }

  componentWillMount = () => {
    let query = this.props.location.search
    query = query.replace(/\?/g, '')
    let variables = query.split('&')

    const avatar = variables[0].split('=')[1]
    const bald = variables[1].split('=')[1]
    const color = variables[2].split('=')[1]

    let avatarImage;

    if(bald === 'true'){
      avatarImage = this.state.balds[avatar-1]
    } else {
      avatarImage = this.state.avatars[avatar-1]
    }

    this.setState({
      avatar: avatar,
      bald: bald,
      avatarImage: avatarImage,
      hairColor: color
    })
  }

  componentDidMount(){
    let hair = document.querySelectorAll('.cls-2')

    hair.forEach(item => {
      item.classList = `cls-2 ${this.state.hairColor}`
    })
  }

  // Color options
  renderColors() {
    return this.state.skinColors.map((item, index) => {
      return (
        <div className="skin-item" key={index}>
          <input
            id={item.name}
            className="input-color"
            name="skinColor"
            onChange={() => this.changeColor(item.name)}
            type="radio"
          />
          <label
            className="label-color"
            htmlFor={item.name}
            style={{ background: item.hexa }}
          >
          </label>
        </div>
      )
    })
  }

  changeColor = (name) => {
    if(this.state.bald !== 'true'){
      let skin = document.querySelectorAll('.cls-1')

      skin.forEach(item => {
        item.classList = `cls-1 ${name}`
      })
    } else {
      let skin = document.querySelectorAll('.noHair-cls-1')

      skin.forEach(item => {
        item.classList = `noHair-cls-1 ${name}`
      })
    }

    this.setState({
      skinColor: name
    })
  }

  render() {
    return (
      <div className="skin-color">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('p_skin.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="profile-main">
          { this.state.avatarImage }

          <div>
            {this.renderColors()}
          </div>

          <a
            className="button-full button-next"
            href={`/participant-about?avatar=${this.state.avatar}&bald=${this.state.bald}&color=${this.state.hairColor}&skinColor=${this.state.skinColor}`}
          >
            {this.props.t('p_skin.btnContinue')}
          </a>
        </main>
      </div>
    );
  }
}