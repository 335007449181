import React, { Component } from 'react';
import './EditClass.scss';

import Header from '../../../Header/Header';
import editButton from '../../../../assets/images/instrutor/edit-button.png';
import removeButton from '../../../../assets/images/instrutor/remove-button.png';
import ClassService from '../../../../services/class.service';
import LocalStorage from '../../../../services/localStorage.service';
import WorkshopService from '../../../../services/workshop.service';

import moment from 'moment';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ParticipantInput from '../Create/ParticipantInput/ParticipantInput';
import { showAlert } from '../../../../utils/showAlert';
import { ContextAuth } from '../../../../context/authContext';

const MySwal = withReactContent(Swal)

export default class Create extends Component {
  static contextType = ContextAuth;
  constructor(props) {
    super(props);
    this.state = {
      oficinas: [],
      class: '',
      nameClass: '',
      participant: {
        _id: '',
        name: '',
        born: '',
        gender: ''
      },
      participants: [],
      workshops: [],
      workshopAux: '',
      datetimeAux: '',
      localizationAux: '',
      type: '',
      qtdWorks: 1,
      classWorkshop: [],
      allParticipants: [],
      classParticipants: [],
      workshopIndex: '',
      idLastOficina: ''
    }
  }

  async componentWillMount() {
    let { code } = this.props.match.params;
    const lang = LocalStorage.retrieve('language');
    let getClass = await ClassService.classExist(code)
    let participantsByClass = await ClassService.participantsByClass(code);

    if (getClass.type === 'campaignLevel2') {
      getClass.type = 'campaignLevel2'
    } else {
      getClass.type = 'campaignLevel1'
    }

    let allWorkshops = await WorkshopService.getAll();
    let idLastOficina = '';
    allWorkshops = allWorkshops.map(workshop => {
      if (workshop.last)
        idLastOficina = workshop._id;

      return {
        _id: workshop._id,
        last: workshop.last,
        canSelect: workshop.last ? false : true,
        selected: false,
        ...workshop[lang]
      }
    })

    await this.setState({
      oficinas: allWorkshops,
      class: getClass,
      nameClass: getClass.name,
      participants: participantsByClass,
      workshops: getClass.workshops,
      type: getClass.type,
      idLastOficina: idLastOficina
    });

    await this.validatesOficinaSelector();
  }

  changeNameClass = async (e) => {
    await this.setState({
      nameClass: e.target.value
    })
  };
  changeWorkshop = async (e) => { await this.setState({ workshopAux: e.target.value }) };
  changeDatetime = async (e) => { await this.setState({ datetimeAux: e.target.value }) };
  changeLocalization = async (e) => { await this.setState({ localizationAux: e.target.value }) };
  changeQtdWorks = async (e) => { await this.setState({ qtdWorks: e.target.value }) };
  changeTypeClass = async (e) => {
    let qtd;
    if (e.target.value === 'campaignLevel2') qtd = 5;
    else qtd = 5;

    let oficinas = this.state.oficinas.map(oficina => {
      this.state.workshops.forEach(w => {
        if (w.workshop == oficina._id) {
          oficina.selected = true;
        } else {
          oficina.selected = false;
        }
      });
      return oficina;
    })

    await this.setState({
      type: e.target.value,
      qtdWorks: qtd,
      oficinas: oficinas
    })

  }

  validDateClass = async (e) => {
    let dateClass = moment(e.target.value).utc().format('L')
    let date = moment().format('L');
    if (new Date(`${e.target.value}T00:00`) > new Date(`9999-12-31T00:00`)) {
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
        'error',
        `${this.props.t('i_CreateClass.wDateAlert3')}`,
        '#'
      );
      await this.setState({ datetimeAux: '' })
    } else if (moment(dateClass, 'DD/MM/YYYY').diff(moment(date, 'DD/MM/YYYY')) < 0) {
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
        'error',
        `${this.props.t('i_CreateClass.wDateAlert1')}`,
        '#'
      );
      await this.setState({ datetimeAux: '' })
    } else if (this.state.workshops.length > 0) {
      if (dateClass < new Date(this.state.workshops[this.state.workshops.length - 1].dateTime)) {
        this.showAlert(
          `${this.props.t('i_CreateClass.btnBack')}`,
          'error',
          `${this.props.t('i_CreateClass.wDateAlert2')}`,
          '#'
        );
        await this.setState({ datetimeAux: '' })
      }
    }
  }

  lastWorkshopIsSelected = () => {
    for (let w of this.state.workshops) {
      if (w.workshop === this.state.idLastOficina)
        return true;
    }
    return false;
  }

  validatesOficinaSelector = async () => {
    //verificação de quais já foram selecionadas
    let oficinas = this.state.oficinas.map(oficina => {
      if (this.state.workshops.map(w => w.workshop).includes(oficina._id))
        oficina.selected = true;
      else
        oficina.selected = false;
      return oficina;
    });
    await this.setState({ oficinas: oficinas });

    // verifica se tem 4 e seleção de 'A Quem Eu Admiro'
    if (this.state.workshops.length == 4) {
      if (!this.lastWorkshopIsSelected()) {
        oficinas = this.state.oficinas.map(oficina => {
          if (oficina.last)
            oficina.canSelect = true;
          else
            oficina.canSelect = false;
          return oficina;
        })
      } else {
        oficinas = this.state.oficinas.map(oficina => {
          if (oficina.last)
            oficina.canSelect = false;
          else
            oficina.canSelect = true;
          return oficina;
        })
      }
      await this.setState({ oficinas: oficinas });
    }

    // verifica se tem menos de 4 e habilita todos menos 'A Quem Eu Admiro'
    if (this.state.workshops.length < 4) {
      oficinas = this.state.oficinas.map(oficina => {
        if (oficina.last)
          oficina.canSelect = false;
        else
          oficina.canSelect = true;
        return oficina;
      })
      await this.setState({ oficinas: oficinas });
    }
  }

  addWorkshop = async () => {
    if (this.state.workshopAux == '' || this.state.datetimeAux == '' || this.state.localizationAux == '') {
      return this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
        'error',
        `${this.props.t('i_CreateClass.workshopAlert')}`,
        '#'
      );
    } else {

      let workshopEdit = {
        workshop: this.state.workshopAux,
        dateTime: this.state.datetimeAux,
        localization: this.state.localizationAux
      };

      if (this.lastWorkshopIsSelected()) {
        if (new Date(this.state.workshops[this.state.workshops.length - 1].dateTime) <=
          new Date(`${workshopEdit.dateTime}T00:00:00`)) {
          return this.showAlert(
            `${this.props.t('i_CreateClass.btnBack')}`,
            'error',
            `${this.props.t('i_CreateClass.workshopsAddAlert')}`,
            '#'
          )
        }
      } else {
        if (workshopEdit.workshop == this.state.idLastOficina &&
          new Date(workshopEdit.dateTime) <=
          new Date(this.state.workshops[this.state.workshops.length - 1].dateTime)
        ) {
          return this.showAlert(
            `${this.props.t('i_CreateClass.btnBack')}`,
            'error',
            `${this.props.t('i_CreateClass.lastWorkshopsAlert')}`,
            '#'
          )
        }
      }

      let formatDate = moment(this.state.datetimeAux).utc().format()
      this.state.workshops.push({ workshop: this.state.workshopAux, dateTime: formatDate, localization: this.state.localizationAux });
      this.state.workshops.sort((a, b) => {
        if (a.dateTime > b.dateTime) return 1;
        if (a.dateTime < b.dateTime) return -1;
        else return 0;
      });

      await this.setState({ workshopAux: '', datetimeAux: '', localizationAux: '' })
      await this.validatesOficinaSelector();

      LocalStorage.persist("cacheTurma", "true");
      LocalStorage.persist("cacheTurmaTipo", this.state.type);
      LocalStorage.persist("cacheTurmaWorkshops", this.state.workshops);
    }
  }

  editWorkshop = (i) => {
    const date = new Date(this.state.workshops[i].dateTime);
    let mouth = date.getMonth() + 1;
    mouth = mouth > 9 ? mouth : `0${mouth}`;
    this.setState({
      workshopAux: this.state.workshops[i].workshop,
      datetimeAux: `${date.getFullYear()}-${mouth}-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`,
      localizationAux: this.state.workshops[i].localization,
    })
    this.removeWorkshop(i);
  }

  removeWorkshop = async (i) => {
    let aux = this.state.workshops;
    aux.splice(i, 1);
    await this.setState({ workshops: aux });
    await this.validatesOficinaSelector();

    LocalStorage.persist("cacheTurma", "true");
    LocalStorage.persist("cacheTurmaTipo", this.state.type);
    LocalStorage.persist("cacheTurmaWorkshops", this.state.workshops);
  }

  saveParticipant = async (participant) => {
    const participants = this.state.participants;
    const index = participants.findIndex(p => p._id === participant._id);
    const isNew = index < 0;

    if (isNew)
      participants.push(participant);
    else
      participants[index] = participant;

    await this.setState({
      participant: { _id: '', name: '', born: '', gender: '' },
      participants
    })
  }

  editParticipant = async (i) => {
    const participant = this.state.participants[i];
    await this.setState({
      participant: {
        _id: participant._id,
        name: participant.name,
        born: participant.born,
        gender: participant.gender
      }
    })
  }

  removeParticipant = (i) => {
    let aux = this.state.participants;
    aux.splice(i, 1)
    this.setState({ participants: aux })
  }

  // Cria os options do select de Oficinas
  listOficinas = () => {
    let listItem = [];

    for (let i = 0; i < this.state.oficinas.length; i++) {
      listItem.push(
        <option
          key={i}
          value={this.state.oficinas[i]._id}
          disabled={this.state.oficinas[i].selected || !this.state.oficinas[i].canSelect}>
          {this.state.oficinas[i].name}
        </option>
      )
    }
    return listItem;
  }

  async editTurma() {
    const showError = (msg) => {
      this.showAlert(
        `${this.props.t('i_CreateClass.btnBack')}`,
        'error',
        msg,
        '#'
      )
    }
    if (this.state.nameClass == '') {
      showError(this.props.t('i_CreateClass.classNameAlert'));
      return;
    }
    if (this.state.participants.length < 5) {
      showError(this.props.t('i_CreateClass.participantQty'));
      return;
    }
    if (this.state.workshops.length != 5) {
      showError(this.props.t('i_CreateClass.workshopScheduling'));
      return;
    }

    try {
      if (this.state.workshops[0].missionsCompleted === false) {
        const workshop = this.state.workshops.map(workshop => {
          workshop.completed = false
          return workshop;
        })
      }
      const body = {
        newClass: {
          code: this.state.class.code,
          name: this.state.nameClass,
          meetingNumbers: 5,
          type: this.state.type === 'campaignLevel2' ? 'campaignLevel2' : 'campaignLevel1',
          workshops: this.state.workshops
        },
        participants: this.state.participants.map(p => {
          if (p._id.startsWith('!')) { delete p._id; }
          return p;
        })
      }
      const res = await ClassService.update(this.state.class.code, body);
      if (res._id)
        this.showAlert(
          `${this.props.t('i_CreateClass.successAlertBtn')}`,
          'success',
          `${this.props.t('i_CreateClass.successAlertMsg')} <span style="color: '#3E68B2'">${this.state.class.code}</span>`,
          '/turmas-list'
        )
    }
    catch (err) {
      showError(this.props.t('i_CreateClass.saveAlert'));
    }
  }

  showAlert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  formatGender(gender) {
    let text = "";
    if (gender === "male") text = this.props.t('i_CreateClass.participantGenderMasculine')
    else if (gender === "female") text = this.props.t('i_CreateClass.participantGenderFeminine')
    else if (gender === "other") text = this.props.t('i_CreateClass.participantGenderOthers')
    return text !== "" ? `, ${text}` : "";
  }

  renderParticipants() {
    return this.state.participants.map((participant, index) => {
      const canBeEdited = this.state.participant._id != participant._id;
      const canBeRemoved = participant._id.startsWith('!');

      return (
        <li key={index}>
          <div className="participantes-info">
            <span className="participantes-name">{`${participant.name}${this.formatGender(participant.gender)}`}</span>
            <span className="participantes-date">{moment(participant.born).format('DD/MM/YYYY')}</span>
          </div>

          <div className="participantes-buttons">
            <button
              onClick={() => this.editParticipant(index)}
              disabled={!canBeEdited}
            >
              <img src={editButton} alt=""
                className={`participantes-edit ${!canBeEdited && "disabled"}`}
              />
            </button>
            
            <button
              onClick={() => this.removeParticipant(index)}
              disabled={!canBeRemoved}
            >
              <img src={removeButton} alt=""
                className={`participantes-remove ${!canBeRemoved && "disabled"}`}
              />
            </button>
          </div>
        </li>
      )
    })
  }

  render() {
    return (
      <div>
        <Header />
        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('i_editClass.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="create-turma-main">
          <p className="create-turma-text">{this.props.t('i_editClass.intro')}</p>
          <p className="create-turma-alert">{this.props.t('i_CreateClass.info')}</p>

          <div className="card form-group">
            <label className="create-turma-label">{this.props.t('i_CreateClass.classNameLabel')}</label>
            <input
              className="create-turma-input"
              type="text"
              onChange={this.changeNameClass.bind(this)}
              value={this.state.nameClass}
            />
          </div>

          {/* ADICIONAR PARTICIPANTE */}
          <h3 className="create-turma-title">{this.props.t('i_CreateClass.participantTitle')}</h3>

          <div className="card form-group">
            <ParticipantInput 
              t={this.props.t}
              participant={this.state.participant}
              onSave={this.saveParticipant}
              showAlert={this.showAlert}
            />

            <p className="create-turma-label">{this.props.t('i_CreateClass.participantsList')} ({this.state.participants.length})</p>

            <p className="create-turma-info">{this.props.t('i_CreateClass.participantsInfo')}</p>

            <ul className="create-turma-participantes-list">
              {this.renderParticipants()}
            </ul>
          </div>

          {/* SELEÇÃO DE OFICINA OU campaignLevel2 */}
          <h3 className="create-turma-title">{this.props.t('i_CreateClass.typeCardTitle')}</h3>

          <div className="card">
            {this.state.class.type == "campaignLevel2" ?
              <div className="form-group">
                <label className="create-turma-label">{this.props.t('i_CreateClass.typeTitle')}</label>
                <div className="create-turma-input-radio">
                  <input
                    disabled="disabled"
                    id="campaignLevel1"
                    name="oficinaType"
                    type="radio"
                    value="campaignLevel1"
                    onChange={this.changeTypeClass.bind(this)}
                  />
                  <label htmlFor="campaignLevel1">{this.props.t('i_CreateClass.typeCampaign1')}</label>

                  <input
                    defaultChecked={true}
                    id="campaignLevel2"
                    name="oficinaType"
                    type="radio"
                    value="campaignLevel2"
                    onChange={this.changeTypeClass.bind(this)}
                  />
                  <label htmlFor="campaignLevel2">{this.props.t('i_CreateClass.typeCampaign2')}</label>
                </div>
              </div>
              :
              <div className="form-group">
                <label className="create-turma-label">{this.props.t('i_CreateClass.typeTitle')}</label>
                <div className="create-turma-input-radio">
                  <input
                    defaultChecked={true}
                    id="campaignLevel1"
                    name="oficinaType"
                    type="radio"
                    value="campaignLevel1"
                    onChange={this.changeTypeClass.bind(this)}
                  />
                  <label htmlFor="campaignLevel1">{this.props.t('i_CreateClass.typeCampaign1')}</label>

                  <input
                    id="campaignLevel2"
                    name="oficinaType"
                    type="radio"
                    value="campaignLevel2"
                    onChange={this.changeTypeClass.bind(this)}
                  />
                  <label htmlFor="campaignLevel2">{this.props.t('i_CreateClass.typeCampaign2')}</label>
                </div>
              </div>
            }
          </div>

          {/* AGENDAMENTO OFICINAS */}
          <h3 className="create-turma-title">{this.props.t('i_CreateClass.scheduleTitle')}</h3>
          <div className="card form-group">
            <label className="create-turma-label">{this.props.t('i_CreateClass.workshopSelect')}</label>
            <div className="create-turma-select-arrow">
              <select
                className="create-turma-select"
                // defaultValue="disabled"
                name="select"
                onChange={this.changeWorkshop.bind(this)}
                value={this.state.workshopAux}
              >
                <option value="">{this.props.t('i_CreateClass.workshopPlaceholder')}</option>
                {this.listOficinas()}
              </select>
            </div>

            <label className="create-turma-label">{this.props.t('i_CreateClass.dateInput')}</label>
            <input
              className="create-turma-input"
              min={`${moment().year() - 1}-12-31`}
              max={`9999-12-31`}
              onChange={this.changeDatetime.bind(this)}
              onBlur={this.validDateClass.bind(this)}
              placeholder="data"
              type="date"
              value={this.state.datetimeAux}
            />

            <label className="create-turma-label">{this.props.t('i_CreateClass.addressInput')}</label>
            <input
              className="create-turma-input endereco"
              placeholder={this.props.t('i_CreateClass.addressPlaceholder')}
              type="text"
              value={this.state.localizationAux}
              onChange={this.changeLocalization.bind(this)}
            />

            <button
              onClick={this.addWorkshop}
              disabled={this.state.workshops.length == 5}
              className="create-turma-add-button button-full">
              {this.props.t('i_CreateClass.addWorkshopBtn')}
            </button>

            {/* cabo */}
            <ul className="create-turma-agendamento-list" >
              {
                this.state.workshops.map((wk, index) =>
                  <li key={index}>
                    <div className="oficina-actions">
                      <div className="oficina-info">
                        <span className="oficina-name">{this.state.oficinas.find(w => w._id === wk.workshop)?.name}</span>
                        <span className="oficina-date">{moment(wk.dateTime).format('ll')}</span>
                      </div>
                      <div className="oficina-buttons">
                        <button
                          onClick={() => this.editWorkshop(index)}
                          hidden={(moment(wk.dateTime).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || wk.presenceCompleted)}
                          disabled={this.state.workshopAux}>
                          <img src={editButton} className="oficina-edit" alt="" />
                        </button>
                        {/* 
                          <button 
                            onClick={() => this.removeWorkshop(index)}
                            hidden={((moment(wk.dateTime) < moment(new Date())) || wk.presenceCompleted)}>
                            <img src={removeButton} className="oficina-remove" alt="" />
                          </button>
                          */}
                      </div>
                    </div>
                    <span className="oficina-address">{wk.localization}</span>
                  </li>
                )
              }
            </ul>
          </div>
          <button
            className="create-turma-save button-full"
            onClick={() => this.editTurma()}
          >
            {this.props.t('i_editClass.saveButton')}
          </button>
        </main>
      </div>
    );
  }
}