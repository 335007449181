import React, { Component } from 'react';
import moment from 'moment'
import 'moment/locale/pt-br'
import {Document, Page, Text, Image, StyleSheet, PDFDownloadLink, View} from '@react-pdf/renderer'

import './Certificado.scss';
import Header from '../../../Header/Header';
import logoIfa from '../../../../assets/images/instrutor/logo-ifa.png';
import assinatura from '../../../../assets/images/instrutor/assinatura.png';

import InstructorService from '../../../../services/instructor.service';
import { ContextAuth } from '../../../../context/authContext';

moment.locale('pt-BR');

const styles = StyleSheet.create({
  page: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  logo: {
    paddingTop:20,
    paddingLeft: 90,
    paddingRight: 90
  },
  title:{
    color: 'orange',
    fontSize: 18,
    textAlign:'center',
    marginTop: 40,
  },
  intro:{
    fontSize: 19,
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  name:{
    color: 'red',
    display: 'block',
    textAlign:'center',
    fontSize: 21,
    letterSpacing: 0.5,
    marginTop: 15,
    marginBottom:25,
    textTransform: 'uppercase',
  },
  text:{
    fontSize: 15,
    textAlign:'center',
    marginTop:30,
    marginBottom: 40,
    marginRight:30,
    marginLeft:30,
    letterSpacing: 1,
    lineHeight: 1.5,
  },
  date:{
    textAlign:'center',
    fontSize: 16,
    marginBottom: 30,
  },
  assinatura: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop:20,
    width: 200
  },
  signature:{
    textAlign:'center',
    fontSize: 14,
    fontWeight: 700,
    marginTop:40,
  },
  role:{
    textAlign:'center',
    fontSize: 14,
    fontWeight: 700,
    marginTop:10,
  },
  button:{
    color: 'orange',
  }
})

class Certificado extends Component {
  static contextType = ContextAuth;

  constructor(props){
    super(props);
    this.state = {
      instructor: {
        name: ''
      },
      date: '',
    }
  }

  async componentWillMount(){
    let localInstructor = this.context.state.userData;
    let instrutor = await InstructorService.getById(localInstructor._id)
    let trainingCompletedAt = instrutor.trainingCompletedAt
    let date = moment(trainingCompletedAt).locale(`${this.props.t('i_certified.dateFormat')}`).format('LL')

    this.setState({
      date: date,
      instructor: instrutor
    })
  }

  GerarPdf(){
    return(
    <Document>
    <Page size='A4' style={styles.page}>
      <View>
        <Image src={logoIfa} style={styles.logo}/>
        <Text style={styles.title}>{this.props.t('i_certified.cTitle')}</Text>
        <Text style={styles.intro}>{this.props.t('i_certified.cCertify')}</Text>
        <Text style={styles.name}>{this.state.instructor.name}</Text>
        <Text style={styles.text}>{this.props.t('i_certified.cText')}</Text>
        <Text style={styles.date}>São Paulo, {this.state.date}</Text>
        <Image src={assinatura} style={styles.assinatura}/>
        <Text style={styles.signature}>{this.props.t('i_certified.cSignature')}</Text>
        <Text style={styles.role}>{this.props.t('i_certified.cRole')}</Text>
      </View>
    </Page>
    </Document>
    )
  }

  render(){
    const Botao = () =>(
      <div className="certificado-treinamento-button outline button-full">
        <PDFDownloadLink document={this.GerarPdf()} fileName="certificado.pdf">
          {this.props.t('i_certified.download')}
        </PDFDownloadLink>
      </div>
    )

    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('i_certified.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main  className="certificado-treinamento-main">
          <p className="certificado-treinamento-main-intro">{this.props.t('i_certified.intro')}</p>

          <div id="certificado" className="card certificado-treinamento-card">
            <img src={logoIfa} className="logo-image" alt="Logo IFA"/>

            <h4 className="certificado-title">{this.props.t('i_certified.cTitle')}</h4>
              <p className="certificado-text">
                <span className="intro">{this.props.t('i_certified.cCertify')}</span>
                <span className="name">{this.state.instructor.name}</span>
                {this.props.t('i_certified.cText')}
              </p>

            <p className="certificado-date">São Paulo, {this.state.date}</p>

            <img src={assinatura} className="assinatura-image" alt="Assinatura José Dornelas" />

            <p className="certificado-legenda">{this.props.t('i_certified.cSignature')}</p>
            <p className="certificado-legenda">{this.props.t('i_certified.cRole')}</p>
          </div>

          <a href="/home-instrutor" className="certificado-treinamento-button filled button-full">
            {this.props.t('i_certified.btnHome')}
          </a>
          <Botao />

        </main>
      </div>
    );
  }
}

export default Certificado;