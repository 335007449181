import React, { Component } from 'react';
import './Presenca.scss';

import Header from '../../../Header/Header';
import ClassService from '../../../../services/class.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Loading from '../../../Loading/Loading'

const MySwal = withReactContent(Swal)

export default class Presenca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      code: '',
      participants: [],
      workshop: '',
      Loading: true,
      showInfoAlert: true
    }
  }

  changePresence = async (e) => {
    const id = e.target.id.split('-')[0];
    const value = e.target.value;

    let participants = this.state.participants;
    const index = participants.findIndex(p => p._id === id);
    participants[index] = { ...participants[index], presence: value }

    await this.setState({
      participants: participants
    })

    await this.savePresence();
  }

  async savePresence() {
    const presenceList = {
      workshop: this.state.workshop,
      participants: this.state.participants
    }
    let res = await ClassService.fillPresence(this.state.code, presenceList);
    if (res._id) {
    }
  }

  finishPresence = async () => {
    const presenceList = {
      workshop: this.state.workshop,
      participants: this.state.participants
    }

    let res = await ClassService.fillPresence(this.state.code, presenceList);
    if (res._id) {
      const steps = await ClassService.completeStep(this.state.code, this.state.workshop, 'presenceCompleted')
      if(steps._id){
        MySwal.fire({
          confirmButtonColor: '#87BF40',
          confirmButtonText: <span>OK</span>,
          icon: 'success',
          title: <p>{this.props.t('presence.savePresenceListAlert')}</p>
        });
      }
      this.props.history.push(`/oficina-instrutor/${res.code}`);
    }
  }

  async componentWillMount() {
    let { code, workshop } = this.props.match.params;

    const classCode = await ClassService.classExist(code);

    let exitsWK = classCode.workshops.filter(w => w.workshop == workshop);


    let participants = await ClassService.participantsByClass(code);
    participants = participants.map(participant => {
      if(exitsWK.length && exitsWK[0].presenceList.length){
        let aux = exitsWK[0].presenceList.find(p => p._id == participant._id)
        if(aux){
          participant.presence = aux.presence.toString();
        } else {
          participant.presence = "true";
        } 
      } else {
        participant.presence = "true";
      }
      return participant;
    })

    await this.setState({
      code: code,
      name: classCode.name,
      participants: participants,
      workshop,
      Loading: false,
    });

  }

  // Lista de Participantes
  participantsList() {
    let listItem = [];

    listItem = this.state.participants.map(item =>
      <li className="presenca-list-item" key={item._id}>
        <span>
          {item.name.includes(' ') ? 
          `${item.name.split(' ')[0]} ${item.name.split(' ')[item.name.split(' ').length - 1]}` :
          `${item.name}`}
        </span>

        <form className="presenca-input-radio">
          <input
            id={`${item._id}-presenca`}
            name="lista-presenca"
            type="radio"
            value="true"
            checked={item.presence === 'true'}
            onChange={this.changePresence.bind(this)}
          />
          <label className="presenca" htmlFor={`${item._id}-presenca`}>{this.props.t('presence.attend')}</label>

          <input
            id={`${item._id}-falta`}
            name="lista-presenca"
            type="radio"
            value="false"
            checked={item.presence === 'false'}
            onChange={this.changePresence.bind(this)}
          />
          <label className="falta" htmlFor={`${item._id}-falta`}>{this.props.t('presence.absence')}</label>
        </form>
      </li>
    )
    return listItem
  }

  renderInfoAlert = () => {
   const path = window.location.pathname.split("/");
   if(path[1] === "turmas-presenca" && this.state.showInfoAlert){  
    this.setState({showInfoAlert: false})
    MySwal.fire({
      confirmButtonColor: '#87BF40',
      confirmButtonText: <span>OK</span>,
      icon: 'info',
      title: <p>{this.props.t("presence.infoAlert")}</p>
    });
   }
  }
  

  render() {
    return (
      <Loading fade = { this.state.Loading } >
      <div>
        <Header />

        {this.renderInfoAlert()}

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('presence.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="presenca-main">
          <h3 className="presenca-class-name">
            {this.state.name}
            <span>{this.props.t('presence.code')}: {this.state.code}</span>
          </h3>

          <p className="presenca-count">{this.props.t('presence.listTitle')} ({this.state.participants.length})</p>

          <div className="card presenca-list">
            <div className="presenca-list-header">
              <span>{this.props.t('presence.listName')}</span>
              <span>{this.props.t('presence.listActions')}</span>
            </div>

            <ul>
              {this.participantsList()}
            </ul>
          </div>

          <button
            className="presenca-button-save button-full"
            onClick={() => this.finishPresence()}
          >
            {this.props.t('presence.btnSave')}
          </button>

        </main>
      </div>
      </Loading>
    );
  }
}