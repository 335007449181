import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import Arial from "../../../../assets/fonts/Arial/Arial.ttf";
import ArialItalic from "../../../../assets/fonts/Arial-Italic/arial-italic.ttf";
import ArialBold from "../../../../assets/fonts/Arial-Bold/arial-bold.ttf";
import ArialBoldItalic from "../../../../assets/fonts/Arial-Bold-Italic/arial-bold-italic.ttf";
import Tahoma from "../../../../assets/fonts/Tahoma/Tahoma.ttf";
import TahomaBold from "../../../../assets/fonts/Tahoma-Bold/tahoma-bold.ttf";
import LocalStorage from "../../../../services/localStorage.service";
import "./CertificadoParticipant.scss";
import Header from "../../../Header/Header";
import imgCertfied from "../../../../assets/images/instrutor/icon-certificado.png";
import { languageToMomentLocale } from './utils/languageToMomentLocale';
import {
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import ClassService from "../../../../services/class.service";
import PdfDocument from "./components/PdfDocument";
import ParticipantCertification from "./components/ParticipantCertification";

Font.register({ family: "Arial", format: "truetype", src: Arial });
Font.register({ family: "arial-bold", format: "truetype", src: ArialBold });
Font.register({ family: "arial-italic", format: "truetype", src: ArialItalic });
Font.register({
  family: "arial-bold-italic",
  format: "truetype",
  src: ArialBoldItalic,
});
Font.register({ family: "Tahoma", format: "truetype", src: Tahoma });
Font.register({ family: "tahoma-bold", format: "truetype", src: TahomaBold });

export default function CertificadoParticipant({ t }) {

  const [Participants, setParticipants] = useState([])

  const { href } = window.location;
  const classCode = href.substring(href.lastIndexOf('/') + 1);

  moment.locale(languageToMomentLocale())
  const currentDate = moment(new Date());
  const date = moment(Date.now()).locale(`${t("i_certified.dateFormat")}`).format("LL");

  const currentClass = LocalStorage.retrieve('classes').find(element => element.code == classCode);
  const lastWorkshopFinished = currentClass.workshops[currentClass.workshops.length - 1];
  const lastWorkshopDate = moment(lastWorkshopFinished.dateTime);
  const momentDate = lastWorkshopDate > currentDate ? currentDate : lastWorkshopDate

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await ClassService.participantsByClass(classCode);
        setParticipants(data);
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [classCode]);

  return (
    <div>
      <Header />
      <div className="page-title-header">
        <h1 className="page-title">{t("i_certifiedParticipant.title")}</h1>
        <span className="page-title-border"></span>
        <img src={imgCertfied} className="imgCertified" alt="certificado" />
      </div>

      <p className="certificado-treinamento-main-intro">
        {t("i_certifiedParticipant.intro")}
      </p>
      {Participants?.map(item => 
        <ParticipantCertification
          item={item}
          t={t}
          momentDate={momentDate}
          date={date}
          currentClass={currentClass}
        />
      )}
      <a
        href="/home-instrutor"
        className="certificado-treinamento-button filled button-full"
      >
        {t("i_certifiedParticipant.btnHome")}
      </a>
        <div className="certificado-treinamento-button outline button-full">
          <PDFDownloadLink
            document={
            <PdfDocument 
              t={t} 
              momentDate={momentDate}
              currentClass={currentClass}
              date={date}
              data={Participants} 
            />
            }
            fileName="certificado.pdf"
          >
            {t("i_certifiedParticipant.download")}
          </PDFDownloadLink>
        </div>
    </div>
  );
}
