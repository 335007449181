import requests from '../agent';

const endpoint = '/participants'

const ParticipantService = {
    getById: async (id) => {
        return await requests.get(`${endpoint}/${id}`);
    },
    find: async (classCode, born) => {
        return await requests.get(`${endpoint}/find?classCode=${classCode}&born=${born}`);
    },
    login: async (participant) => {
        return await requests.post(`/auth${endpoint}`, participant);
    },
    assignStars: async (participants) => {
        return await requests.post(`${endpoint}/assign-stars`, participants);
    },
    rankingByClass: async (code) => {
        return await requests.get(`/classes/${code}/ranking/`);
    },
    changeFirstAccess: async (id) => {
        return await requests.post(`${endpoint}/${id}/first-access`);
    },
    update: async (id, participant) => {
        return await requests.put(`${endpoint}/${id}`, participant)
    },
    getAll: async () => {
        return await requests.get(endpoint)
    },
    changePollAnswered: async (id) => {
        return await requests.post(`${endpoint}/${id}/poll-answered`)
    },
    getUserMissionInfo: async (participantId, missionId) => {
        return await requests.get(`${endpoint}/${participantId}/mission/${missionId}/info`)
    }
};

export default ParticipantService;

