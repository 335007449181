import React, { Component } from 'react';

import QuizService from '../../../../services/quiz.service';

import './Types.scss';
import Header from '../../../Header/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

import GameType1 from '../../../../assets/images/participante/game-type-01.png';
import GameType2 from '../../../../assets/images/participante/game-type-02.png';
import GameType3 from '../../../../assets/images/participante/game-type-03.png';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default class Types extends Component {
  constructor(props){
    super(props);
    this.state = {
      gameTypes: [
        {
          name: `${this.props.t('gameTypes.trueFalse')}`,
          link: '/game-list?gameType=trueFalse',
          className: 'trueFalse',
          image: GameType2
        },
        {
          name: `${this.props.t('gameTypes.fillGap')}`,
          link: '/game-list?gameType=fillGap',
          className: 'fillGap',
          image: GameType3
        },
        {
          name: `${this.props.t('gameTypes.quizzes')}`,
          link: '/game-list?gameType=quiz',
          className: 'quiz',
          image: GameType1
        }
      ],
      quizzes: [],
      typeGame: []
    }
  }

  componentDidMount = async() => {
    let quizzes = await QuizService.getQuizType('participante')
    let typeGame = quizzes.map(quiz =>{
      return quiz.typeGame
    })

    await this.setState({
      quizzes: quizzes,
      typeGame: typeGame
    })

  }

  linkGame = (item) => {
    if(this.state.typeGame.includes(item.className)){
      this.props.history.push(item.link)
    } else {
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: `${this.props.t('gameTypes.btnBack')}`,
        icon: 'warning',
        title: <p>{this.props.t('gameTypes.emptyAlert')}</p>
      })
    }
  }

  render() {
    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('gameTypes.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="game-types-main">
          <OwlCarousel
            dots={false}
            items="1"
            loop={true}
            nav={true}
          >
            {
              this.state.gameTypes.length && this.state.gameTypes.map(item => {
                return(
                  <div
                    onClick={() => this.linkGame(item)}
                    className={"game-types-modulos " + item.className}
                  >
                    <span className="game-types-modulos-title">{ item.name }</span>
                    <img src={ item.image } alt=""/>
                  </div>
                )
              })
            }
          </OwlCarousel>
        </main>
      </div>
    )
  }
}