import requests from '../agent';

const endpoint = '/instructors'

const InstructorService = {
    login: async (instructor) => {
        return await requests.post(`/auth${endpoint}`, instructor)
    },
    create: async (instructor) => {
        return await requests.post(`${endpoint}`, instructor)
    },
    getById: async (id) => {
        return await requests.get(`${endpoint}/${id}`)
    },
    existsWithUsername: async (username) => {
        return await requests.get(`${endpoint}/username/${username}/exists`)
    },
    existsWithEmail: async (email) => {
        return await requests.get(`${endpoint}/email/${email}/exists`)
    },
    existsWithCpf: async (cpf) => {
        return await requests.get(`${endpoint}/cpf/${cpf}/exists`)
    },
    changeFirstAccess: async (id) => {
        return await requests.post(`${endpoint}/${id}/first-access`, {});
    },
    update: async (id, instructor) => {
        return await requests.put(`${endpoint}/${id}`, instructor)
    },
    recoveryPassword: async (email) => {
        return await requests.post(`${endpoint}/recovery-password`, { email })
    },
    notify: async (email) => {
        return await requests.post(`${endpoint}/notify`, { email })
    },
    changePassword: async (changePass) => {
        return await requests.post(`${endpoint}/change-password`, changePass);
    }    
};

export default InstructorService;

