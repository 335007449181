import requests from '../agent';

const endpoint = '/quizzes';

const QuizService = {
    getAll: async () => {
        return await requests.get(`${endpoint}/active`)
    },
    getQuizType: async (type) => {
        return await requests.get(`${endpoint}/type/${type}`)
    },
    getByTrainingId: async (id) => {
        return await requests.get(`/training/${id}/quizzes`)
    },
    createSaved: async (quizSaved) => {
        return await requests.post(`${endpoint}/saved`, quizSaved)
    },
    getTypeGame: async (type) => {
        return await requests.get(`${endpoint}/game/${type}`)
    },
    concludeTrainingWithNoQuizz: async (trainingId, instructorId) => {
        return await requests.put(`${endpoint}/update/${trainingId}`, instructorId)
    },
}

export default QuizService