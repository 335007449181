import { useContext } from "react";
import SendMissionService from "../../../../../services/sendmission.service";
import getTranslation from "../../../../../utils/getTranslation";
import { ContextAuth } from "../../../../../context/authContext";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Compressor from "compressorjs";

const SendButton = ({ images, imagesAlreadySent, userMissionId }) => {
  const context = useContext(ContextAuth);

  const compressImage = (image) => {
    return new Promise((resolve, reject) => {
      new Compressor(image.file, {
        quality: 0.6,
        success: (compressedResult) => {
          resolve(compressedResult)
        },
        error: (error) => {
          reject(error)
        },
      });
    });
  }

  const handleSendImages = async () => {
    let MySwal = withReactContent(Swal);

    Swal.fire({
      icon: "warning",
      text: getTranslation("participantMissionUpload.sendButton.sendWarn"),
      allowEscapeKey: true,
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#87BF40",
      confirmButtonText: getTranslation("participantMissionUpload.sendButton.sendWarnConfirm"),
      cancelButtonText: getTranslation("participantMissionUpload.sendButton.sendWarnCancel"),
    }).then(async (res) => {

      if (res.isConfirmed) {
        MySwal.fire({
          html: getTranslation("i_ListImages.sendLoading"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            MySwal.showLoading();
          },
        });
        
        const participantId = context.state.userData?._id;

        if (!participantId) return;

        const formData = new FormData();

        formData.append("missionID", userMissionId);

        await Promise.all(
          images.map(async (image) => {
            const result = await compressImage(image);
            formData.append("files", result);
          })
        );

        try {
          const response = await SendMissionService.participant.create(
            formData,
            participantId
          );

          context.setContext({ userData: response.participant });

          MySwal.fire({
            title: getTranslation("i_ListImages.sendSucessTitle"),
            html: getTranslation("i_ListImages.sendSucessText"),
            icon: "success",
            showCloseButton: true,
            showConfirmButton: false,
            focusConfirm: false,
          });
        } catch (error) {
          MySwal.fire({
            title: getTranslation("i_ListImages.sendErrorTitle"),
            html: getTranslation("i_ListImages.sendErrorText"),
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            focusConfirm: false,
          });
        }
      }
    });
  };

  return (
    <button
      className="listaDeImagens_sendButton"
      disabled={(images.length === 0 || imagesAlreadySent)}
      onClick={() => handleSendImages()}
      style={{
        background:
          images.length > 0 ? "#87BF40" : "rgba(195, 195, 195, 0.698)",
      }}
    >
      {getTranslation("i_ListImages.btnSend")}
    </button>
  );
};

export default SendButton;
