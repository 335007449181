import LocalStorage from "../../../../services/localStorage.service";
import MissionService from "../../../../services/mission.service";
import PowerService from "../../../../services/power.service";

const getPowersAndMissionsDescriptions = async ({ context, userMissionsFiltered }) => {
  const userPowersAssigned = context.state.userData.powers;
  const lang = LocalStorage.retrieve("language");

  let powers = await PowerService.getAll();
  let missions = await MissionService.getAll(context);

  powers = powers.map((power) => {
    return {
      _id: power._id,
      ...power[lang],
    };
  });

  missions = missions.map((mission) => {
    return {
      _id: mission._id,
      power: mission.power,
      ...mission[lang],
    };
  });

  // pra cada missão pendente do usuário, atribuir o poder ao qual essa missão pertence
  const userMissionsAndPowersWithDescriptions = userMissionsFiltered.map(
    (missionAssigned) => {
      const powerAssigned = userPowersAssigned.find(
        (power) => power.workshop === missionAssigned.workshop
      );
      const powerDescription = powers.find(
        (power) => power._id === powerAssigned.powerId
      );
      const missionDescription = missions.find(
        (mission) => mission._id === missionAssigned.missionId
      );

      return {
        ...missionAssigned,
        powerDescription,
        missionDescription,
      };
    }
  );

  return userMissionsAndPowersWithDescriptions;
};

export { getPowersAndMissionsDescriptions };
