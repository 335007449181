import React, { Component } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import QuizService from "../../../../services/quiz.service";
import LocalStorage from "../../../../services/localStorage.service";

import "./Quiz.scss";

import Header from "../../../Header/Header";
import { navigate } from "../../../../services/navigation.service";
import { ContextAuth } from "../../../../context/authContext";
const MySwal = withReactContent(Swal);

class Quiz extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
    this.state = {
      quizzes: [],
      instructor: {},
      saved: []
    };
  }

  async componentDidMount() {
    const lang = LocalStorage.retrieve("language");
    let localInstructor = this.context.state.userData;

    let { id } = this.props.match.params;

    let quizContent = await QuizService.getByTrainingId(id);
    quizContent = quizContent.map((quiz) => {

      return {
        _id: quiz._id,
        training: quiz.training,
        type: quiz.type,
        currenty: false,
        ...quiz[lang],
      };
    });

    if (quizContent.length > 0) quizContent[0].currenty = true;

    this.setState({
      quizzes: quizContent,
      instructor: localInstructor,
    });
  }

  nextQuestion = async (e) => {
    try {
      let user = this.state.instructor._id;
      let current = this.state.quizzes.filter((q) => q.currenty);
      let quiz = current[0]._id;
      let correct = this.state.answerChosen.correct;
      let answer = this.state.answerChosen.answer;

      this.setState({
        saved: [...this.state.saved, { user, quiz, correct, answer }]
      }, async() => {
        let allQuizzes = this.state.quizzes;
        allQuizzes.shift();
  
        if (allQuizzes && allQuizzes.length > 0) {
          allQuizzes[0].currenty = true;
          this.setState({ quizzes: allQuizzes });
          
        } else {
          let res = await QuizService.createSaved(this.state.saved);
          this.alert(
            `${this.props.t("i_trainingQuiz.finishAlert")}`,
            "success",
            `${this.props.t("i_trainingQuiz.textAlert")}: ${res.result}%`,
            "/treinamento-instrutor"
          );
        }
      })
    } catch (err) {
      this.alert(
        `${this.props.t("i_trainingQuiz.tryAgain")}`,
        "error",
        `${this.props.t("i_trainingQuiz.errorAlert")}`,
        `javascript:void(0)`
      );
    }
  };

  alert(text, icon, title, url) {
    const component = this;

    MySwal.fire({
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${text}`,
      icon: icon,
      title: `<p>${title}</p>`,
    }).then(function () {
      const navigator = navigate.bind(component);

      navigator({ link: url });
    });
  }

  render() {
    return (
      <div className="quiz-instrutor">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t("i_trainingQuiz.title")}</h1>
          <span className="page-title-border"></span>
        </div>

        <div>
          {this.state.quizzes &&
            this.state.quizzes.map((quiz, index) => {
              if (quiz.currenty) {
                return (
                  <main className="quiz-instrutor-main" key={index}>
                    <p className="quiz-instrutor-question">{quiz.statement}</p>

                    <ul className="quiz-instrutor-answers">
                      {quiz.answers &&
                        quiz.answers.map((event, i) => (
                          <li
                            className="card quiz-instrutor-item"
                            key={`${quiz._id}-${i}`}
                          >
                            <input
                              id={`${quiz._id}-${i}`}
                              type="radio"
                              name="answer"
                              value={event.answer}
                              onClick={() =>
                                this.setState({ answerChosen: event })
                              }
                            />
                            <label for={`${quiz._id}-${i}`}>
                              {event.answer}
                            </label>
                          </li>
                        ))}
                    </ul>
                    {this.state.quizzes && this.state.quizzes.length > 1 ? (
                      <button
                        className="quiz-instrutor-button button-full"
                        onClick={this.nextQuestion.bind(this)}
                      >
                        {this.props.t("i_trainingQuiz.nextQuiz")}
                      </button>
                    ) : (
                      <button
                        className="quiz-instrutor-button button-full"
                        onClick={this.nextQuestion.bind(this)}
                      >
                        {this.props.t("i_trainingQuiz.finish")}
                      </button>
                    )}
                  </main>
                );
              }
            })}
        </div>
      </div>
    );
  }
}

export default Quiz;
