import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { navigate } from "../services/navigation.service";

const MySwal = withReactContent(Swal);

const showAlert = (text, icon, title, url, component) => {
  MySwal.fire({
    allowOutsideClick: false,
    confirmButtonColor: "#87BF40",
    confirmButtonText: `${text}`,
    icon: icon,
    title: `<p>${title}</p>`,
  }).then(function () {
    if (url !== "#") {
      const navigator = navigate.bind(component);

      navigator({ link: url });
    }
  });
};

export { showAlert };
