import React, { Component } from 'react';
import './RecoverPassword.scss';

import logoHorizontal from '../../../../assets/images/logo/logo-horizontal.png';

import InstructorService from '../../../../services/instructor.service';
import LocalStorage from '../../../../services/localStorage.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ContextAuth } from '../../../../context/authContext';
const MySwal = withReactContent(Swal)

export default class RecoverPassword extends Component {
  static contextType = ContextAuth;
    constructor(props){
    super(props);
    this.state = {
      username: ''
    }
  }

  componentWillMount = async() => {
    const instructor = this.context.state.userData;
    await this.setState({
      username: instructor.username
    })
  }

  // Sweet Alert
  alert(text, icon, btnText, url){
    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: `${btnText}`,
      icon: icon,
      text: `${text}`,
    }).then(function() {
      window.location = `${url}`;
  });
  }

  savePassword = async() => {
    let oldPassword = document.querySelector('#oldPassword').value
    let newPassword = document.querySelector('#newPassword').value
    let repeatPassword = document.querySelector('#repeatPassword').value

    if(!oldPassword && !newPassword && !repeatPassword || !oldPassword || !newPassword || !repeatPassword){
      this.alert(
        `${this.props.t('recoverPassword.alertText1')}`,
        'error',
        `${this.props.t('recoverPassword.btnAlertBack')}`,
        'javascript:void(0)'
      )
    } else if(newPassword != repeatPassword){
      this.alert(
        `${this.props.t('recoverPassword.alertText2')}`,
        'error',
        `${this.props.t('recoverPassword.btnAlertBack')}`,
        'javascript:void(0)'
      )
    } else {
      try {
        const change = {
          username: this.state.username,
          password: oldPassword,
          newPassword: newPassword
        }
        let res = await InstructorService.changePassword(change);
        if(res._id){
          this.alert(
            `${this.props.t('recoverPassword.success')}`,
            'success',
            `OK`,
            '/'
          )
        }
      } catch(err){
        this.alert(
          `${this.props.t('recoverPassword.error')}`,
          'error',
          `OK`,
          'javascript:void(0)'
        )
      }
    }
  }

  render() {
    return (
      <div className="recover">
        <header className="login-header">
          <img src={logoHorizontal} className="login-header-image" alt="logo IFA" />
          <h1 className="login-header-title">{this.props.t('recoverPassword.title')}</h1>
        </header>

        <main className="recover-main">
          <p className="recover-text">{this.props.t('recoverPassword.text')}</p>

          <div className="form-group">
            <label
              className="recover-label"
              htmlFor="oldPassword"
            >
              {this.props.t('recoverPassword.oldLabel')}
            </label>
            <input
              id="oldPassword"
              className="recover-input"
              placeholder={this.props.t('recoverPassword.oldPlaceholder')}
              type="password"
            />
          </div>

          <div className="form-group">
            <label
              className="recover-label"
              htmlFor="newPassword"
            >
              {this.props.t('recoverPassword.newLabel')}
            </label>
            <input
              id="newPassword"
              className="recover-input"
              placeholder={this.props.t('recoverPassword.newPlaceholder')}
              type="password"
            />
          </div>

          <div className="form-group">
            <label
              className="recover-label"
              htmlFor="repeatPassword"
            >
              {this.props.t('recoverPassword.repeatLabel')}
            </label>
            <input
              id="repeatPassword"
              className="recover-input"
              placeholder={this.props.t('recoverPassword.repeatPlaceholder')}
              type="password"
            />
          </div>

          <button
            className="button-full recover-btnRecover"
            onClick={() => this.savePassword() }
          >
            {this.props.t('recoverPassword.btnRecover')}
          </button>

          <a
            className="recover-backLink"
            href="/"
          >
            {this.props.t('recoverPassword.backLink')}
          </a>
        </main>
      </div>
    );
  }
}