import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import config from "./config";
import LocalStorage from "./services/localStorage.service";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = config.apiUrl;

const responseBody = (res) => res.body;

// Tentar buscar o valor do token fora do escopo do objeto "requests" faz com que essa busca seja feita globalmente, assim que o app é instanciado, o que retorna null caso seja a primeira vez que o usuário obtém o token
const token = LocalStorage.retrieve("token");



const requests = {
  del: (url) => {
    return superagent
      .del(`${API_ROOT}${url}`)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },

  get: (url) => {
    
    return superagent
      .get(`${API_ROOT}${url}`)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },

  put: (url, body) => {
    return superagent
      .put(`${API_ROOT}${url}`, body)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },

  post: (url, body) => {

    return superagent
      .post(`${API_ROOT}${url}`, body)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },
};

export default requests;
