import React, { Component } from 'react';
import './Poder.scss';

export default class Poder extends Component {
  constructor(props){
    super(props)
    this.state = {
      poderSelected: {
        id: '',
        poder: '',
        description: '',
        value: '',
      }
    }
  }

  changePower = (item) => {
    const obj = {
      id: item._id,
      poder: item.name,
      description: item.description,
      value: item.name
    }

    this.setState({
      poderSelected: obj,
    });


    this.renderModal();

    let modal = document.querySelector('.poderes-modal')
    modal.style.display = 'block'
  }

  renderModal = () => {
    return (
      <div className="modal poderes-modal">
        <div className="modal-content poderes-modal-content">
          {this.state.poderSelected.id && (
            <img
              src={`https://ifa-app.s3.us-east-2.amazonaws.com/${this.state.poderSelected.id}/capa_${this.props.lang}.png`}
              alt=""
              className="modal-content-image"
            />
          )}

          <h3 className="modal-content-title">
            {this.state.poderSelected.poder}
          </h3>
          <p className="modal-content-text">
            {this.state.poderSelected.description}
          </p>
          <span
            className="modal-close-button"
            onClick={() => this.closeModal()}
          >
            {this.props.t("i_powers.btnBack")}
          </span>
        </div>
      </div>
    );
  }

  closeModal(){
    let modal = document.querySelector('.poderes-modal')

    modal.style.display = 'none';
  }

  changeCounter(counter) {
    let limit = Math.ceil(this.props.qtyParticipants / this.props.qtyMaxPowers);
    let res = limit - counter;
    return res < 0 ? 0 : res;
  }

  render(){
    return(
      <div>
        {
          this.props.poderes.map((item) => (
            <div
              key={item._id}
              className="poderes-list-item"
              onClick={ () => this.changePower(item) }
            >
              <span className={`name ${item.name}`}>{ item.name }</span>
              <span className="quantity">
                x{this.changeCounter(item.counter)}
              </span>
            </div>
          ))
        }
        { this.renderModal() }
      </div>
    );
  }
}