import React, { Component } from "react";
import "./Welcome.scss";
import LocalStorage from "../../services/localStorage.service";

import Header from "../Header/Header";
import welcomeImage from "../../assets/images/onboarding/background.png";

import SecurityService from "../../services/security.service";
import InstructorService from "../../services/instructor.service";
import ParticipantService from "../../services/participant.service";
import { navigate } from "../../services/navigation.service";
import { ContextAuth } from "../../context/authContext";

export default class Welcome extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      type: "",
      name: "",
      text: "",
    };
  }

  async componentDidMount() {
    const user = this.context.state?.userData;
    const name = user.name.split(" ")[0];
    const type =
      SecurityService.isInstructor(this.context)
        ? "instructor"
        : "participant";
    const text = this.props.t(`welcome.${type}Text`);

    LocalStorage.remove("cacheInstructor");
    await this.setState({ user, name, type, text });
  }

  continue = async (ev) => {
    const component = this;

    if (this.state.type === "instructor") {
      try {
        const res = await InstructorService.changeFirstAccess(this.state.user._id)
        this.context.setContext({ userData: res });
          const navigator = navigate.bind(component);

          navigator({ link: "/treinamento-instrutor" });
      } catch (err) {
        console.log(err)
      }

    } else {
      try {
        const res = await ParticipantService.changeFirstAccess(this.state.user._id);
        this.context.setContext({ userData: res });
        
        const navigator = navigate.bind(component);
          
        navigator({ link: "/participant-avatar" });

      } catch (err) {
        console.log(err)
      }
    }
  };

  render() {
    return (
      <div className="welcome">
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">
            {this.props.t("welcome.title")} {this.state.name}
          </h1>
          <span className="page-title-border onboarding"></span>
        </div>

        <main className="welcome-main">
          <span className="welcome-main-about">
            {this.props.t("welcome.about")}
          </span>
          <img src={welcomeImage} className="welcome-image" alt="" />
          <div className="card welcome-card">
            <h3 className="welcome-card-title">
              {this.props.t("welcome.cardTitle1")}
              <br />
              {this.props.t("welcome.cardTitle2")}
            </h3>

            <p className="welcome-card-text">{this.state.text}</p>
          </div>

          <button onClick={this.continue} className="welcome-button">
            {this.props.t("welcome.btnContinue")}
          </button>
        </main>
      </div>
    );
  }
}