import React, { Component } from "react";

import "./FinishedClasses.scss";
import Header from "../../../Header/Header";

import ClassService from "../../../../services/class.service";
import moment from "moment";
import { ContextAuth } from "../../../../context/authContext";

export default class FinishedClasses extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
    this.state = {
      turmas: [],
    };    
  }

  async componentWillMount() {
    const instrutor = this.context.state.userData;

    if (instrutor._id) {
      const classes = await ClassService.classCompletedByInstructor(
        instrutor._id
      );
      localStorage.setItem("classes", JSON.stringify(classes));
      localStorage.setItem("participants", JSON.stringify(classes));
      await this.setState({
        turmas: classes,
      });
    }
  }

  render() {
    return (      
      <div>
        <Header />

        <div className="page-title-header">
          <span className="page-title-border"></span>
        </div>

        <main className="ended-classes-main">
          <ul className="ended-classes-list">
            {this.state.turmas.map((turma, index) => {
              return (                
                <li className="card ended-classes-item" key={index}>
                  <p className="name">{turma.name}</p>
                  <p>
                    <span>{this.props.t("i_FinishedClasses.type")} </span>  
                    {turma.workshops.length === 1
                      ? `${this.props.t("i_FinishedClasses.typeWorkshop")}`
                      : turma.type === "campaign" 
                      ? `${this.props.t("i_FinishedClasses.typeCampaign")}`
                      : turma.type === "campaignLevel1" 
                      ? `${this.props.t("i_FinishedClasses.typeCampaignLevel1")}` 
                      : `${this.props.t("i_FinishedClasses.typeCampaignLevel2")}`
                    }
                  </p>
                  <p>
                    <span>{this.props.t("i_FinishedClasses.startDate")} </span>
                    {moment(turma.createdAt)
                      .locale(`${this.props.t("i_FinishedClasses.dateFormat")}`)
                      .format("LL")}
                  </p>
                  {turma.workshops.length == 1 ? (
                    <a href={`/edit-turma/${turma.code}`}>
                      <button className="edit-turma">
                        {this.props.t("i_editClass.title")}
                      </button>
                    </a>
                  ) : (
                    " "
                  )}
                  <a href={`/turmas-consult/${turma.code}`}>
                    <button className="info-turma">
                      {this.props.t("i_FinishedClasses.btnInfo")}
                    </button>
                  </a>
                  <a href={`/turmas-certified/${turma.code}`}>
                    <button className="certificado-turma">
                      {this.props.t("i_FinishedClasses.btnCertificado")}
                    </button>
                  </a>
                </li>
              );
            })}
          </ul>
        </main>
      </div>
    );
  }
}
