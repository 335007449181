import { useEffect, useState } from "react";

export default function ParticipantInput({
  t,
  participant,
  onSave,
  showAlert,
}) {
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [born, setBorn] = useState("");

  useEffect(() => {
    const namePieces = participant.name.split(" ").filter((p) => p);
    const nameAux = namePieces.slice(0, namePieces.length - 1).join(" ");
    const lastNameAux = namePieces.slice(-1).pop();

    setId(participant._id);
    setName(nameAux ? nameAux : "");
    setLastName(lastNameAux ? lastNameAux : "");
    setGender(participant.gender);
    setBorn(participant.born);
  }, [participant]);

  const changeName = (event) => setName(event.target.value.toUpperCase());
  const changeLastName = (event) =>
    setLastName(event.target.value.toUpperCase());
  const changeGender = (event) => setGender(event.target.value);
  const changeBorn = (event) => setBorn(event.target.value);

  const hasId = () => !!id && id.length;

  const isValid = (value) => !!value && value.length;
  const isBornValid = (value) => {
    if (!value || !value.length) {
      return false;
    }

    const born = new Date(value + "T00:00");
    const now = new Date();
    return born <= now;
  };

  const validateBorn = async (event) => {
    if (!isBornValid(event.target.value)) {
      showAlert(
        t("i_CreateClass.btnBack"),
        "error",
        t("i_CreateClass.participantDateAlert"),
        "#"
      );
      setBorn("");
    }
  };

  const inputIsValid = () => {
    return (
      isValid(name) && isValid(lastName) && isValid(gender) && isBornValid(born)
    );
  };

  const save = () => {
    if (!inputIsValid()) {
      showAlert(
        t("i_CreateClass.btnBack"),
        "error",
        t("i_CreateClass.participantAlert"),
        "#"
      );
      return;
    }

    const fullName = name.trim() + " " + lastName.trim();

    const action = onSave ? onSave : () => {};
    action({
      _id: hasId() ? id : `!${fullName}${born}`,
      name: fullName,
      gender,
      born,
    });
  };

  return (
    <>
      <label className="create-turma-label">
        {t("i_CreateClass.participantNameLabel")}
      </label>
      <input
        className="create-turma-input participant-name"
        placeholder={t("i_CreateClass.participantNamePlaceholder")}
        type="text"
        onChange={changeName}
        value={name}
      />

      <label className="create-turma-label">
        {t("i_CreateClass.participantLastNameLabel")}
      </label>
      <input
        className="create-turma-input participant-name"
        placeholder={t("i_CreateClass.participantLastNamePlaceholder")}
        type="text"
        onChange={changeLastName}
        value={lastName}
      />

      <label className="create-turma-label">
        {t("i_CreateClass.participantGenderSelect")}
      </label>
      <div className="create-turma-select-arrow">
        <select
          className="create-turma-select"
          // defaultValue="disabled"
          name="select"
          onChange={changeGender}
          value={gender}
        >
          <option value="">
            {t("i_CreateClass.participantGenderPlaceholder")}
          </option>
          <option value="male">
            {t("i_CreateClass.participantGenderMasculine")}
          </option>
          <option value="female">
            {t("i_CreateClass.participantGenderFeminine")}
          </option>
          <option value="other">
            {t("i_CreateClass.participantGenderOthers")}
          </option>
        </select>
      </div>

      <label className="create-turma-label">
        {t("i_CreateClass.participantDateLabel")}
      </label>
      <input
        className="create-turma-input participant-date"
        onChange={changeBorn}
        onBlur={validateBorn}
        type="date"
        value={born}
      />

      <button onClick={save} className="create-turma-add-button button-full">
        {id
          ? t("i_CreateClass.participantsButtonOnEditing")
          : t("i_CreateClass.participantsButton")}
      </button>
    </>
  );
}
